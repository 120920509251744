;(function() {


"use strict"

window.Vue.component("paginated-list", {
  props: ['CurrentOption', 'CompanyIndex','index'],
  data: function() {
    return {
      // id: window.uncached.data.company_id.id,
      currentPage: 1,
      itemsPerPage: 1,
      list: null,
      totalPage: null,
      resultCount: 0,
    }
  },

  mounted: function() {
    if (this.CurrentOption == 'UserInfo') {
      this.generate_user_axios()
    } else {
      this.generate_company_axios()
    }
  },

  watch: {
    index: function(newVal, oldVal){
      this.currentPage = 1
      this.generate_company_axios()
    }
  },

  template: '\
    <ul>\
      <li class="paginated_pages"\
        v-for="pageNumber in totalPage"\
        v-if="Math.abs(pageNumber - currentPage) < 3 ||\
          pageNumber == totalPage ||\
          pageNumber == 1"\
      >\
        <span\
          v-bind:key="pageNumber"\
          @click="setPage(pageNumber)"\
          class="pages"\
          :class="{current: currentPage === pageNumber,\
              last: (pageNumber == totalPage && Math.abs(pageNumber - currentPage) > 3),\
              first:(pageNumber == 1 && Math.abs(pageNumber - currentPage) > 3)\
          }"\
        >\
          {{ pageNumber }}\
        </span>\
      </li>\
    </ul>\
  ',

  computed:{
    // paginate: function() {
    //     if (!this.users || this.users.length != this.users.length) {
    //         return
    //     }
    //     this.resultCount = this.users.length
    //     if (this.currentPage >= this.totalPages) {
    //       this.currentPage = this.totalPages
    //     }
    //     var index = this.currentPage * this.itemsPerPage - this.itemsPerPage
    //     return this.users.slice(index, index + this.itemsPerPage)
    // }
  },

  methods: {
    generate_user_axios: function() {
      axios.get(
        '/account/ordering_history?page=' + this.currentPage
      ).then(
        function(response) {
          this.totalPage = response.data["total_pages"]
          this.list = response.data["list"]
          this.$emit('basket_list', this.list)
        }.bind(this)
      ).catch(
        function (error) {
          console.log(error)
        }
      )
    },
    
    generate_company_axios: function() {
      if (!!this.id) {
        axios.get(
          '/account/get_company_order_history?id=' + this.id + '&page=' + this.currentPage
        ).then(
          function(response) {
            this.totalPage = null
            this.list = null
            this.totalPage = response.data["total_pages"]
            this.list = response.data["list"]
            this.$emit('basket_company_list', this.list)
            this.id = null
          }.bind(this)
        ).catch(
          function (error) {

          }
        )
      } else {
        axios.get(
          '/account/get_company_order_history?id=' + this.CompanyIndex + '&page=' + this.currentPage
        ).then(
          function(response) {
            this.totalPage = null
            this.list = null
            this.totalPage = response.data["total_pages"]
            this.list = response.data["list"]
            this.$emit('basket_company_list', this.list)
          }.bind(this)
        ).catch(
          function (error) {

          }
        )
      }
    },

    setPage: function(pageNumber) {
      this.currentPage = pageNumber
      if (this.CurrentOption == 'UserInfo') {
        this.generate_user_axios()
      } else {
        this.generate_company_axios()
      }
    },

    nextPage: function() {
      this.pageNumber++
    },

    prevPage: function() {
      this.pageNumber--
    }
  }
})


})();