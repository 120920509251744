window.main_searcher = function() {
  searcher_init_globals()
  searcher_bind_search_action_on_field()
  searcher_activate_suggestion_click()
  searcher_input_text()
}

window.searcher_init_globals = function() {
  var globals = {
    searcher_url: '/searcher/by_name',
    searcher_field: $('#searcher_test > input'),
    searcher_engine: window.searcher_autocomplete_engine
  }
  var global_vars = Object.keys(globals)

  for(i = 0; i < global_vars.length; i++){
    window[global_vars[i]] = globals[global_vars[i]]
  }
}

window.searcher_bind_search_action_on_field = function() {
  window.searcher_field.typeahead(null, {
    source: window.searcher_engine(),
    display: 'value',
    templates: {
      suggestion: Handlebars.compile(
        '<div tt-articul="{{ articul }}">{{ suggestion }}</div>', {
          noEscape: true
        }
      )
    }
  })
}

window.searcher_activate_suggestion_click = function() {
  $('body').on(
    'click','.tt-suggestion',
    window.searcher_mouseclick_on_suggestion)
  $('body').on(
    'click','#searcher_test .close',
    window.searcher_close_written
  )
}

window.searcher_autocomplete_engine = function() {
  return new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
		queryTokenizer: Bloodhound.tokenizers.whitespace,
		remote: {
			url: searcher_url + '?query=%QUERY',
			wildcard: '%QUERY'
		}
  })
}

window.searcher_input_text = function() {
  var searcher_place = $('#searcher_test > span')
  var input_place = searcher_place.find('input.int.ints.open.tt-input')
  input_place.keyup(function(event) {
    if(event.keyCode == 13) {
      inp = $(this)
      var text = inp.val()
      inp.parent().find('.tt-suggestion').each(function(e, obj){
        sugg_text = $(obj).text().replace(/<\/?em>/, '')
        if(sugg_text == text){
          articul = $(obj).attr('tt-articul')
          if(articul){
            event.preventDefault()
            searcher_open_tovar_page(articul)
          }
        }
      })
    }
  })
}

window.searcher_close_written = function() {
  var fdfd = $(this).parent().parent().parent()[0].reset()
}

window.searcher_mouseclick_on_suggestion = function() {
  var articul = $(this).attr('tt-articul')
  searcher_open_tovar_page(articul)
}

window.searcher_open_tovar_page = function(articul){
  window.location.href = '/cat/' + articul
}
