window.main_news_subscription = function() {
  window.news_subscription_activate_subscribe_button()
  window.news_subscription_activate_subscribe_checkbox()
}

window.news_subscription_activate_subscribe_button = function() {
  var subscription_modal_form = $("#subscription_modal_form")
  subscription_modal_form.on("submit", function(event) {
    event.preventDefault()
    window.news_subscription_subscribe()
  })
}

window.news_subscription_activate_subscribe_checkbox = function() {
  var new_client_form = $("#new_market_model_client")
  new_client_form.on("change", "input#subscribe", function(event) {
    var checked = $(this).is(':checked')
    if (checked) {
      if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
        window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'NEW_CLIENT_SUBSCRIBE_CHECKBOX')
      }
    }
  })
}

window.news_subscription_subscribe = function() {
  if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
    window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'NEWS_SUBSCRIPTION_SUBSCRIBE')
  }
  var form = $("#subscription_modal_form")
  var data = form.serialize()
  $.ajax({
    url: '/subscribe',
    type: 'post',
    data: data
  })
}
