// Активация jquery-ui фич
// 
// Многое активируется где попало, но я бы переносил всё в этот файл.
// Например, табы в продуктах.

window.main_jquery_ui = function() {
  window.jquery_ui_activate_tooltip()
}

window.jquery_ui_activate_tooltip = function() {
  $(document).tooltip({
    items: ".tooltip-top-tracked",
    position: {
      my: "center bottom-16",
      at: "center top"
      // collision: "none"
    },
    track: true
  })
  $(".tooltip:not(.html)").tooltip({
    position: {
      my: "center top",
      at: "center bottom"
    }
  })
  $(".tooltip.html").tooltip({
    position: {
      my: "center top",
      at: "center bottom"
    },
    content: function () {
      return $(this).prop('title')
    }
  })
  $(".tooltip-amount").tooltip({
    tooltipClass: "ui-tooltip-amount",
    position: {
      my: "center top",
      at: "center bottom"
    }
  })
}
