;(function($) {


"use strict"

window.Vue.component("CompanyAdd", {
  data: function() {
    return {
      account: window.vue.application.app.data.account,
      fields: {
        name: {
          value: null,
          label: "Название компании",
        },
        inn: {
          value: null,
          label: "ИНН",
        },
        kpp: {
          value: null,
          label: "КПП",
        }
      },
      errors: {},
      is_data_error: false,
      is_ajaxed: false
    }
  },

  methods: {

    $_ajax_company_add: function(data) {
      this.is_ajaxed = true
      $.ajax({type:'post',url:'/ajax_account/company_add',data:data})
        .then(this.$_ajax_company_add_successed)
        .fail(this.$_ajax_company_add_rejected)
    },

    $_ajax_company_add_successed: function(data, textStatus, jqXHR) {
      // Если получили триггер об успехе переключимя на другой компонент, обновив данные:
      if (data.success == 'true') {
        this.account.companies.push({id: data.id, name: data.name})
        this.account.company_success_added_show_index = 0
        this.$router.push({name: 'company_profile', params: {company_id: data.id}})
        return null
      }
      for (var key in this.fields) {
        this.fields[key].value = data[key]
      }
      this.errors = Object.assign({}, data.errors || {})
      this.is_ajaxed = false
    },

    $_ajax_company_add_rejected: function(jqXHR, textStatus, errorThrown) {
      this.is_data_error = true
      this.is_ajaxed = false
    },

    company_add_handler: function(argument) {
      var data = {}
      for (var key in this.fields) {
        data[key] = this.fields[key].value
      }
      this.$_ajax_company_add(data)
    }
  },

  template: `
    <div class="company-add">
      <h2>Добавление организации</h2>
      <div v-if="!is_data_error" class="form_z grid">

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.name.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <input type="text"
                ref="input-name"
                v-model="fields.name.value"
                tabindex="1"
              >
              <field-errors :errors="errors.name"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.inn.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <input type="text"
                ref="input-inn"
                v-model="fields.inn.value"
                tabindex="1"
              >
              <field-errors :errors="errors.inn"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.kpp.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <input type="text"
                ref="input-kpp"
                v-model="fields.kpp.value"
                tabindex="1"
              >
              <field-errors :errors="errors.kpp"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <input
                type="button"
                value="Зарегистрировать компанию"
                v-on:click="company_add_handler"
                class="primary button"
                tabindex="1"
              >
            </div>
          </div>
        </div>

      </div>
      <div v-else>
        <p>Извините, сбой приложения, повторите попытку позже.</p>
      </div>
    </div>
  `,
})


})(jQuery);
