;(function() {

  "use strict"

  window.main_jira_send = function() {
    /*jira_send_calculation_menu()
    jira_send_calculation_product()*/
  }

  window.jira_send_calculation_menu = function() {
    var order_mark = $("#js_jira_form_calucaltion_sended_menu")
    if (order_mark.length == 1) {
      if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
        window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SUCCESS_SEND_JIRA_FROM_MENU')
      }
    }
  }

  window.jira_send_calculation_product = function() {
    var order_mark = $("#js_jira_form_calucaltion_sended_product")
    if (order_mark.length == 1) {
      if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
        window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SUCCESS_SEND_JIRA_FROM_PRODUCT')
      }
    }
  }
})();
