;(function($) {

"use strict"

window.vue.application.app.component.CartCount = {
  mixins: [window.vue.application.app.mixin.CartMixin],
  data: function() {
    return {
      cart: window.vue.application.app.data.cart
    }
  },
  template: `
    <div v-bind:class="{'cart-count': true, empty: is_cart_empty}">
      <div class="link">
        <a href="/cart" title="Перейти в корзину"></a>
        <div class="count">{{ cart.list.length + cart.offers.length + cart.tuned_offers.map(function(a) {return a.offer_products.length}).reduce(function(a, b) {return a + b}, 0) + cart.offer_products.length }}</div>
        <div class="list" v-if="!is_cart_empty">
  ` +
  // Пакетные коммерческие предложения
  `
          <div class="tovar" v-for="(offer, index) in cart.offers">
            <a class="image" :href="'/kp/' + offer.offer.slug"></a>
            <div class="col">
              <div class="name"><p><a :href="'/kp/' + offer.offer.slug">{{ offer.offer.name }}</a></p></div>
              <div class="count">
                <count-corrector
                  @cc_m="handler_offer_m(offer.offer_rel.count, index)"
                  @cc_p="handler_offer_p(offer.offer_rel.count, index)"
                  @cc_i="handler_offer_i"
                  :count="offer.offer_rel.count"
                  :index="index"
                  :disabled="cart.disabled"
                ></count-corrector>
              </div>
              <div class="price">
                <price-numeric
                  :price="offer.offer.total_price || 0"
                ></price-numeric>
              </div>
            </div>
            <div class="remove" @click="handler_offer_r(index)">
              <i class="fa fa-times valign-middle"></i>
            </div>
          </div>
  ` +
  // Настраиваемые коммерческие предложения
  `
          <template v-for="(tuned_offer, offer_index) in cart.tuned_offers">
            <div class="tovar tuned_offer">
              <span class="image"></span>
              <div class="col">
                <div class="name">
                  <p>
                    <a :href="'/kp/' + tuned_offer.offer.slug">
                      Настраиваемое коммерческое предложение «{{ tuned_offer.offer.name }}»
                    </a>
                  </p>
                </div>
              </div>
              <div class="remove" @click="handler_tuned_offer_r(offer_index)">
                  <i class="fa fa-times valign-middle"></i>
              </div>
            </div>

            <div class="tovar offer_product" v-for="(offer_product, index) in tuned_offer.offer_products">
              <a class="image"
                :href="'/cat/' + offer_product.code"
                :style="'background-image:url(' + tuned_offer.products[offer_product.code].img + ')'"
              ></a>
              <div class="col">
                <div class="name">
                  <p>
                    <a :href="'/cat/' + offer_product.code">
                      {{ tuned_offer.products[offer_product.code].information.ui_name }}
                    </a>
                  </p>
                </div>
                <div class="count">
                  <count-corrector
                    @cc_m="handler_tuned_offer_product_m(offer_index, offer_product)"
                    @cc_p="handler_tuned_offer_product_p(offer_index, offer_product)"
                    @cc_i="handler_tuned_offer_product_i"
                    :count="tuned_offer.offer_rel.tune.offer_products[offer_product.id.toString()].count"
                    :index="offer_product"
                    :disabled="cart.disabled"
                  ></count-corrector>
                </div>
                <div class="price">
                  <price-numeric
                    :price="parseFloat(offer_product.price) || 0"
                  ></price-numeric>
                </div>
              </div>
              <div class="remove">
                -
              </div>
            </div>
          </template>

  ` +
  // Товары ценовых коммерческих предложений
  `
          <div class="tovar" v-for="(offer_product, index) in cart.offer_products">
            <a class="image" :href="'/kp/' + offer_product.offer.slug + '#offer_product-' + offer_product.offer_product.id"></a>
            <div class="col">
              <div class="name"><p><a :href="'/kp/' + offer_product.offer.slug + '#offer_product-' + offer_product.offer_product.id">{{ offer_product.product.name }}</a></p></div>
              <div class="count">
                <count-corrector
                  @cc_m="handler_offer_product_m(offer_product.offer_product_rel.count, index)"
                  @cc_p="handler_offer_product_p(offer_product.offer_product_rel.count, index)"
                  @cc_i="handler_offer_product_i"
                  :count="offer_product.offer_product_rel.count"
                  :index="index"
                  :disabled="cart.disabled"
                ></count-corrector>
              </div>
              <div class="price">
                <price-numeric
                  :price="offer_product.offer_product.price || 0"
                ></price-numeric>
              </div>
            </div>
            <div class="remove" @click="handler_offer_product_r(index)">
              <i class="fa fa-times valign-middle"></i>
            </div>
          </div>
  ` +
  // Товары
  `
          <div class="tovar" v-for="(tovar, index) in cart.list">
            <a class="image" :href="attr_image_href(tovar)" :style="attr_image_style(tovar)"></a>
            <div class="col">
              <div class="name"><p><a :href="attr_name_href(tovar)">{{ tovar.name }}</a></p></div>
              <div class="count">
                <count-corrector
                  @cc_m="handler_m(tovar.count, index)"
                  @cc_p="handler_p(tovar.count, index)"
                  @cc_i="handler_i"
                  :count="tovar.count"
                  :index="index"
                  :disabled="cart.disabled"
                  class="valign-middle"
                ></count-corrector>
              </div>
              <div class="price">
                <price-numeric
                  :price="tovar.price"
                  class="valign-middle"
                ></price-numeric>
              </div>
            </div>
            <div class="remove" @click="handler_r(index)">
              <i class="fa fa-times valign-middle"></i>
            </div>
          </div>
          <div class="total">
            <div class="l">
              <div class="w">
                Итого:
                <price-numeric
                  :price="cart.total.price"
                  class=""
                ></price-numeric>
              </div>
            </div>
            <div class="r">
              <div class="w">
                <a href="/cart" class="go_cart primary">Перейти в корзину</a>
                <template v-if="cart.new_offer_link">
                  <br>
                  <span v-html="cart.new_offer_link"></span>
                </template>
                <template v-if="cart.new_discountless_link">
                  <br>
                  <span v-html="cart.new_discountless_link"></span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  methods: {
    attr_image_style: function(tovar) {
      return "background-image:url(" + tovar.img + ")"
    },
    attr_name_href: function(tovar) {
      return this.$_tovar_href(tovar)
    },
    attr_image_href: function(tovar) {
      return this.$_tovar_href(tovar)
    },
    $_tovar_href: function(tovar) {
      return "/cat/" + tovar.articul
    },
  },
}

})(jQuery);
