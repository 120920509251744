;(function($) {

"use strict"

window.vue.application.app.component.CartList = {
  mixins: [window.vue.application.app.mixin.CartMixin],
  data: function() {
    return {
      cart: window.vue.application.app.data.cart,
    }
  },
  template: `
    <div class="cart-list">
      <div class="wrap marged">
        <h1 class="header">Корзина в филиале {{ cart.current_user.present ? cart.affiliate.name : cart.affiliate.display_name }}</h1>
      </div>
      <template v-if="!is_cart_empty">
        <div class="bg wrap padded">
          <table class="list">
            <thead>
              <tr class="head">
                <th class="col_img"></th>
                <th class="col_name">Наименование товара</th>
                <th class="col_price">Цена {{ cart.affiliate.currency }}</th>
                <th class="col_count">Количество</th>
                <th class="col_order_count" v-show="cart.current_user.present">Под заказ</th>
                <th class="col_total_price">Итого</th>
                <th class="col_remove" @click="handler_ra" title="Удалить все">
                  <i class="fa fa-times"></i>
                </th>
              </tr>
            </thead>
            <tbody class="body">
  ` +
  // Пакетные коммерческие предложения
  `
              <tr class="tovar" v-for="(offer, index) in cart.offers">
                <td class="col_img va-t">
                  <div class="img" style="background-image: url(/market/desktop/img/cart/percent.svg);"></div>
                </td>
                <td class="col_name">
                  <a :href="'/kp/' + offer.offer.slug">
                    {{ offer.offer.name }}
                  </a>
                  <ul class="grouped_offer-list">
                    <li v-for="item in offer.offer_products">
                      <span class="code">{{ item.product.code }}</span>
                      <a :href="attr_href(item.product.code)">{{ item.product.name }}</a>
                    </li>
                  </ul>
                </td>
                <td class="col_price">
                  <price-numeric
                    :price="offer.offer.old_total_price || 0"
                    class="discount red"
                  ></price-numeric>
                  <br>
                  <price-numeric
                    :price="offer.offer.total_price || 0"
                  ></price-numeric>
                </td>
                <td class="col_count">
                  <count-corrector
                    @cc_m="handler_offer_m(offer.offer_rel.count, index)"
                    @cc_p="handler_offer_p(offer.offer_rel.count, index)"
                    @cc_i="handler_offer_i"
                    :count="offer.offer_rel.count"
                    :index="index"
                    :disabled="cart.disabled"
                  ></count-corrector>
                </td>
                <td class="col_order_count" v-show="cart.current_user.present">
                  -
                </td>
                <td class="col_total_price">
                  <span
                    v-show="offer.old_total_price > offer.total_price"
                  >
                    <price-numeric
                      v-bind:class="{discount: true, red: true}"
                      :price="offer.old_total_price"
                    ></price-numeric>
                  </span>
                  <br v-show="offer.old_total_price > offer.total_price">
                  <price-numeric
                    :price="offer.total_price"
                  ></price-numeric>
                </td>
                <td class="col_remove" @click="handler_offer_r(index)" title="Удалить КП">
                  <i class="fa fa-times"></i>
                </td>
              </tr>
  ` +
  // Настраиваемые коммерческие предложения
  `
              <template v-for="(tuned_offer, offer_index) in cart.tuned_offers">
                <tr class="tovar tuned_offer">
                  <td colspan="6" class="talign-center">
                    Настраиваемое коммерческое предложение «{{ tuned_offer.offer.name }}»
                  </td>
                  <td class="col_remove" @click="handler_tuned_offer_r(offer_index)" title="Удалить Настраиваемое КП">
                    <i class="fa fa-times"></i>
                  </td>
                </tr>
                <tr class="tovar offer_product" v-for="(offer_product, index) in tuned_offer.offer_products">
                  <td class="col_img">
                    <div class="img" :style="attr_tovar_style(tuned_offer.products[offer_product.code].img)"></div>
                  </td>
                  <td class="col_name">
                    <a :href="'/cat/' + offer_product.code">
                      {{ tuned_offer.products[offer_product.code].information.ui_name }}
                    </a>
                    #{{ offer_product.code }}
                  </td>
                  <td class="col_price">
                    <price-numeric
                      :price="parseFloat(tuned_offer.products[offer_product.code].price) || 0"
                      class="discount red"
                    ></price-numeric>
                    <br>
                    <price-numeric
                      :price="parseFloat(offer_product.price) || 0"
                    ></price-numeric>
                  </td>
                  <td class="col_count">
                    <count-corrector
                      @cc_m="handler_tuned_offer_product_m(offer_index, offer_product)"
                      @cc_p="handler_tuned_offer_product_p(offer_index, offer_product)"
                      @cc_i="handler_tuned_offer_product_i"
                      :count="tuned_offer.offer_rel.tune.offer_products[offer_product.id.toString()].count"
                      :index="offer_product"
                      :disabled="cart.disabled"
                    ></count-corrector>
                  </td>
                  <td class="col_order_count" v-show="cart.current_user.present">
                    -
                  </td>
                  <td class="col_total_price">
                    <price-numeric
                      :price="parseFloat(offer_product.total_price) || 0"
                    ></price-numeric>
                  </td>
                  <td class="col_remove">
                  </td>
                </tr>
              </template >

  ` +
  // Товары ценовых коммерческих предложений
  `
              <tr class="tovar offer_product" v-for="(offer_product, index) in cart.offer_products">
                <td class="col_img">
                  <div class="img" :style="attr_tovar_style(offer_product.product.img)"></div>
                  <div class="code">{{ offer_product.product.code }}</div>
                </td>
                <td class="col_name">
                  <a :href="'/kp/' + offer_product.offer.slug + '#offer_product-' + offer_product.offer_product.id">
                    {{ offer_product.product.name }}
                  </a>
                </td>
                <td class="col_price">
                  <price-numeric
                    :price="offer_product.product.price || 0"
                    class="discount red"
                  ></price-numeric>
                  <br>
                  <price-numeric
                    :price="offer_product.offer_product.price || 0"
                  ></price-numeric>
                  <br>
                  <small>(-{{ offer_product.offer_product.percent.toFixed(2) }}%)</small>
                </td>
                <td class="col_count">
                  <count-corrector
                    @cc_m="handler_offer_product_m(offer_product.offer_product_rel.count, index)"
                    @cc_p="handler_offer_product_p(offer_product.offer_product_rel.count, index)"
                    @cc_i="handler_offer_product_i"
                    :count="offer_product.offer_product_rel.count"
                    :index="index"
                    :disabled="cart.disabled"
                  ></count-corrector>
                </td>
                <td class="col_order_count" v-show="cart.current_user.present">
                  -
                </td>
                <td class="col_total_price">
                  <price-numeric
                    :price="offer_product.total_price || 0"
                  ></price-numeric>
                </td>
                <td class="col_remove" @click="handler_offer_product_r(index)" title="Удалить">
                  <i class="fa fa-times"></i>
                </td>
              </tr>
  ` +
  // Товары
  `
              <tr class="tovar" v-for="(tovar, index) in cart.list">
                <td class="col_img">
                  <div class="img" :style="attr_tovar_style(tovar.img)"></div>
                  <div class="code">{{ tovar.articul }}</div>
                </td>
                <td class="col_name">
                  <a :href="attr_href(tovar.articul)">{{ tovar.name }}</a>
                  <p class="alert_suborder" v-if="tovar.order_count > 0" v-show="cart.current_user.present">
                    В заказе {{ tovar.count }}, в наличии на складе {{ tovar.amount_count }}.
                    Остальные {{ tovar.order_count == 0 ? "-" : tovar.order_count }} "Под заказ"
                  </p>
                </td>
                <td class="col_price">
                  <price-numeric
                    :price="tovar.old_price || 0"
                    v-bind:class="{discount: true, red: true}"
                    v-show="tovar.old_price > 0"
                  ></price-numeric>
                  <br v-show="tovar.old_price > 0">
                  <price-numeric
                    :price="tovar.price"
                    v-bind:class="{discount: tovar.discount_price > 0, red: true}"
                  ></price-numeric>
                  <br v-show="tovar.discount_price > 0">
                  <price-numeric
                    v-show="tovar.discount_price > 0"
                    :price="tovar.discount_price || 0"
                  ></price-numeric>
                </td>
                <td class="col_count">
                  <count-corrector
                    @cc_m="handler_m(tovar.count, index)"
                    @cc_p="handler_p(tovar.count, index)"
                    @cc_i="handler_i"
                    :count="tovar.count"
                    :index="index"
                    :disabled="cart.disabled"
                  ></count-corrector>
                <td class="col_order_count" v-show="cart.current_user.present">{{ tovar.order_count == 0 ? "-" : tovar.order_count  }}</td>
                </td>
                <td class="col_total_price">
                  <price-numeric
                    :price="tovar.total_old_price || 0"
                    v-bind:class="{discount: true, red: true}"
                    v-show="tovar.total_old_price > 0"
                  ></price-numeric>
                  <br v-show="tovar.total_old_price > 0">
                  <price-numeric
                    :price="tovar.total_price"
                    v-bind:class="{discount: tovar.discount_total_price > 0, red: true}"
                  ></price-numeric>
                  <br v-show="tovar.discount_total_price > 0">
                  <span style="white-space: nowrap">
                    <price-numeric
                      v-show="tovar.discount_total_price > 0"
                      :price="tovar.discount_total_price || 0"
                    ></price-numeric>
                    <span v-show="tovar.discount_total_price > 0 && tovar.order_count > 0">
                      *
                    </span>
                  </span>
                </td>
                <td class="col_remove" @click="handler_r(index)" title="Удалить">
                  <i class="fa fa-times"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  ` +
  // Итого
  `
        <div class="total-line bg2">
          <div class="promo">
            <!--Если у вас есть промо-код, введите его в это поле: <input type="text" name="promo" value="">-->
          </div>
          <div class="total">
            <span
              v-show="cart.total.old_price > cart.total.price"
            >
              <strong>
                <price-numeric
                  v-bind:class="{discount: true}"
                  :price="cart.total.old_price"
                ></price-numeric>
              </strong>
              {{ cart.affiliate.currency }}
            </span>
            <br v-show="cart.total.old_price > cart.total.price">
            <span
              v-show="cart.total.discount_price < cart.total.price"
            >
              <strong>
                <price-numeric
                  v-bind:class="{discount: true}"
                  :price="cart.total.price"
                ></price-numeric>
              </strong>
              {{ cart.affiliate.currency }}
            </span>
            <br v-show="cart.total.discount_price < cart.total.price">
            <strong>
              Итого:
              <price-numeric
                :price="cart.total.discount_price < cart.total.price ? cart.total.discount_price : cart.total.price"
              ></price-numeric>
            </strong>
            {{ cart.affiliate.currency }}
            <p v-if="cart.is_discount_note" v-show="cart.current_user.present">
              <small class="white color">
                * — скидка применяется только к товару в наличии.
              </small>
            </p>
          </div>
        </div>
        <div class="control-line bg">
          <a class="button submit" href="/cart/to_order">Перейти к оформлению в филиале {{ this.cart.affiliate.name }}</a>
          <template v-if="cart.new_offer_link">
            <br>
            <span v-html="cart.new_offer_link"></span>
          </template>
          <template v-if="cart.new_discountless_link">
            <br>
            <span v-html="cart.new_discountless_link"></span>
          </template>
        </div>
      </template>
      <template v-else>
        <div class="bg wrap padded">
          <p>Корзина пуста</p>
        </div>
      </template>
    </div>
  `,
  methods: {
    attr_tovar_style: function(img) {
      return (img != null) ? "background-image:url(" + img + ")" : ""
    },
    attr_href: function(articul) {
      return "/cat/" + articul
    },
  }
}

})(jQuery);
