window.main_callback = function() {
  window.callback_activate_open()
}

window.callback_activate_open = function() {
  var button = $(".callback.side_buttons-button")
  button.on('click', function() {
    var modal = $('#modal-8')
    modal.arcticmodal({
      overlay: {
        css: { opacity: .3 }
      }
    });
  })
}
