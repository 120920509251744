window.main_orders_history = function() {
  var orders_history = $(".orders_history")
  if (orders_history.length != 1) {
    return null
  }
  window.orders_history_activate_order()
}

window.orders_history_activate_order = function() {
  var orders = $(".orders_history .order")
  orders.on("click", ".order_header, .order_footer", function(event) {
    var order = $(event.delegateTarget)
    order.toggleClass("detailed")
  })
}
