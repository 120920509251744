;(function() {


"use strict"

window.vue.application.app.router.CabinetRouter = {}
window.vue.application.app.router.CabinetRouter.main = function() {
  window.vue.application.app.router.CabinetRouter.instance = new VueRouter({
    mode: 'history', 
    routes: [
      { path:'/account',                             name: 'user_profile', component: window.Vue.component('UserInfo') },
      { path:'/account/user_history',                name: 'user_history', component: window.Vue.component('OrdersHistory') },
      { path:'/account/company/:company_id',         name: 'company_profile', component: window.Vue.component('CompanyInfo') },
      { path:'/account/company_history/:company_id', name: 'company_history', component: window.Vue.component('OrdersHistory') },
      { path:'/account/add_company',                 name: 'company_add', component: window.Vue.component('CompanyAdd') }
    ]
  })
}


})();
