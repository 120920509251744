;(function() {


"use strict"

Object.assign(
  window.vue.application.app.data,
  {
    cart: {
      list: [],
      total: {
        price: 0
      },
      disabled: false,
      refresh_timeout_id: null,
      refresh_tovar_articul: null,
      options: {
        refresh_timeout_interval: 700,
        api_controller_name: "ajax_cart",
        api_request_type: "get",
      }
    }
  }
)
window.vue.application.app.mixin.CartMixin = {
  computed: {
    is_cart_empty: function() {
      return this.cart.list.length == 0 &&
        this.cart.offers.length == 0 &&
        this.cart.tuned_offers.length == 0 &&
        this.cart.offer_products.length == 0
    },
  },
  created: function() {
    this.$_brush_data()
  },
  beforeUpdate: function() {
    this.$_brush_data()
  },
  methods: {
    handler_m: function(count, index) {
      var articul = this.cart.list[index].articul
      this.$_check_events_order(articul)
      count-= this.cart.list[index].count_step
      if (count < 0) {
        count = 0
      }
      this.cart.list[index].count = count
      this.$_ajax_set(articul, count)
    },
    handler_p: function(count, index) {
      var articul = this.cart.list[index].articul
      this.$_check_events_order(articul)
      count+= this.cart.list[index].count_step
      this.cart.list[index].count = count
      this.$_ajax_set(articul, count)
    },
    handler_i: function(count, index) {
      var articul = this.cart.list[index].articul
      this.$_check_events_order(articul)
      this.$_ajax_set(articul, count)
    },
    handler_r: function(index) {
      var articul = this.cart.list[index].articul
      this.$_check_events_order(articul)
      this.$_ajax_del(articul)
    },
    handler_offer_m: function(count, index) {
      var offer_id = this.cart.offers[index].offer.id
      count-= 1
      if (count < 0) {
        count = 0
      }
      this.cart.offers[index].offer_rel.count = count
      this.$_ajax_set_offer(offer_id, count)
    },
    handler_offer_p: function(count, index) {
      var offer_id = this.cart.offers[index].offer.id
      count+= 1
      this.cart.offers[index].offer_rel.count = count
      this.$_ajax_set_offer(offer_id, count)
    },
    handler_offer_i: function(count, index) {
      var offer_id = this.cart.offers[index].offer.id
      this.$_ajax_set_offer(offer_id, count)
    },
    handler_offer_r: function(index) {
      var offer_id = this.cart.offers[index].offer.id
      this.$_ajax_del_offer(offer_id)
    },
    handler_tuned_offer_r: function(index) {
      var offer_id = this.cart.tuned_offers[index].offer.id
      this.$_ajax_del_offer(offer_id)
    },
    handler_offer_product_m: function(count, index) {
      var offer_product_id = this.cart.offer_products[index].offer_product.id
      count-= 1
      if (count < 0) {
        count = 0
      }
      this.cart.offer_products[index].offer_product_rel.count = count
      this.$_ajax_set_offer_product(offer_product_id, count)
    },
    handler_offer_product_p: function(count, index) {
      var offer_product_id = this.cart.offer_products[index].offer_product.id
      count+= 1
      this.cart.offer_products[index].offer_product_rel.count = count
      this.$_ajax_set_offer_product(offer_product_id, count)
    },
    handler_offer_product_i: function(count, index) {
      var offer_product_id = this.cart.offer_products[index].offer_product.id
      this.$_ajax_set_offer_product(offer_product_id, count)
    },
    handler_tuned_offer_product_m: function(offer_index, offer_product) {
      var count = this.cart.tuned_offers[offer_index].offer_rel.tune.offer_products[offer_product.id.toString()].count
      var delta = this.cart.tuned_offers[offer_index].products[offer_product.code].minimum
      var minimum = offer_product.count
      count = count - delta
      if (count < minimum) {
        count = minimum
      }
      this.cart.tuned_offers[offer_index].offer_rel.tune.offer_products[offer_product.id.toString()].count = count
      this.$_ajax_set_tuned_offer_product(offer_product.id, count)
    },
    handler_tuned_offer_product_p: function(offer_index, offer_product) {
      var count = this.cart.tuned_offers[offer_index].offer_rel.tune.offer_products[offer_product.id.toString()].count
      var delta = this.cart.tuned_offers[offer_index].products[offer_product.code].minimum
      count = count + delta
      this.cart.tuned_offers[offer_index].offer_rel.tune.offer_products[offer_product.id.toString()].count = count
      this.$_ajax_set_tuned_offer_product(offer_product.id, count)
    },
    handler_tuned_offer_product_i: function(count, index) {
      this.$_ajax_set_tuned_offer_product(index.id, count)
    },
    handler_offer_product_r: function(index) {
      var offer_product_id = this.cart.offer_products[index].offer_product.id
      this.$_ajax_del_offer_product(offer_product_id)
    },
    handler_ra: function() {
      if (this.cart.disabled == true) {return null}
      this.$_ajax_del_all()
    },
    handler_o: function() {
      window.modals.show('cart-order')
    },

    $_check_events_order: function(articul) {
      // Если вся корзина заблокирована то не реагируем
      if (this.cart.disabled == true) {return null}
      // Если изменения были и они были не на текущем товаре, то не реагируем (необходимо сначало применить изменения другого товара)
      if (this.cart.refresh_tovar_articul != null && this.cart.refresh_tovar_articul != articul) {return null}
    },

    $_ajax_set: function(articul, count) {
      this.$_ajax_clear_timeout()
      this.$_ajax_set_timeout(this.$_set, articul, count)
    },
    $_ajax_del: function(articul) {
      this.$_ajax_clear_timeout()
      this.$_ajax_set_timeout(this.$_del, articul)
    },
    $_ajax_del_all: function() {
      this.$_ajax_clear_timeout()
      this.$_ajax_set_timeout(this.$_del_all)
    },
    $_ajax_set_offer: function(offer_id, count) {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
      var instance = this
      this.cart.refresh_timeout_id = setTimeout(
        function() {
          $.ajax({
            type: "get",
            url: "/ajax_cart/set_offer",
            data: {
              id: offer_id,
              count: count
            },
            data_custom: {
              instance: instance
            },
            success: function(data, textStatus, jqXHR) {
              instance.$_set_promise_resolved(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
              instance.$_set_promise_rejected(textStatus)
            },
          })
        },
        this.cart.options.refresh_timeout_interval
      )
    },
    $_ajax_del_offer: function(offer_id) {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
      var instance = this
      this.cart.refresh_timeout_id = setTimeout(
        function() {
          $.ajax({
            type: "get",
            url: "/ajax_cart/del_offer",
            data: {
              id: offer_id
            },
            data_custom: {
              instance: instance
            },
            success: function(data, textStatus, jqXHR) {
              instance.$_set_promise_resolved(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
              instance.$_set_promise_rejected(textStatus)
            },
          })
        },
        this.cart.options.refresh_timeout_interval
      )
    },
    $_ajax_set_offer_product: function(offer_product_id, count) {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
      var instance = this
      this.cart.refresh_timeout_id = setTimeout(
        function() {
          $.ajax({
            type: "get",
            url: "/ajax_cart/set_offer_product",
            data: {
              id: offer_product_id,
              count: count
            },
            data_custom: {
              instance: instance
            },
            success: function(data, textStatus, jqXHR) {
              instance.$_set_promise_resolved(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
              instance.$_set_promise_rejected(textStatus)
            },
          })
        },
        this.cart.options.refresh_timeout_interval
      )
    },
    $_ajax_set_tuned_offer_product: function(offer_product_id, count) {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
      var instance = this
      this.cart.refresh_timeout_id = setTimeout(
        function() {
          $.ajax({
            type: "get",
            url: "/ajax_cart/set_tuned_offer_product",
            data: {
              id: offer_product_id,
              count: count
            },
            data_custom: {
              instance: instance
            },
            success: function(data, textStatus, jqXHR) {
              instance.$_set_promise_resolved(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
              instance.$_set_promise_rejected(textStatus)
            },
          })
        },
        this.cart.options.refresh_timeout_interval
      )
    },
    $_ajax_del_offer_product: function(offer_product_id) {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
      var instance = this
      this.cart.refresh_timeout_id = setTimeout(
        function() {
          $.ajax({
            type: "get",
            url: "/ajax_cart/del_offer_product",
            data: {
              id: offer_product_id
            },
            data_custom: {
              instance: instance
            },
            success: function(data, textStatus, jqXHR) {
              instance.$_set_promise_resolved(data)
            },
            error: function(jqXHR, textStatus, errorThrown) {
              instance.$_set_promise_rejected(textStatus)
            },
          })
        },
        this.cart.options.refresh_timeout_interval
      )
    },

    $_ajax_clear_timeout: function() {
      if (this.cart.refresh_timeout_id != null) {
        clearTimeout(this.cart.refresh_timeout_id)
      }
    },
    $_ajax_set_timeout: function() {
      var params = Array.prototype.slice.call(arguments)
      var callback = params.shift()
      this.cart.refresh_timeout_id = setTimeout(function() {
        callback.apply(null, params)
      }, this.cart.options.refresh_timeout_interval)
    },

    cart_get: function() {
      this.cart.disabled = true
      var instance = this
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: instance.cart.options.api_request_type,
          url: '/' + instance.cart.options.api_controller_name + '/get',
          data: {
          },
          data_custom: {
            instance: instance
          },
          cache: false, // оно добавляет параметр `_` - это как минимум неприлично
          success: function(data, textStatus, jqXHR) {
            resolve(data)
            instance.$_cart_get_successed(data)
          },
          error: function(jqXHR, textStatus, errorThrown) {
            reject(textStatus)
            instance.$_cart_get_errored(textStatus)
          },
        })
      })
    },
    cart_add: function(articul, count) {
      this.cart.disabled = true
      this.cart.refresh_tovar_articul = articul
      var instance = this
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: instance.cart.options.api_request_type,
          url: '/' + instance.cart.options.api_controller_name + '/add',
          data: {
            articul: articul,
            count: count
          },
          data_custom: {
            instance: instance
          },
          cache: false, // оно добавляет параметр `_` - это как минимум неприлично
          success: function(data, textStatus, jqXHR) {
            resolve(data)
            instance.$_cart_add_successed(data)
          },
          error: function(jqXHR, textStatus, errorThrown) {
            reject(textStatus)
            instance.$_cart_add_errored(textStatus)
          },
        })
      })
    },
    cart_set_data: function(data) {
      // window.vue.application.app.data.cart.list = data.list
      // window.vue.application.app.data.cart.offers = data.offers
      // window.vue.application.app.data.cart.tuned_offers = data.tuned_offers
      // window.vue.application.app.data.cart.offer_products = data.offer_products
      // window.vue.application.app.data.cart.total = data.total
      // window.vue.application.app.data.cart.affiliate = data.affiliate
      // window.vue.application.app.data.cart.new_offer_link = data.new_offer_link
      // window.vue.application.app.data.cart.current_user = data.current_user
      Object.assign(window.vue.application.app.data.cart, data)
    },
    $_set: function(articul, count) {
      this.cart.disabled = true
      this.cart.refresh_tovar_articul = articul
      var instance = this
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: instance.cart.options.api_request_type,
          url: '/' + instance.cart.options.api_controller_name + '/set',
          data: {
            articul: articul,
            count: count
          },
          data_custom: {
            instance: instance
          },
          cache: false, // оно добавляет параметр `_` - это как минимум неприлично
          success: function(data, textStatus, jqXHR) {
            resolve(data)
            instance.$_set_promise_resolved(data)
          },
          error: function(jqXHR, textStatus, errorThrown) {
            reject(textStatus)
            instance.$_set_promise_rejected(textStatus)
          },
        })
      })
    },
    $_del: function(articul) {
      this.cart.disabled = true
      this.cart.refresh_tovar_articul = articul
      var instance = this
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: instance.cart.options.api_request_type,
          url: '/' + instance.cart.options.api_controller_name + '/del',
          data: {
            articul: articul,
          },
          data_custom: {
            instance: instance
          },
          cache: false, // оно добавляет параметр `_` - это как минимум неприлично
          success: function(data, textStatus, jqXHR) {
            resolve(data)
            instance.$_del_promise_resolved(data)
          },
          error: function(jqXHR, textStatus, errorThrown) {
            reject(textStatus)
            instance.$_del_promise_rejected(textStatus)
          },
        })
      })
    },
    $_del_all: function() {
      this.cart.disabled = true
      var instance = this
      return new Promise(function(resolve, reject) {
        $.ajax({
          type: instance.cart.options.api_request_type,
          url: '/' + instance.cart.options.api_controller_name + '/del_all',
          data: {
          },
          data_custom: {
            instance: instance
          },
          cache: false, // оно добавляет параметр `_` - это как минимум неприлично
          success: function(data, textStatus, jqXHR) {
            resolve(data)
            instance.$_del_all_promise_resolved(data)
          },
          error: function(jqXHR, textStatus, errorThrown) {
            reject(textStatus)
            instance.$_del_all_promise_rejected(textStatus)
          },
        })
      })
    },
    $_set_promise_resolved: function(data) {
      this.cart_set_data(data)
      this.cart.disabled = false
      this.cart.refresh_tovar_articul = null
    },
    $_set_promise_rejected: function() {
    },
    $_del_promise_resolved: function(data) {
      this.cart_set_data(data)
      this.cart.disabled = false
      this.cart.refresh_tovar_articul = null
    },
    $_del_promise_rejected: function() {
    },
    $_del_all_promise_resolved: function(data) {
      this.cart_set_data(data)
      this.cart.disabled = false
      this.cart.refresh_tovar_articul = null
    },
    $_del_all_promise_rejected: function() {
    },
    $_cart_add_successed: function(data) {
      this.cart_set_data(data)
      this.cart.disabled = false
      this.cart.refresh_tovar_articul = null
    },
    $_cart_add_errored: function(reason) {
      // TODO создать состояние компонента в случае ошибки, переключить на него тут и в $_cart_get_errored
    },
    $_cart_get_successed: function(data) {
      this.cart_set_data(data)
      this.cart.disabled = false
    },
    $_cart_get_errored: function(reason) {
    },
    $_brush_data: function() {
      // Сноска о действии скидки только на товары в наличии.
      this.cart.is_discount_note = false
      for (var i = 0, l = this.cart.list.length; i < l; i++) {
        var item = this.cart.list[i]
        // Шаг минимального прибавления может быть не заполнен.
        if (item.count_step == null) {
          item.count_step = 1
        }
        // Появилась сноска о скидке.
        if (item.order_count > 0 && item.discount_total_price > 0) {
          this.cart.is_discount_note = true
        }
      }
    },
  }
}


})();
