window.main_category_menu = function() {
  // console.log('> main_category_menu')
  set_active_categories()
  // window.activate_fixed()
  // cheked_heights()
}

window.set_active_categories = function() {
  var category_menu_container = $('.subcat')
  var category_menu = category_menu_container.children('ul')
  var active = category_menu.find('li.active')
  active.parentsUntil('.subcat').filter('li').addClass('active_parent')
}
