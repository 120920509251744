"use strict"

$(document).on("turbolinks:load", function() {
  var vknews_place = $(".vknews")
  if (vknews_place.length != 1) {
    return null
  }

  window.vknews = new Vknews(vknews_place)
})

function Vknews(place) {
  this.place = place
  this.slider = this.place.find(".vknews-slider")
  this.prev = this.place.find(".vknews-prev")
  this.next = this.place.find(".vknews-next")
  this.position = 0
  this.count = 0
  this.activate_buttons()
  this.ui_state()
  this.load_vknews()
  return this
}

Vknews.prototype.activate_buttons = function() {
  this.place.on("click", ".vknews-prev", this.prev_callback.bind(this))
  this.place.on("click", ".vknews-next", this.next_callback.bind(this))
  this.place.on("click", ".vknews-slide", this.click_slide_callback.bind(this))
}

Vknews.prototype.prev_callback = function(event) {
  this.prev_step()
  this.ui_state()
}

Vknews.prototype.next_callback = function(event) {
  this.next_step()
  this.ui_state()
  this.load_vknews()
}

Vknews.prototype.click_slide_callback = function(event) {
  var slide = $(event.currentTarget)
  var player = slide.data("player")
  var modal9 = $("#modal-9")
  modal9.arcticmodal({
    overlay: {
      css: { opacity: .3 }
    },
    beforeOpen: function(data, el) {
      modal9.find(".youtube_news_video").empty()
      $(".callback_report").animate({ right: -100 }, 400)
      $(".error_report").animate({ right: -100 }, 400)
      var iframe = $("<iframe>").attr({
        "src": player,
        "width": 1000, 
        "height": 600,
        "allowFullScreen":'allowFullScreen',
        "allow": "autoplay; encrypted-media; fullscreen; picture-in-picture;",
        "frameborder": "0"
      })
      modal9.find(".youtube_news_video").append(iframe)
    },
    beforeClose: function(data, el) {
      $(".callback_report").animate({ right: 0 }, 400)
      $(".error_report").animate({ right: 0 }, 400)
    }
  })
}

Vknews.prototype.next_step = function() {
  if (this.position < this.count - 1) {
    this.position = this.position + 1
    var left = this.position * -288
    this.slider.css("left", left + "px")
  }
}

Vknews.prototype.prev_step = function() {
  if (this.position > 0) {
    this.position = this.position - 1
    var left = this.position * -288
    this.slider.css("left", left + "px")
  }
}

Vknews.prototype.load_vknews = function() {
  // Определим текущую страницу
  var current_page = Math.ceil((this.position + 1) / 5)
  // Определим сколько всего страниц
  var total_pages = Math.ceil(this.count / 5)
  // Производим загрузку следующей страницы слайдов только если мы на последней странице.
  if (current_page >= total_pages) {
  } else {
    return null
  }

  // Следующая страница
  var page = total_pages + 1
  var instance = this
  $.ajax({
    url: `/vk_videos/vknews?page=${page}`,
    dataType: "json",
    cache: false,
    timeout: 3000
  }).fail(function(jqXHR, textStatus, errorThrown) {
    console.log('jqXHR.responseJSON: ', jqXHR.responseJSON)
    console.log('jqXHR.responseText: ', jqXHR.responseText)
  }).done(function(data, textStatus, jqXHR) {
    data.forEach(function(e, i, a) {
      instance.slider.append(`
        <div class="vknews-slide"
          style="background-image: url(${e.thumbnail_url});"
          data-player="${e.player}"
        >
          <div class="vknews-header">${e.title}</div>
        </div>
      `)
    })
    instance.calc_count()
    instance.ui_state()
  })
}

Vknews.prototype.ui_state = function() {
  if (this.position == 0) {
    this.prev.addClass("disabled")
  } else {
    this.prev.removeClass("disabled")
  }
  if (this.position + 1 >= this.count) {
    this.next.addClass("disabled")
  } else {
    this.next.removeClass("disabled")
  }
}

Vknews.prototype.calc_count = function() {
  this.count = this.slider.find(".vknews-slide").length
}
