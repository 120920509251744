;(function(){


  window.Market.config.filter = {
    colors: {
      "Бежевый"      : "F5DEB3",
      "Белый"        : "FFFFFF",
      "Бирюзовый"    : "20B2AA",
      "Бордовый"     : "800000",
      "Бронза"       : "DAA520",
      "Голубой"      : "00FFFF",
      "Желтый"       : "FFFF00",
      "Жёлтый"       : "FFFF00",
      "Зелёный"      : "008000",
      "Зеленый"      : "008000",
      "Золотой"      : "FFD700",
      "Коричневый"   : "8B4513",
      "Красный"      : "ff0000",
      "Медь"         : "FF4500",
      "Оранжевый"    : "FFA500",
      "Оранжевый (O)": "FFA500",
      "Разноцветный" : "FFFFFF",
      "Розовый"      : "FFC0CB",
      "Серебристый"  : "C0C0C0",
      "Серый"        : "808080",
      "Синий"        : "0000FF",
      "Фиолетовый"   : "800080",
      "Хром"         : "C0C0C0",
      "Черный"       : "000000",
      "Желтый (Y)"      : "20B2AA",
      "Белый (W)"       : "ffffff",
      "Желтый (Y)"      : "ffff00",
      "Маджента (M)"    : "ff00ff",
      "Металлик (MT)"   : "808080",
      "Св.маджента (LM)": "ff66ff",
      "Св.циан (LC)"    : "e0ffff",
      "Св.черный (LK)"  : "000000",
      "Циан (C)"        : "00ffff",
      "Черный (B)"      : "000000"
    },
    urls:{
      "Лак (V)"      : "/admin/files/strict_color/csp/j3_6127/image/csp_j3_6127.png",
      "Промывка"     : "/admin/files/strict_color/csp/j3_6127/image/csp_j3_6127.png",
      "Золотистый"   : "/admin/files/strict_color/csp/j6_65/image/csp_j6_65.png",
      "Прозрачный"   : "/admin/files/strict_color/csp/j3_6127/image/csp_j3_6127.png",
      "Многоцветный" : "/admin/files/strict_color/rds/j3_542/image/rds_j3_542.png"
    }
  }

})();
