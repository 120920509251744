Number.prototype.declension = function(words) {
  var number = this
  if (typeof words === "string") {
    words = words.split(/\s*,\s*/)
  }
  if (words[1] == null || words[1] == "") {
    words[1] = words[0]
  }
  if (words[2] == null || words[2] == "") {
    words[2] = words[1]
  }
  if (!Number.isInteger(number.valueOf())) {
    return words[2]
  }
  var modulo_100 = Math.abs(number) % 100
  if (11 <= modulo_100 && modulo_100 <= 19) {
    return words[0]
  }
  var modulo_10 = Math.abs(number) % 10
  switch(modulo_10) {
    case 1:
      return words[1]
    break
    case 2:
    case 3:
    case 4:
      return words[2]
    break
    default:
      return words[0]
    break
  }
}
