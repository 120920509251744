;(function() {


"use strict"

window.Vue.component("PriceNumeric", {
  props: {
    price: Number
  },
  data: function() {
    return {}
  },
  template: '\
    <span class="price-numeric">\
      {{ $_celoye() }}\
      <span>\
        {{ $_drobnoye() }}\
      </span>\
    </span>\
  ',
  methods: {
    $_celoye: function() {
      var whole = Math.trunc(this.price)
      return whole.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") 
    },
    $_drobnoye: function() {
      return this.price.toFixed(2).split(".")[1] || "00"
    }
  }
})


})();
