;(function(){
  "use strict"

  window.Market.trader = {}

  window.Market.trader.main = function() {
    // console.log('> window.Market.trader.main')
  }

  window.Market.trader.set_info_by_phil = function(phil_name) {
    if (phil_name==null||phil_name=='') { return null }
    var articuls = window.Market.TovarCollection.get_instance().get_articuls()
    console.log('articuls: ', articuls)
  }

})();
