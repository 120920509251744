// 
// Подключаем основные библиотеки такие как jquery, vue…
// 

import "babel-polyfill";

import Rails from "rails-ujs";
Rails.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

// import * as ActiveStorage from 'activestorage';
// ActiveStorage.start();

import $ from "jquery"
global.$ = $
global.jQuery = $

import "slick-carousel"
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css"

import "lightbox2"
import "lightbox2/dist/css/lightbox.css"

import 'font-awesome/scss/font-awesome'

import "underscore"

import LazyLoad from "vanilla-lazyload"
window.LazyLoad = LazyLoad

import Vue from "vue/dist/vue.js"
window.Vue = Vue

import VModal from "vue-js-modal"
window.VModal = VModal

import VueRouter from "vue-router"
window.VueRouter = VueRouter
// const originalPush = VueRouter.prototype.push
// window.VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

import axios from "axios";
global.axios = axios

import VueRecaptcha from "vue-recaptcha";
global.VueRecaptcha = VueRecaptcha

import InfinityScroll from "../modules/infinity_scroll.js"
window.InfinityScroll = InfinityScroll




// 
// Подключаем наши скрипты
// 

function requireAll(r) { r.keys().forEach(r); }

import "../sprockets/lib/jquery.arcticmodal-0.3.min"
import "../sprockets/lib/jquery.carouFredSel-6.2.1-packed"
import "../sprockets/lib/jquery.cookie"
import "../sprockets/lib/jquery.countdown.min"
import "../sprockets/lib/jquery.mousewheel-3.0.6.pack"
import "../sprockets/lib/jquery.placeholder.min"

// jquery-ui
import "../modules/jquery-ui-1.12.1.custom/jquery-ui.min"
import "../modules/jquery-ui-1.12.1.custom/jquery-ui.min.css"
import "../modules/jquery-ui-1.12.1.custom.scss"

import "../sprockets/lib/jquery.truncate.min.js"
import "../sprockets/lib/datepicker-ru.js"
window.Handlebars = require("../sprockets/lib/handlebars")
import "../sprockets/lib/object_assign.js"
import "../sprockets/lib/math_trunc"
import Bloodhound from "../sprockets/lib/typeahead.bundle.min.js"
global.Bloodhound = Bloodhound

import Bowser from "bowser";
global.bowser = Bowser.getParser(window.navigator.userAgent);

import "../sprockets/config/init"
import "../sprockets/config/app"
import "../sprockets/config/filter"

requireAll(require.context('../sprockets/class', true, /\.(js|coffee)$/))

requireAll(require.context('../sprockets/func', true, /\.(js|coffee)$/))




// 
// Подключаем наши vue скрипты
// 

import "../sprockets/vue/vue.js"
requireAll(require.context('../sprockets/vue/component', true, /\.(js|coffee)$/))
import "../sprockets/vue/application/app.js"
import "../sprockets/vue/application/app/router/CabinetRouter.js"
requireAll(require.context('../sprockets/vue/application/app/mixin', true, /\.(js|coffee)$/))
requireAll(require.context("../sprockets/vue/application/app/component", true, /\.(js|coffee)$/))

import vue_zagination from 'vue-zagination';
window.vue_zagination = vue_zagination
import 'vue-zagination/dist/vue-zagination.css'


// 
// Подключаем Стили
// 

import "../override/zlader/zlader-custom.css"
import "../modules/zlader/zlader.js"
import "../modules/zlader/zlader.css"

// SASS
import "../styles/sass/sass"
// CSS
import "../styles/css/ala_bem/events.css"
import "../styles/css/ala_bem/filter_checkbox.css"
import "../styles/css/ala_bem/knowledges.css"
import "../styles/css/ala_bem/pre_cat.css"
import "../styles/css/ala_bem/searcher.css"
import "../styles/css/ala_bem/signtv.css"
import "../styles/css/ala_bem/slider3.css"
import "../styles/css/ala_bem/slider4.css"
import "../styles/css/ala_bem/top.css"
import "../styles/css/ala_bem/wrap.css"
import "../styles/css/ala_bem/zenoselect.css"
import "../styles/css/ala_bem/static_pages/about_us.css"

import "../styles/css/style_aa.css"
import "../styles/css/style_ab.css"
import "../styles/css/style_ac.css"
import "../styles/css/style_ad.css"
import "../styles/css/style_ae.css"
import "../styles/css/style_af.css"
import "../styles/css/style_ag.css"
import "../styles/css/style_ah.css"
import "../styles/css/style_ai.css"

import "../styles/css/style_fix_aa.css"
import "../styles/css/style_fix_ab.css"
import "../styles/css/style_fix_ac.css"
import "../styles/css/style_fix_ad.css"
import "../styles/css/style_fix_ae.css"
import "../styles/css/style_fix_af.css"
import "../styles/css/style_fix_ag.css"

import "../styles/css/script.css"


// 
// Активация скриптов
// 

$(document).on("turbolinks:load", function() {
  setTimeout(function() {
    window.vue.main()
    window.main_account()
    window.main_orders_history()
  }, 0)
  setTimeout(window.main_hovers, 0)
  setTimeout(window.main_lazyload, 0)
  setTimeout(function() {
    window.Market.AjaxLiner.activate()
    window.Flash.activate()
    window.SassFramework.activate()
  }, 0)
  setTimeout(window.main_forum_posts, 0)
  setTimeout(window.main_modal, 0)
  setTimeout(window.main_filter, 3)
  setTimeout(window.main_phil, 5)
  setTimeout(window.Market.trader.main, 5)
  setTimeout(window.main_setting, 10)
  setTimeout(window.main_category_menu, 10)
  setTimeout(window.main_catalog_items, 25)
  setTimeout(window.main_searcher, 35)
  setTimeout(window.main_write_to_leader, 10)
  setTimeout(window.main_buying_report, 10)
  setTimeout(window.main_callback, 10)
  setTimeout(window.main_news_subscription, 10)
  setTimeout(window.main_order, 50)

  // Функция filter_apply_session() зависит и от FC и от TC поэтому так:
  setTimeout(function() {
    window.Market.FilterCollection.activate()
    window.Market.TovarCollection.activate()
    window.filter_apply_session()
  }, 70)

  setTimeout(function(){
    window.Market.Tovar.activate()
  }, 90)
  // setTimeout(window.main_videoblock, 10)
  window.main_jquery_ui()
  window.InfinityScroll.activate({bottom_padding: 300})
  window.main_offers()
})
