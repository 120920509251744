window.main_filter = function() {
  filter_globals()
  filter_destroy_session_if_needed()
  filter_monkey_patching_change_url()
}

// init
window.filter_globals = function() {
  window['filter_placeholder_selector'] = "div.filter_b" 
  window['filter_checkbox_pickers_selector'] = "input[class='filter_checkbox-input']"
  window['filter_checkbox_pickers'] = $(filter_checkbox_pickers_selector)
  window['filter_delimiter'] = '-=delimiter=-'
}

window.filter_destroy_session_if_needed = function() {
  if ($.cookie('uptime') != window.global_uptime) {
    console.log('first_time!')
    filter_destroy_session()
    $.cookie('uptime', window.global_uptime, {path: '/', expires: 1})
  }
}

/* 
 * Если переходим по урлам категорий, то добавляем напиканное в фильтрах в сессию?
 */
window.filter_monkey_patching_change_url = function() {
  $("a").click(function(event) {
    // Не любой клик должен гадить в сессию, 
    // вернее у нас есть меню филиалов, в котором ```<a>Волгоград</a>``` без href
    var link = $(this)
    var is_has_href = (link.attr('href') ? true : false)
    if (
      window.location.href.match(/\/cat\//) &&
      is_has_href
    ) {
      filter_set_to_session(filter_get_pickers(), $(this).attr('href'))
    }
  })
}

//interface
window.apply_filter = function() {

  $("div[tovar_id]").hide()
  for(i = 0; i < window['pick_data'].length; i++){
    f_selector = window['pick_data'][i]
    filtered_ids = window['filter_picker'][f_selector]
    for(n = 0; n < filtered_ids.length; n++){
      $("div[tovar_id='"+ filtered_ids[n] +"'").show()
    }
  }
  if(window['pick_data'].length == 0){
    $("div[tovar_id]").show()
  }
}

window.filter_apply_session = function(){
  filter_clear_pickers()
  filter_get_session()
  if (filter_session.length > 0){
    filter_filter_filter_session()
    filter_filtering_tovars(filter_session)
    filter_set_pickers(filter_session)
  }
}

/**
 * Отфильтровать filter_session
 * Напикано может быть такое, чего нет ни у одного товара,
 * проверяем есть ли значения в фильтрах или грохаем напиканное
 * TODO проверить на зависимость от безлимитной загрузки
 * @return {void}
 */
window.filter_filter_filter_session = function() {
  if (Object.prototype.toString.call(window.filter_session) !== '[object Array]') {
    return null
  }
  for (var i = window.filter_session.length - 1; i >= 0; i--) {
    var filter_pick = window.filter_session[i]
    var filter_name = filter_pick.split(window.Market.FilterCollection.get_instance().options.pick_data_delimiter)[0]
    var filter_value = filter_pick.split(window.Market.FilterCollection.get_instance().options.pick_data_delimiter)[1]
    if (filter_name == null) {
      continue
    }
    if (filter_value == null) {
      continue
    }
    // Это хорошо, когда в filters_data есть данные обо всех фильтрах,
    // но мы не ищем лёгких путей, "Бренды" обрабатывать отдельно!
    // switch(true) {
    //   case filter_name == 'Бренды':
    //     if (
    //       window.Market.FilterCollection.get_instance().pickers_data[filter_name + ' ' + filter_value] == null
    //     ) {
    //       window.filter_session.splice(i, 1)
    //     }
    //     break
    //   default:
        if (
          window.Market.FilterCollection.get_instance().filters_data[filter_name] == null ||
          !window.Market.FilterCollection.get_instance().filters_data[filter_name].includes(filter_value)
        ) {
          window.filter_session.splice(i, 1)
        }
    //     break
    // }
  }
}

// engine
window.filter_set_to_session = function(filter_picker, href) {
  $.ajax({
    url: '/filter/picker_set',
    type: 'post',
    async: false, // Деприкейтед же (
    data: {filter_picker: filter_picker, href: href},
    success: function(response) {
      window['filter_set_picker_response'] = response
    }
  })
}

window.filter_remove_from_session = function(pick_item) {
  $.ajax({
    url: '/filter/picker_remove',
    type: 'post',
    async: true,
    data: {pick_item: pick_item},
    success: function(response) {

    }
  })
}

window.filter_get_session = function() {
  $.ajax({
    url: '/filter/picker_get',
    type: 'get',
    async: false,
    success: function(response){
      window['filter_session'] = JSON.parse(response)
    }
  })
  return filter_session
}

window.filter_destroy_session = function() {
   $.ajax({
    url: '/filter/picker_remove',
    type: 'post',
    async: false,
    success: function(response) {
      console.log(response)
    }
  })
}

window.filter_get_pickers = function() {
  return window.Market.FilterCollection.get_instance().puck_pick_data()
}

window.filter_filtering_tovars = function(pick_data) {
  Market.FilterCollection.get_instance().pick_data = pick_data.slice(0)
  Market.TovarCollection.get_instance().apply_filter()
}

// work only on checkboxes and FilterInterval now
window.filter_set_pickers = function(filter_session) {
  var fc = window.Market.FilterCollection.get_instance()
  var tc = window.Market.TovarCollection.get_instance()
  // Просто вствить сессионные данные на место - ничего не сделать
  fc.pick_data = fc.unpuck_pick_data(filter_session)
  // Вот что нужно сделать после:
  fc.checkize_pick_data() // Начекать на фильтрах (ни на что более не влияет)
  fc.buttons_reset_apply_pick_data() // Начекать на "не фильтры", ага
  tc.apply_filter() // Применить начеканное к товарам
  fc.redisable_pickers() // В зависимости от начеканного что-то дизейблится…
}

//work only on checkboxes now
window.filter_clear_pickers = function() {
  $('input[filter_picker_id]').each(function() {
    $(this).prop('checked', false)
  })
}
