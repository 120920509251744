;(function() {


"use strict"

window.Vue.component("CompanyInfo", {

  mixins: [window.vue.application.app.mixin.PersonInfoMixin],

  data: function() {
    return {
      account: window.vue.application.app.data.account,
      fields: {
        id: {
          value: null
        },
        name: {
          value: null,
          label: "Наименование компании",
          is_editable: false,
        },
        inn: {
          value: null,
          label: "ИНН",
          is_editable: false,
        },
        kpp: {
          value: null,
          label: "КПП",
          is_editable: false,
        },
        legal_name: {
          value: null,
          label: "Юридическое название компании",
          is_editable: false,
        },
        ogrn: {
          value: null,
          label: "ОГРН (Основной государственный регистрационный номер)",
          is_editable: false,
        },
        legal_form: {
          value: null,
          label: "Организационно-правовая форма",
          is_editable: false,
        },
        bik: {
          value: null,
          label: "БИК (Банковский идентификационный код)",
          is_editable: false,
        },
        bank_name: {
          value: null,
          label: "Наименоваиние банка",
          is_editable: false,
        },
        correspondent_account: {
          value: null,
          label: "Корреспондентский счёт",
          is_editable: false,
        },
        checking_account: {
          value: null,
          label: "Расчётный счёт",
          is_editable: false,
        },
        phone_number: {
          value: null,
          label: "Номер телефона",
          is_editable: false,
        },
        email: {
          value: null,
          label: "Электронная почта",
          is_editable: false,
        },
      },
      field_errors: {
        messages: {},
        details: {},
      },
      is_error: false,
    }
  },

  // Реагирует только на реактивные свойства.
  computed: {
  },

  mounted: function() {
    this.$parent.$consider_ccd()
    this.$_ajax_get_company_info()
  },

  // Тут необходимо оперировать с нереактивными свойствами, иначе вечный ререндер.
  updated: function() {
    if (this.account.company_success_added_show_index != null) {
      this.account.company_success_added_show_index += 1
    }
  },

  watch: {
    '$route': function(to, from) {
      this.$_ajax_get_company_info()
    },
  },

  methods: {

    // Метод вызывается при рендеринге (присутствует в шаблоне) и происходит учёт нереактивных свойств.
    is_company_success_added: function() {
      return this.account.company_success_added_show_index != null && this.account.company_success_added_show_index < 1
    },

    $_ajax_get_company_info: function() {
      this.is_error = false
      this.error_type = null
      axios.get(
        '/ajax_account/company_info/' + this.account.person_id
      ).then(
        this.$_ajax_company_info_successed
      ).catch(
        this.$_ajax_company_info_rejected
      )
    },

    $_ajax_post_company_info: function(data) {
      this.is_error = false
      this.error_type = null
      axios.post(
        '/ajax_account/company_info/' + this.account.person_id,
        data
      ).then(
        this.$_ajax_company_info_successed
      ).catch(
        this.$_ajax_company_info_rejected
      )
    },

    $_ajax_company_info_successed: function(response) {
      if (response.data == null) {
        throw new Error("Данные не получены.");
      }
      var ajax_fields = response.data.field_values
      var ajax_field_keys = Object.keys(ajax_fields)
      for (var vue_field_key in this.fields) {
        if (ajax_field_keys.includes(vue_field_key)) {
          this.fields[vue_field_key].value = ajax_fields[vue_field_key]
        }
      }
      this.field_errors.messages = Object.assign({}, response.data.errors.messages)
      this.field_errors.details = Object.assign({}, response.data.errors.details)
    },

    // Если шаблон узнает тип ошибки - отобразит соответствующую,
    // иначе покажет неизвестную ошибку.
    $_ajax_company_info_rejected: function(error) {
      if (error.response.data != null && error.response.data.error != null) {
        this.error_type = error.response.data.error.type
      }
      this.is_error = true
    },

    company_del_handler: function(event) {
      var data = {id: this.fields.id.value}
      this.$_ajax_company_del(data)
    },

    $_ajax_company_del: function(data) {
      this.is_error = false
      this.error_type = null
      $.ajax({
        type: 'post',
        url: '/ajax_account/company_del',
        data: data,
        // Передадим данные запроса в объект jqXHR для получения их в .then(…) и .fail(…)
        beforeSend: function(jqXHR) {
          jqXHR.custom_data = {id: data.id}
          return true
        }
      }).then(function(data, textStatus, jqXHR) {
        if (data.success == 'true') {
          for (var i = this.account.companies.length - 1; i >= 0; i--) {
            var company = this.account.companies[i]
            if (company.id == jqXHR.custom_data.id) {
              this.account.companies.splice(i, 1)
              i--
            }
          }
          // Назначаем физ лицо как источник данных, если
          // была удалена уомпания принятая в качестве источника.
          // Это дублирует бизнес логику бэкэнда, что не красиво!!!
          if (this.account.applied_person_id == jqXHR.custom_data.id) {
            this.account.applied_person_id = 'user'
          }
          this.account.company_success_deleted_show_index = 0
          this.account.person_id = 'user'
          return null
        }
      }.bind(this)).fail(function(jqXHR, textStatus, errorThrown) {
        if (jqXHR.responseJSON.error != null && jqXHR.responseJSON.error.type != null) {
          this.error_type = jqXHR.responseJSON.error.type
        }
        this.is_error = true
      }.bind(this))
    },

    apply_company_handler: function(event) {
      var data = {id: this.account.person_id}
      this.$_ajax_apply_company(data)
    },

    $_ajax_apply_company: function(data) {
      this.is_error = false
      this.error_type = null
      $.ajax({
        type: 'post',
        url: '/ajax_account/apply_company',
        data: data,
        // Передадим данные запроса в объект jqXHR для получения их в .then(…) и .fail(…)
        beforeSend: function(jqXHR) {
          jqXHR.custom_data = {id: data.id}
          return true
        }
      }).then(function(data, textStatus, jqXHR) {
        if (data.success == 'true') {
          this.account.applied_person_id = jqXHR.custom_data.id
        }
      }.bind(this)).fail(function(jqXHR, textStatus, errorThrown) {
        if (jqXHR.responseJSON.error != null && jqXHR.responseJSON.error.type != null) {
          this.error_type = jqXHR.responseJSON.error.type
        }
        this.is_error = true
      }.bind(this))
    },

    pencil_click_handler: function(field_name) {
      this.fields[field_name].cache = this.fields[field_name].value
      this.fields[field_name].is_editable = true
      this.$nextTick(function() {
        this.$refs["input-" + field_name].focus()
      }.bind(this))
    },

    enter_handler: function(field_name) {
      this.fields[field_name].is_editable = false
      if (this.fields[field_name].value == this.fields[field_name].cache) {
        return null
      }
      this.$_ajax_post_company_info({field_name: field_name, field: this.fields[field_name]})
    },

    blur_handler: function(field_name) {
      if (this.fields[field_name].is_editable == false) {
        return null
      }
      this.fields[field_name].is_editable = false
      if (this.fields[field_name].value == this.fields[field_name].cache) {
        return null
      }
      this.$_ajax_post_company_info({field_name: field_name, field: this.fields[field_name]})
    },

    escape_handler: function(field_name) {
      this.fields[field_name].is_editable = false
      this.fields[field_name].value = this.fields[field_name].cache
    },
  },

  template: `
    <div class="company-info">
      <div class="hugging blocks_row">
        <div class="right">
          <b v-if="account.person_id == account.applied_person_id">Реквизиты по умолчанию</b>
          <span
            v-else
            class="small primary button"
            @click="apply_company_handler"
            title="Назначить компанию источником реквизитов для совершения покупок."
          >Назначить реквизиты по умолчанию</span>
        </div>
        <div>
          <h2>Профиль организации</h2>
        </div>
      </div>
      <div v-show="is_company_success_added()">
        <p class="message iconed bordered closing success">Компания успешно добавленна.</p>
      </div>
      <div v-if="!is_error" class="form_z grid">

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.name.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('name')" class="as_input">{{ this.fields.name.value }}</span>
              <input type="text"
                ref="input-name"
                v-model="fields.name.value"
                v-show="$_is_show_input('name')"
                @blur="blur_handler('name')"
                @keyup.enter="enter_handler('name')"
                @keyup.esc="escape_handler('name')"
                @focus="pencil_click_handler('name')"
              >
              <div
                v-show="$_is_show_pencil('name')"
                @click="pencil_click_handler('name')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['name']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.inn.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('inn')" class="as_input">{{ this.fields.inn.value }}</span>
              <input type="text"
                ref="input-inn"
                v-model="fields.inn.value"
                v-show="$_is_show_input('inn')"
                @blur="blur_handler('inn')"
                @keyup.enter="enter_handler('inn')"
                @keyup.esc="escape_handler('inn')"
                @focus="pencil_click_handler('inn')"
              >
              <div
                v-show="$_is_show_pencil('inn')"
                @click="pencil_click_handler('inn')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['inn']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.kpp.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('kpp')" class="as_input">{{ this.fields.kpp.value }}</span>
              <input type="text"
                ref="input-kpp"
                v-model="fields.kpp.value"
                v-show="$_is_show_input('kpp')"
                @blur="blur_handler('kpp')"
                @keyup.enter="enter_handler('kpp')"
                @keyup.esc="escape_handler('kpp')"
                @focus="pencil_click_handler('kpp')"
              >
              <div
                v-show="$_is_show_pencil('kpp')"
                @click="pencil_click_handler('kpp')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['kpp']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.legal_name.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('legal_name')" class="as_input">{{ this.fields.legal_name.value }}</span>
              <input type="text"
                ref="input-legal_name"
                v-model="fields.legal_name.value"
                v-show="$_is_show_input('legal_name')"
                @blur="blur_handler('legal_name')"
                @keyup.enter="enter_handler('legal_name')"
                @keyup.esc="escape_handler('legal_name')"
                @focus="pencil_click_handler('legal_name')"
              >
              <div
                v-show="$_is_show_pencil('legal_name')"
                @click="pencil_click_handler('legal_name')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['legal_name']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.bik.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('bik')" class="as_input">{{ this.fields.bik.value }}</span>
              <input type="text"
                ref="input-bik"
                v-model="fields.bik.value"
                v-show="$_is_show_input('bik')"
                @blur="blur_handler('bik')"
                @keyup.enter="enter_handler('bik')"
                @keyup.esc="escape_handler('bik')"
                @focus="pencil_click_handler('bik')"
              >
              <div
                v-show="$_is_show_pencil('bik')"
                @click="pencil_click_handler('bik')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['bik']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.bank_name.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('bank_name')" class="as_input">{{ this.fields.bank_name.value }}</span>
              <input type="text"
                ref="input-bank_name"
                v-model="fields.bank_name.value"
                v-show="$_is_show_input('bank_name')"
                @blur="blur_handler('bank_name')"
                @keyup.enter="enter_handler('bank_name')"
                @keyup.esc="escape_handler('bank_name')"
                @focus="pencil_click_handler('bank_name')"
              >
              <div
                v-show="$_is_show_pencil('bank_name')"
                @click="pencil_click_handler('bank_name')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['bank_name']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.correspondent_account.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('correspondent_account')" class="as_input">{{ this.fields.correspondent_account.value }}</span>
              <input type="text"
                ref="input-correspondent_account"
                v-model="fields.correspondent_account.value"
                v-show="$_is_show_input('correspondent_account')"
                @blur="blur_handler('correspondent_account')"
                @keyup.enter="enter_handler('correspondent_account')"
                @keyup.esc="escape_handler('correspondent_account')"
                @focus="pencil_click_handler('correspondent_account')"
              >
              <div
                v-show="$_is_show_pencil('correspondent_account')"
                @click="pencil_click_handler('correspondent_account')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['correspondent_account']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.checking_account.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('checking_account')" class="as_input">{{ this.fields.checking_account.value }}</span>
              <input type="text"
                ref="input-checking_account"
                v-model="fields.checking_account.value"
                v-show="$_is_show_input('checking_account')"
                @blur="blur_handler('checking_account')"
                @keyup.enter="enter_handler('checking_account')"
                @keyup.esc="escape_handler('checking_account')"
                @focus="pencil_click_handler('checking_account')"
              >
              <div
                v-show="$_is_show_pencil('checking_account')"
                @click="pencil_click_handler('checking_account')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['checking_account']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.phone_number.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('phone_number')" class="as_input">{{ this.fields.phone_number.value }}</span>
              <input type="text"
                ref="input-phone_number"
                v-model="fields.phone_number.value"
                v-show="$_is_show_input('phone_number')"
                @blur="blur_handler('phone_number')"
                @keyup.enter="enter_handler('phone_number')"
                @keyup.esc="escape_handler('phone_number')"
                @focus="pencil_click_handler('phone_number')"
              >
              <div
                v-show="$_is_show_pencil('phone_number')"
                @click="pencil_click_handler('phone_number')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['phone_number']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.email.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('email')" class="as_input">{{ this.fields.email.value }}</span>
              <input type="text"
                ref="input-email"
                v-model="fields.email.value"
                v-show="$_is_show_input('email')"
                @blur="blur_handler('email')"
                @keyup.enter="enter_handler('email')"
                @keyup.esc="escape_handler('email')"
                @focus="pencil_click_handler('email')"
              >
              <div
                v-show="$_is_show_pencil('email')"
                @click="pencil_click_handler('email')"
                class="pencil"
              ></div>
              <field-errors :errors="this.field_errors.messages['email']"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <input
                type="button"
                value="Удалить компанию"
                v-on:click="company_del_handler"
                class="secondary small button"
                tabindex="2"
              >
            </div>
          </div>
        </div>

      </div>
      <div v-else>
        <template v-if="error_type === 'company_not_found'">
          <p class="message iconed bordered danger">Компания не найдена.</p>
        </template>
        <template v-else-if="error_type === 'access_dinied'">
          <p class="message iconed bordered danger">Вы не авторизованы.</p>
        </template>
        <template v-else>
          <p class="message iconed bordered danger">Неизвестная ошибка приложения, попробуйте перезагрузить страницу.</p>
        </template>
      </div>
    </div>
  `
})


})();
