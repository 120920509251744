Array.prototype.remove = function(value) {
    var idx = this.indexOf(value);
    if (idx != -1) {
        // Второй параметр - число элементов, которые необходимо удалить
        return this.splice(idx, 1);
    }
    return false;
}
Array.prototype.remove_all = function(value) {
  for(var i = 0, l = this.length; i < l; i++) {
    if(this[i] == value) {
      this.splice(i, 1)
    }
  }
  return this
}
Array.prototype.clone = function() {
  return this.slice(0)
}
