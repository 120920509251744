window.main_phil = function() {
  phil_bind_change()
  phil_init()
  phil_ask_init()
}

// 
// При загрузке страницы устанавливаем в куки филиал _выбранный сервером_
// если __вдруг__ в куках ничего нет
// 
window.phil_ask_init = function() {
  var choose_town = $('.your_town')
  var phil_name = $("#js_phil_title").data('name')
  if (phil_name == 'Москва - Гольяново') {
    var short_name = phil_name.slice(0,6)
    $('.your_town .txt').empty().append('Вы находитесь в' + ' ' + '<div class="city_phil_name">' + short_name + '?</div>')
  } else {
    $('.your_town .txt').empty().append('Вы находитесь в' + ' <div class="city_phil_name">' + phil_name + '?</div>')
  }

  if ($.cookie('showtown') != '1') {
      choose_town.show()
  }

  $('.ask_city_modal').click(function() {
    choose_town.hide()
    set_cookie()
  })
  $('.your_town .btn_yes').click(function(){
    choose_town.hide()
    set_cookie()
  })
  $('.your_town .btn_no').click(function() {
    $('#modal_affiliates').fadeIn()
    $(".overlay").fadeIn()
    $('.your_town').hide()
    set_cookie()
  })
}

window.set_cookie = function() {
  $.cookie('showtown', '1', {
    path: '/',
    expires: 180
  })
}

window.phil_init = function() {
  var cookie_phil = $.cookie('phil')
  if (cookie_phil == null) {
    var phil_name = $("#js_phil_title").data('name')
    if (phil_name == null || typeof(phil_name) != 'string' || phil_name == '') {
      return null
    }
    phil_set_cookie(phil_name, true)
  }
  var phil_title = $("#js_phil_title")
  phil_title.removeClass('hidden')
}

// 
// Навешиваем обработчик клика на элементы списка «выбор филиала»
// 
window.phil_bind_change = function() {
  $(".town li a").click(function(event) {
    event.preventDefault()
    if (window.uncached.data.current_user != null) {
      var message = "\
        Клиент \"" + window.uncached.data.current_client.ui_name + "\"\
        привязан к филиалу " + window.uncached.data.phil_model.display_name + ".\
        Сменить привязку можно в настройках клиента.\
        Продолжить?\
      "
      if (confirm(message)) {
        window.location.pathname = "/account"
      } else {
        $(".overlay").fadeOut();
        $("#modal_affiliates").fadeOut();
      }
      return null
    }
    if (page_cart_order()) {
      if (!confirm('Смена филиала приведёт к перезагрузке страницы, цены товаров будут пересчитаны, продолжить?')) {
        return null
      }
    }
    var button = $(this)
    var affiliate_name = button.data('name')
    var affiliate_display_name = button.text()
    if (affiliate_name == null) {
      return null
    }

    phil_set(affiliate_name, false)

    if (page_cart_order()) {
      window.location.reload()
      return null
    }
    if (page_events()) {
      window.location.reload()
      return null
    }
    if (page_shipping() || page_payment()) {
      window.location.reload()
      return null
    }
    if (page_tovar()) {
      window.location.reload()
      return null
    }
    nav_contacts = $('#nav_contacts')
    nav_contacts.attr('href', '/company/contacts/' + affiliate_name)
    console.log('affiliate_name: ', affiliate_name)
    // Покидаем эту страницу если мы на странице контактов, ага
    if ($('#contacts').length) {
      window.location.replace('/company/contacts/' + affiliate_name)
      return null
    }

    $(".overlay").fadeOut();
    $("#modal_affiliates").fadeOut();

    phil_ui_refresh(affiliate_name, affiliate_display_name)
  })
}

// 
// Попытка установить в куки, с проверкой через сервер,
// если сервер даст другой филиал, тогда вновь влияем на пользовательский интерфейс
// 
window.phil_set = function(phil_name, update_interface) {
  phil_set_cookie(phil_name)
  phil_ensure_set(phil_name, update_interface)
}

window.phil_set_cookie = function(phil_name) {
  $.cookie('phil', phil_name, {
    path: '/', expires: 180
  })
}

window.phil_ensure_set = function(phil_name, update_interface){
  $.ajax({
    url: '/geo/phil',
    type: 'post',
    async: false,
    data: {name: phil_name},
    dataType: 'json',
    success: function(resp) {
      if (resp["phil"] != $.cookie('phil')) {
        phil_set_cookie(resp["phil"])
        if (update_interface) {
          phil_ui_refresh(resp["phil"], resp['display_name'])
        }
      }
      // Заставим vue обновить инфу о корзине при смене филиала.
      window.app.cart_get()
    }
  })
}

// 
// Запускаем изменения UI связанные с выбранным филиалом
// 
window.phil_ui_refresh = function(phil_name, phil_display_name) {
  // Это наименование во всплывающем окне (вёрстка ржачная, не обращаем внимания.)
  $("#phil_name_in_select").text(phil_display_name)
  // Это наименование в кнопке для всплытия списка «выбор филиала»
  var phil_button = $("#js_phil_title")
  phil_button.text(phil_display_name)
  phil_button.attr('data-name', phil_name)

  // Если у нас кот значит мы показывам то, на что влияет трейдер
  if (page_catalog() || page_tovar()) {
    trader_apply_async(phil_name)
  }

  // Изменяем информацию о филиале на текущей странице
  $.ajax({
    type: 'get',
    url: '/ajax_affiliate/phonenumber',
    cache: false,
    success: function(data, textStatus, jqXHR) {
      var phone_number_places = $('#header .phone, #footer .phone')
      phone_number_places.each(function(index, dom) {
        $(dom).html(data)
      })
    }
  })

  if (page_contacts()) {
    // Ссылка на страницу контактов меняется в зависимости от выбранного филиала
    nav_contacts = $('#nav_contacts')
    nav_contacts.attr('href', '/company/contacts/' + phil_name)
  }
}
