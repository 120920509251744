window.main_videoblock = function() {
  console.log('> main_videoblock')
  if (page_home()){
    window.videoblock_listener = setInterval(videoblock_signtv_status_checker, 1000)
  } else {
    clearInterval(window.videoblock_listener)
  }
}

window.videoblock_template_youtube = function() {
  s = '\
    <div class="box-liner">\
      <div class="liner">\
        <div class="font">\
          <span class="mad liner-next"></span>\
          <span class="liner-prev disable"></span>\
        </div>\
        <div class="liner-line" >\
        </div>\
      </div>\
    </div>\
  '
  return s
}

window.videoblock_popup = function(video_id) {
  event.preventDefault()
  var modal9 = $('#modal-9')
  modal9.arcticmodal({
    overlay: {
      css: { opacity: .3 }
    },
    beforeOpen: function(data, el) {
      modal9.find('.youtube_news_video').empty()
      $(".callback_report").animate({ right: -100 }, 400);
      $(".error_report").animate({ right: -100 }, 400);
      var iframe = $("<iframe>").attr({
        "src": $(video_id).data("link"),
        "width": 1000, 
        "height": 600,
        "allowFullScreen":'allowFullScreen'
      })
      modal9.find('.youtube_news_video').append(iframe)
    },
    beforeClose: function(data, el) {
      $(".callback_report").animate({ right: 0 }, 400);
      $(".error_report").animate({ right: 0 }, 400);
    }
  });
}

window.videoblock_template_signtv = function() {
  s = '\
    <div class="sign_tv">\
      <iframe src="http://sign-tv.tv" width="306" height="304"></iframe>\
    </div>\
  '
  return s
}

window.videoblock_signtv_status_checker = function() {
  $.ajax({
    type: 'get',
    url: '/signtv/status',
    dataType: 'json',
    success: function(resp){
      if (resp["status"] == "0") {
        if (videoblock_is_signtv()) {
          videoblock_set_youtube()
        }
      }
      if (resp["status"] == "1"){
        if (videoblock_is_youtube()) {
          videoblock_set_signtv()
        }
      }
    }
  })
}

window.videoblock_set_signtv = function() {
  $(".videoblock").html(videoblock_template_signtv())
}

window.videoblock_set_youtube = function() {
  $(".videoblock").html(videoblock_template_youtube())
  // window.Market.AjaxLiner($(".videoblock"), window.Market.AjaxLiner.options)
  window.Market.AjaxLiner.activate()
}

window.videoblock_is_signtv = function() {
  return $(".videoblock .sign_tv").length
}

window.videoblock_is_youtube = function() {
  return $(".videoblock .box-liner").length
}
