;(function() {


"use strict"

window.Vue.component("OrdersHistory", {

  data: function() {
    return {
      account: window.vue.application.app.data.account,
      orders: [],
      pagination: {
        current: 1,
        total: 1,
      },
      is_error: false,
      is_ajax: false,
    }
  },

  // Реагирует только на реактивные свойства.
  computed: {
  },

  mounted: function() {
    this.$parent.$consider_ccd()
    this.$ajax_get_order_history()
  },

  watch: {
    '$route': function(to, from) {
      this.$ajax_get_order_history()
    },
  },

  methods: {

    // Запрашивает с сервера историю заказов в соответствии с
    // текущим состоянием, получая данные - заменяет текущие новыми.
    $ajax_get_order_history: function() {
      this.is_ajax = true
      var data = {
        page: this.pagination.current,
        person_type: this.$parent.person_type,
        id: this.$parent.$data.person_id
      }
      $.ajax({
        type: 'post',
        data: data,
        url: '/ajax_account/order_history',
      }).then(function(data, textStatus, jqXHR) {
        this.orders = data.orders.slice(0)
        Object.assign(this.pagination, data.pagination)
        this.is_error = false
        this.is_ajax = false
      }.bind(this)).fail(function(jqXHR, textStatus, errorThrown) {
        this.is_error = true
        this.is_ajax = false
      }.bind(this))
    },

    collapsing_handler: function(order, event) {
      order.is_collapsed = !order.is_collapsed
    },

    pagination_handler: function(page) {
      this.pagination.current = page
      this.$ajax_get_order_history()
    },
  },

  template: `
    <div class="orders-history">
      <template v-if="!is_error">
        <div
          v-for="(order, index) in orders"
          class="order"
          v-show="orders.length > 0"
          :class="{ ajax: is_ajax }"
        >
          <div class="order_header">
            Заказ № {{ order.id }} от {{ order.datetime }} {{ order.payment_type }}
            <div
              class="collapsing"
              :class="{up: !order.is_collapsed}"
              @click="collapsing_handler(order, $event)"
            ></div>
          </div>
          <div class="order_content">
            <div v-show="order.is_collapsed" class="grid row">
              <div class="col-4">
                <div class="wrap padded cell">
                  {{ order.order_items.length }}
                  <declension
                    :words="'товаров товар товара'"
                    :number="order.order_items.length"
                  ></declension>
                </div>
              </div>
              <div class="col-4">
                <div class="wrap padded cell">{{ order.delivery }}</div>
              </div>
              <div class="col-4">
                <div class="wrap padded cell talign-right">
                  <price-numeric :price="order.price"></price-numeric> ₽
                </div>
              </div>
            </div>
            <div
              v-show="!order.is_collapsed"
              class="grid"
            >
              <template
                v-for="(item, index) in order.order_items"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="wrap padded cell">{{ item.name }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="wrap padded cell">{{ item.articul }}</div>
                  </div>
                  <div class="col-4">
                    <div class="wrap padded cell">{{ item.count }}</div>
                  </div>
                  <div class="col-4">
                    <div class="wrap padded cell talign-right">
                      <price-numeric :price="item.total_price"></price-numeric>
                      ₽
                    </div>
                  </div>
                </div>
              </template>
              <div class="row">
                <div class="col-6">
                  <div class="wrap padded cell">{{ order.delivery }}</div>
                </div>
                <div class="col-6">
                  <div class="wrap padded cell talign-right">
                    <price-numeric :price="order.delivery_price"></price-numeric>
                    ₽
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="order_footer grid row"
            v-show="!order.is_collapsed"
          >
            <div class="col-6">
              <div class="wrap padded cell"></div>
            </div>
            <div class="col-6">
              <div class="wrap padded cell talign-right">
                <div
                  class="collapsing"
                  :class="{up: !order.is_collapsed}"
                  @click="collapsing_handler(order, $event)"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <zagination
          :current="pagination.current"
          :total="pagination.total"
          @zagination_click="pagination_handler"
          v-show="orders.length > 0"
        ></zagination>
        <div v-show="orders.length == 0" class="wrap">
          <p>Нет заказов.</p>
        </div>
      </template>
      <div v-else>
        <p>Извините, сбой приложения, повторите попытку позже.</p>
      </div>
    </div>
  `,
})


})();
