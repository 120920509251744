"use strict"

$(document).on("turbolinks:load", function() {
  activate_hidden_phone_number()
})

function activate_hidden_phone_number() {
  $("body").on("click", ".hidden_phone_number", function(event) {
    var hidden_phone_number = $(event.currentTarget)
    hidden_phone_number.text(hidden_phone_number.data("phone_number"))
    hidden_phone_number.removeClass("tooltip-top-tracked")
    hidden_phone_number.removeClass("hidden")
    window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SHOW_PHONE_NUMBER')
  })
}
