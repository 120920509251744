;(function() {


"use strict"

window.Vue.component("Declension", {

  props: {
    words: [String, Array],
    number: [Number, Object],
  },

  data: function() {
    return {
    }
  },

  computed: {
    word: function() {
      var words = null
      if (typeof this.words === "string") {
        words = this.words.split(" ")
      } else {
        words = this.words.slice(0)
      }
      if (words[1] == null || words[1] == "") {
        words[1] = words[0]
      }
      if (words[2] == null || words[2] == "") {
        words[2] = words[1]
      }
      var number = null
      if (typeof this.number === 'number') {
        number = this.number
      } else {
        number = 0
      }
      var modulo_100 = Math.abs(number) % 100
      if (11 <= modulo_100 && modulo_100 <= 19) {
        return words[0]
      }
      var modulo_10 = Math.abs(number) % 10
      switch(modulo_10) {
        case 1:
          return words[1]
        break
        case 2:
        case 3:
        case 4:
          return words[2]
        break
        default:
          return words[0]
        break
      }
    },
  },

  template: `
    <span>{{ word }}</span>
  `
})


})();
