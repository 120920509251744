;(function($, _) {

  "use strict"

  var instance

  function Flash(options) {
    this.options = Object.assign(this.constructor.default_options, options || {})
  }

  Flash.prototype.activate = function() {
    this.place = $(this.options.selectors.place)
    if (this.place.length != 1) {
      return this
    }
    this.activate_close()
    return this
  }

  Flash.prototype.activate_close = function() {
    this.place.on('click', this.options.selectors.close, {instance: this}, this.close_handler)
    this.place.on('click', this.options.selectors.flash, {instance: this}, this.close_handler)
  }

  Flash.prototype.close_handler = function(event) {
    // Останавливаем "всплытие" события (устраняет проблему двойного срабатывания - это очевидно!)
    event.stopPropagation()
    var instance = event.data.instance
    var object = $(this)
    switch(true) {
      case object.is(instance.options.selectors.flash):
        var flash = object
      break
      case object.is(instance.options.selectors.close):
        var close = $(this)
        var flash = close.parents(instance.options.selectors.flash).first()
      break
    }
    flash.addClass("closed")
  }

  Flash.default_options = {
    selectors: {
      place: "#flashes",
      flash: ".flash",
      close: ".flash-close"
    }
  }

  Flash.activate = function(options) {
    return this.get_instance(options).activate()
  }
  Flash.get_instance = function(options) {
    if (instance == null) {
      instance = new this(options)
    }
    return instance
  }

  window.Flash = Flash

})(jQuery, _);
