window.main_trader = function() {
}

// Аякс вызывающийся при смене филиала на товаре или категории в:
// файле: app/javascript/sprockets/func/phil.js
// функции: window.phil_ui_refresh
window.trader_apply_async = function(phil_name, articuls) {

  articuls = articuls || catalog_items_get_articuls()

  var ajax_config = {
    url: null,
    type: "post",
    async: true,
    data: {
      phil: phil_name
    },
    success: function(response) {
      window.trader_set_traders(response)
    }
  }
 
  if (page_tovar()) {
    ajax_config.url = "/trader/" + articuls[0]
    ajax_config.data.tovar_buy_small = false
  } else if (page_catalog()) {
    ajax_config.url = "/traders/by_phil"
    ajax_config.data.articuls = articuls.join(',')
  } else {
    return null
  }

  $.ajax(ajax_config)
}

window.trader_get_traders = function(phil_name, articuls, ajax_data){
  var ajax, traders

  ajax = $.extend({}, ajax_data, {
    success: function(resp){
      traders = resp
    }
  })

  $.ajax(ajax)

  return traders
}

window.trader_set_traders = function(items_trader){
  if(page_tovar()){
    trader_set_ui_for_tovar_detail(items_trader)
  }
  if(page_catalog()){
    trader_set_ui_for_items(items_trader)
  }
}

window.trader_set_ui_for_items = function(items_trader){
  var articuls, tc, tovar_place

  dom_group_blocks_to_tovar_blocks()

  $('div[trader_articul]').each(function(){
    trader_set_ui_for_item_block($(this), items_trader, 'trader_articul')
  })

  $('div[data-tovar_articul]').each(function(){
    trader_set_ui_for_item_block($(this), items_trader, 'data-tovar_articul')
  })
}

window.trader_set_ui_for_item_block = function(item_block, items_trader, attr){
  var tc, trader

  tc = window.Market.TovarCollection.get_instance()
  trader = items_trader[item_block.attr(attr)]

  item_block.find(tc.options.tovar.selectors.price).html(trader[4])
  item_block.find(tc.options.tovar.selectors.amount).html(trader[5])
  item_block.find(tc.options.tovar.selectors.buy).html(trader[6])
  if (trader[9]) {
    item_block.addClass("promotion")
  } else {
    item_block.removeClass("promotion")
  }
}

window.trader_set_ui_for_tovar = function(articul, items_trader){
  var item_block

  item_block = $('div[data-tovar_articul]="'+ articul +'"')
  trader_set_ui_for_item_block(item_block, items_trader)
}

window.trader_set_ui_for_tovar_detail = function(items_trader) {
  var data = items_trader

  if (!data||Object.prototype.toString.call(data) != '[object Array]') {
    return null
  }
  var product = $("#product[data-id]").first()
  if (!product.length) {
    return null
  }
  if (data[9]) {
    product.addClass("promotion")
  } else {
    product.removeClass("promotion")
  }

  window.Market.Tovar.get_instance().ui_set_price(data[4])
  window.Market.Tovar.get_instance().ui_set_amount(data[5])
  window.Market.Tovar.get_instance().ui_set_buy(data[6])
}

// Аякс вызывающийся для смены торговой информации при изменении фильтров
// группы.
window.trader_set_for_tovar = function(articul, phil, group_slug) {
  group_slug = group_slug || false
  $.ajax({
    type: 'post',
    url: '/trader/' + articul,
    data: {
      phil: phil,
      tovar_buy_small: true
    },
    async: true,
    dataType: 'json',
    success: function(data, textStatus, jqXHR) {
      var tc = window.Market.TovarCollection.get_instance()
      if (!tc) {
        return null
      }
      var tovar_place = null
      switch (true) {
        case group_slug && group_slug.trim().length > 0:
          tovar_place = $('.products div[tovar_id][slug_container="' + group_slug + '"]')
          break
        case articul && articul.trim().length > 0:
          tovar_place = $('.products div[tovar_id][data-tovar_articul="' + articul + '"]')
          break
      }
      if (!tovar_place||!tovar_place.length) {
        return null
      }
      tovar_place.find(tc.options.tovar.selectors.price).html(data[4])
      tovar_place.find(tc.options.tovar.selectors.amount).html(data[5])
      tovar_place.find(tc.options.tovar.selectors.buy).html(data[6])
    }
  })
}

// Смена не филиала, а фильтров группы - приводит к смене торговой информации
// только на одном "товаре".
window.trader_set_for_group = function(articul, slug, phil) {
  dom_group_to_tovar_data(articul, slug)
  trader_set_for_tovar(articul, phil, slug)
}
