;(function($, _) {

  "use strict"

  var instance

  var handlers = {
    dropdown_menu: {
      click: function(event) {
        event.stopPropagation()
        var instance = event.data.instance
        var dropdown_menu = $(event.delegateTarget)
        dropdown_menu.toggleClass("dropdowned")
      },
      click_body: function(event) {
        var instance = event.data.instance
        var dropdown_menus = $(instance.options.selectors.dropdown_menu)
        dropdown_menus.removeClass("dropdowned")
      }
    },
    accordion: {
      click: function(event) {
        var instance = event.data.instance
        var duration = instance.options.accordion.animation_duration
        var thisaccordion = $(this).parent()
        var headers = thisaccordion.find('div.header')
        var texts = thisaccordion.find('div.text')
        if ( $(this).hasClass("current") ) {
          // нажимаем на раскрытый блок блок
          $(this).removeClass('current').next().slideUp(duration)
        } else {
          // нажимаем на скрытый блок
          $(this)
            .addClass('current') // кликнутый
            .next().slideDown(duration) // разворачиваем нужный блок
            .end() // кликнутый
            .siblings('.header') // остальные заголовки
            .removeClass('current') // убираем флаг
            .next().slideUp(duration) // сворачиваем блоки за остальными заголовками
        }
      }
    }
  }

  function SassFramework(options) {
    this.options = Object.assign(this.constructor.default_options, options || {})
  }

  SassFramework.prototype.activate = function() {
    this.activate_dropdown_menu()
    this.activate_accordion()
    this.activate_tabs()
    this.activate_message()
    return this
  }

  SassFramework.prototype.activate_dropdown_menu = function() {
    var dropdown_menus = $(this.options.selectors.dropdown_menu)
    dropdown_menus.on("click", {instance: this}, handlers.dropdown_menu.click)
    $("body").on("click", {instance: this}, handlers.dropdown_menu.click_body)
  }

  SassFramework.prototype.activate_accordion = function() {
    var accordions = $(this.options.selectors.accordion)
    accordions.on("click", this.options.selectors.accordion_header, {instance: this}, handlers.accordion.click)
    accordions.find(this.options.selectors.accordion_header).not(".current").next().slideUp(200)
  }

  SassFramework.prototype.activate_tabs = function() {
    var tabs = $(this.options.selectors.tabs).tabs()
  }

  SassFramework.prototype.activate_message = function() {
    $("body").on("click", this.options.selectors.message_closing, {instance: this}, function(event) {
      $(event.target).remove()
    })
  }

  SassFramework.default_options = {
    selectors: {
      dropdown_menu: ".dropdown_menu:not(.hoverable)",
      accordion: ".accordion",
      tabs: ".zn.tabs",
      accordion_header: ">.header",
      message_closing: ".message.closing",
    },
    accordion: {
      animation_duration: 400
    }
  }

  SassFramework.activate = function(options) {
    return this.get_instance(options).activate()
  }
  SassFramework.get_instance = function(options) {
    if (instance == null) {
      instance = new this(options)
    }
    return instance
  }

  window.SassFramework = SassFramework

})(jQuery, _);
