"use strict"

import { Keyboard } from "../class/keyboard"

$(document).on("turbolinks:load", function() {
  activate_content_plans_edit()
  activate_content_plans_select()
  activate_content_plans_column_input()
  activate_content_plans_column_area()
  activate_content_plans_remove_hidden_attachment()
})

function activate_content_plans_edit() {
  $(".content_plans").on("click", ".content_plans-edit", function(event) {
    // Если текущий клик был по ссылке, тогда переходим по ссылке не реагируя.
    if (event.target.matches("a")) {
      return null
    }
    var edit = $(this)
    var column = edit.parents("td")
    var column_show_view = column.find(".content_plans-column_show_view")
    var column_edit_view = column.find(".content_plans-column_edit_view")
    var column_input = column.find(".content_plans-column_input")
    var column_select = column.find(".content_plans-column_select")
    var column_area = column.find(".content_plans-column_area")
    column_show_view.hide()
    column_edit_view.show()
    switch(true) {
      case column_input.length == 1:
        column_input.focus()
      break
      case column_select.length == 1:
        column_select.focus()
      break
      case column_area.length == 1:
        column_area.focus()
      break
    }
  })
}

function activate_content_plans_column_input() {
  $(".content_plans").on("keydown", ".content_plans-column_input", function(event) {
    if (event.keyCode == 27) {
      var input = $(this)
      var column_edit_view = input.parents(".content_plans-column_edit_view")
      var column_show_view = input.parents("td").find(".content_plans-column_show_view")
      column_show_view.show()
      column_edit_view.hide()
    }
    if (event.keyCode == 13) {
      var input = $(this)
      var form = input.parents("form")
      $.rails.fire(form[0], "submit")
    }
  })
  $(".content_plans").on("focusout", ".content_plans-column_input", function(event) {
    var input = $(this)
    var column_edit_view = input.parents(".content_plans-column_edit_view")
    var column_show_view = input.parents("td").find(".content_plans-column_show_view")
    column_show_view.show()
    column_edit_view.hide()
  })
  $(".content_plans").on("change", '.content_plans-column_input[type=checkbox]', function(event) {
    var input = $(this)
    var form = input.parents("form")
    $.rails.fire(form[0], "submit")
  })
}

function activate_content_plans_column_area() {
  $(".content_plans").on("keydown", ".content_plans-column_area", function(event) {
    // Esc
    if (event.keyCode == 27) {
      var input = $(this)
      var column_edit_view = input.parents(".content_plans-column_edit_view")
      var column_show_view = input.parents("td").find(".content_plans-column_show_view")
      column_show_view.show()
      column_edit_view.hide()
    }
  })
  $("body").on("keydown", function(event) {
    // Ctrl + Enter
    if (event.target.classList.contains("content_plans-column_area") && Keyboard.shortcut_pressed(17, 13)) {
      var area = $(event.target)
      var form = area.parents("form")
      $.rails.fire(form[0], "submit")
    }
  })
  $(".content_plans").on("focusout", ".content_plans-column_area", function(event) {
    var area = $(this)
    var column_edit_view = area.parents(".content_plans-column_edit_view")
    var column_show_view = area.parents("td").find(".content_plans-column_show_view")
    column_show_view.show()
    column_edit_view.hide()
  })
}


function activate_content_plans_select() {
  $(".content_plans").on("change", ".content_plans-column_select", function(event) {
    var select = $(this)
    var form = select.parents("form")
    // var form = select.parents("form").submit()
    // var form = select.parents("form").trigger('submit.rails')
    $.rails.fire(form[0], "submit")
  })
  $(".content_plans").on("focusout", ".content_plans-column_select", function(event) {
    var select = $(this)
    var column_edit_view = select.parents(".content_plans-column_edit_view")
    var column_show_view = select.parents("td").find(".content_plans-column_show_view")
    column_show_view.show()
    column_edit_view.hide()
  })
}

function activate_content_plans_remove_hidden_attachment() {
  $(".content_plans").on("change", ".content_plans-disable_hidden_attachment", function(event) {
    var is_checked = $(this).prop("checked")
    var value_of_hidden_attachment = $(this).data("value")
    var hidden_attachment = $(`.content_plans input[type=hidden][value="${value_of_hidden_attachment}"]`)
    hidden_attachment.prop("disabled", is_checked)
  })
}
