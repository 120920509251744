;(function($) {


"use strict"

window.vue.application.app.component.TovarBuy = {

  props: {
    tovar_articul: {
      type: String,
      required: true,
    },
    tovar_step: {
      type: Number,
      default: 1
    },
  },

  data: function() {
    return {
      articul: this.tovar_articul,
      count: this.tovar_step,
      step: this.tovar_step,
      disabled: false,
      error: false,
    }
  },

  template: `
    <div class="tovar-buy" :class="{disabled: disabled, error: error}">
      <div class="corrector">
        <div class="m" @click="handler_m"></div>
        <input type="text" name="count" v-model="count">
        <div class="p" @click="handler_p"></div>
      </div>
      <button type="button" @click="handler_add" :disabled="disabled">Купить</button>
    </div>
  `,

  computed: {
  },

  methods: {
    handler_p: function() {
      this.count = parseFloat((this.count + this.step).toFixed(2))
    },
    handler_m: function() {
      this.count = parseFloat((this.count - this.step).toFixed(2))
      if (this.count < 0)
        this.count = 0
    },
    handler_add: function() {
      this.disabled = true
      $.ajax({
        url: this.$root.cart.options.api_controller_name + '/add',
        type: 'post',
        data: {
          articul: this.articul,
          count: this.count,
        },
        data_custom: {
          instance: this
        },
        cache: false,
        success: function(data, textStatus, jqXHR) {
          var instance = this.data_custom.instance
          instance.disabled = false
          instance.error = false
          instance.$root.cart.list = data.list || []
        },
        error: function(jqXHR, textStatus, errorThrown) {
          var instance = this.data_custom.instance
          instance.disabled = false
          instance.error = true
        }
      })
    },
  },
}


})(jQuery);
