;(function() {


"use strict"

window.Vue.component("UserInfo", {

  mixins: [window.vue.application.app.mixin.PersonInfoMixin],

  data: function() {
    return {
      account: window.vue.application.app.data.account,
      fields: {
        email: {
          label: "E-mail",
          value: null,
          errors: []
        },
        password: {
          is_editable: false,
          label: "Сменить пароль",
          value: null,
          errors: []
        },
        name: {
          is_editable: false,
          label: "Имя",
          value: null,
          errors: []
        },
        phone_number: {
          is_editable: false,
          label: "Телефон",
          value: null,
          errors: []
        },
        birthday: {
          is_editable: false,
          label: "Дата рождения",
          value: null,
          errors: []
        },
        sex: {
          label: "Пол",
          value: null,
          errors: []
        }
      },
      is_error: false,
    }
  },

  // Реагирует только на отслеживаемые свойства.
  computed: {
  },

  mounted: function() {
    this.$parent.$consider_ccd()
    this.$_ajax_get_user_info()
  },

  // Тут необходимо оперировать с нереактивными свойствами, иначе вечный ререндер.
  updated: function() {
    if (this.account.company_success_deleted_show_index != null) {
      this.account.company_success_deleted_show_index += 1
    }
  },

  methods: {

    // Метод вызывается при рендеринге (присутствует в шаблоне) и происходит учёт нереактивных свойств.
    is_show_company_success_deleted: function() {
      return this.account.company_success_deleted_show_index != null && this.account.company_success_deleted_show_index < 1
    },

    $_ajax_get_user_info: function() {
      axios.get(
        '/ajax_account/user_info'
      ).then(
        this.$_ajax_user_info_successed
      ).catch(
        this.$_ajax_user_info_rejected
      )
    },

    $_ajax_post_user_info: function(data) {
      axios.post(
        '/ajax_account/user_info',
        data
      ).then(
        this.$_ajax_user_info_successed
      ).catch(
        this.$_ajax_user_info_rejected
      )
    },

    $_ajax_user_info_successed: function(response) {
      // var info_keys = Object.keys(response.data)
      // for (var field_key in this.fields) {
      //   var field = this.fields[field_key]
      //   if (info_keys.includes(field_key)) {
      //     field.value = response.data[field_key]
      //   }
      // }

      // Перебираем поля формы, если есть такое поле в пришедших данных - забираем значение.
      var ajax_field_keys = Object.keys(response.data)
      for (var vue_field_key in this.fields) {
        if (ajax_field_keys.includes(vue_field_key)) {
          // this.is_ajax_user_info_edit_field_values = true
          this.fields[vue_field_key].value = response.data[vue_field_key]
          // this.$nextTick(()=>{
          //   this.is_ajax_user_info_edit_field_values = false
          // })
          // this.$set(field, 'value', response.data[field_key], false)
        }
      }
      // Обнулим ошибки у всех полей
      for (var key in this.fields) {
        var field = this.fields[key]
        field.errors.splice(0,field.errors.length)
      }
      // Перебираем пришедшие ошибки, если есть такое поле у формы - добавляем ошибки в поле.
      if (Object.prototype.toString.call(response.data.errors) === '[object Object]') {
        var form_fields = Object.keys(this.fields)
        for (var error_field_name in response.data.errors) {
          var messages = response.data.errors[error_field_name]
          if (form_fields.includes(error_field_name)) {
            this.fields[error_field_name].errors = messages.slice(0)
          }
        }
      }
    },

    $_ajax_user_info_rejected: function(error) {
      this.is_error = true
    },

    pencil_click_handler: function(field_name) {
      this.fields[field_name].cache = this.fields[field_name].value
      this.fields[field_name].is_editable = true
      this.$nextTick(function() {
        this.$refs["input-" + field_name].focus()
      }.bind(this))
    },

    enter_handler: function(field_name) {
      this.fields[field_name].is_editable = false
      if (this.fields[field_name].value == this.fields[field_name].cache) {
        return null
      }
      this.$_ajax_post_user_info({field_name: field_name, field: this.fields[field_name]})
    },

    blur_handler: function(field_name) {
      if (this.fields[field_name].is_editable == false) {
        return null
      }
      this.fields[field_name].is_editable = false
      if (this.fields[field_name].value == this.fields[field_name].cache) {
        return null
      }
      this.$_ajax_post_user_info({field_name: field_name, field: this.fields[field_name]})
    },

    escape_handler: function(field_name) {
      this.fields[field_name].is_editable = false
      this.fields[field_name].value = this.fields[field_name].cache
    },

    sex_change_handler: function(event) {
      this.$_ajax_post_user_info({field_name: 'sex', field: this.fields.sex})
    },

    apply_user_handler: function(event) {
      this.$_ajax_apply_user()
    },

    $_ajax_apply_user: function() {
      this.is_error = false
      $.ajax({
        type: 'post',
        url: '/ajax_account/apply_user',
      }).then(function(data, textStatus, jqXHR) {
        if (data.success == 'true') {
          this.account.applied_person_id = 'user'
        }
      }.bind(this)).fail(function(jqXHR, textStatus, errorThrown) {
        this.is_error = true
      }.bind(this))
    },
  },

  watch: {
  },

  template: `
    <div class="user-info">
      <div class="hugging blocks_row">
        <div class="right">
          <b v-if="'user' == account.applied_person_id">Реквизиты по умолчанию</b>
          <span
            v-else
            class="small primary button"
            @click="apply_user_handler"
            title="Назначить аккаунт физ. лица источником реквизитов для совершения покупок."
          >Назначить реквизиты по умолчанию</span>
        </div>
        <div>
      <h2>Профиль</h2>
        </div>
      </div>
      <div v-if="!is_error" class="form_z grid">

        <div v-show="is_show_company_success_deleted()">
          <p class="message iconed bordered closing success">Компания удалена.</p>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.email.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span class="as_input">{{ this.fields.email.value }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.password.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="!this.fields.password.is_editable" class="as_input">******</span>
              <input type="password"
                ref="input-password"
                v-model="fields.password.value"
                v-show="fields.password.is_editable"
                @blur="blur_handler('password')"
                @keyup.enter="enter_handler('password')"
                @keyup.esc="escape_handler('password')"
              >
              <div
                v-show="!this.fields.password.is_editable"
                @click="pencil_click_handler('password')"
                class="pencil"
              ></div>
              <field-errors :errors="fields.password.errors"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.name.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('name')" class="as_input">{{ this.fields.name.value }}</span>
              <input type="text"
                ref="input-name"
                v-model="fields.name.value"
                v-show="$_is_show_input('name')"
                @blur="blur_handler('name')"
                @keyup.enter="enter_handler('name')"
                @keyup.esc="escape_handler('name')"
                @focus="pencil_click_handler('name')"
              >
              <div
                v-show="$_is_show_pencil('name')"
                @click="pencil_click_handler('name')"
                class="pencil"
              ></div>
              <field-errors :errors="fields.name.errors"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.birthday.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <span v-show="$_is_show_span_as_input('birthday')" class="as_input">{{ this.fields.birthday.value }}</span>
              <input type="text"
                ref="input-birthday"
                v-model="fields.birthday.value"
                v-show="$_is_show_input('birthday')"
                @blur="blur_handler('birthday')"
                @keyup.enter="enter_handler('birthday')"
                @keyup.esc="escape_handler('birthday')"
                @focus="pencil_click_handler('birthday')"
                placeholder="2001-01-01"
              >
              <div
                v-show="$_is_show_pencil('birthday')"
                @click="pencil_click_handler('birthday')"
                class="pencil"
              ></div>
              <field-errors :errors="fields.birthday.errors"></field-errors>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <div class="wrap shred padded">
              <label>{{ this.fields.sex.label }}:</label>
            </div>
          </div>
          <div class="col-9">
            <div class="wrap shred padded">
              <label>
                Мужской
                <input type="radio" name="sex" value="мужской"
                  v-model="fields.sex.value"
                  @change="sex_change_handler($event)"
                >
              </label>
              <label>
                Женский
                <input type="radio" name="sex"value="женский"
                  v-model="fields.sex.value"
                  @change="sex_change_handler($event)"
                >
              </label>
              <field-errors :errors="fields.sex.errors"></field-errors>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Извините, сбой приложения, повторите попытку позже.</p>
      </div>
    </div>
  `,
})


})();
