;(function(){
  "use strict"

  ///////////////////
  // FilterFactory //
  ///////////////////
  window.Market.FilterFactory = function() {
  }
  // window.Market.FilterFactory.register = function(constructor_function) {
  //   if (!(constructor_function instanceof Function)) {
  //     throw new window.Error('constructor_function is not function.')
  //   }
  //   console.log('constructor_function.name: ', constructor_function.name)
  //   this.constructors = _.extend(this.constructors || {})
  //   this.constructors[constructor_function.name] = constructor_function
  //   debugger
  // }
  window.Market.FilterFactory.new = function(type, place, pickers_values) {
    var constructor_class_name = 'Filter' + type.charAt(0).toUpperCase() + type.slice(1)
    // var constructor_function = this.constructors[constructor_class_name]
    // if (!(constructor_function instanceof Function)) {
    //   throw new window.Error('Конструктор "' + constructor_class_name + '" не найден.')
    // }
    if (type == "checkbox") {
      return new window.Market.FilterCheckbox(place, pickers_values)
    } else if (type == "interval") {
      return new window.Market.FilterInterval(place, pickers_values)
    }
    // return new constructor_function(place, pickers_values)
  }

  ////////////
  // Filter //
  ////////////
  window.Market.Filter = function(place, pickers_values) {
    this.place = place
    this.pickers_values = pickers_values
    this.options = _.extend(window.Market.Filter.options, this.constructor.options||{})

    this.get_name = function() {
      return this.place.attr(this.options.attr_name_filter_name)
    }

    this.remove = function() {
      var place_pickers = this.place.parents(this.options.place_pickers_selector)
      var place_header = place_pickers.prev(this.options.place_header_selector)
      this.place.remove()
      place_pickers.remove()
      place_header.remove()
    }

    // Должна добавлять переданные значения к выбранным и применять к интерфейсу
    this.checkize_values = function(values) {
      console.log('> not implemented, now: Filter.prototype.checkize_values')
    }

    // Должна применять только переданные значения, остальные убирать
    this.apply_values = function(values) {
      console.log('> not implemented, now: Filter.prototype.apply_values')
    }

    // Должна все значения фильтра делать невыбранными
    this.reset_values = function(values) {
      console.log('> not implemented, now: Filter.prototype.reset_values')
    }

    // Должна давать кликабельный доступ ко всем значениям фильтра
    this.anable_all_pickers = function() {
      console.log('> not implemented, now: Filter.prototype.anable_all_pickers')
    }

    // Убирает кликабельный доступ к определённому значению фильтра
    this.disable_picker = function(picker_value) {
      console.log('> not implemented, now: Filter.prototype.disable_picker')
    }

    this.activate = function() {
      console.log('> not implemented, now: Filter.prototype.activate_filter')
    }

  }
  window.Market.Filter.options = {
    place_pickers_selector: '.box_a',
    place_header_selector: '.txt_a'
  }


  ////////////////////
  // FilterCheckbox //
  ////////////////////
  window.Market.FilterCheckbox = function FilterCheckbox(place, pickers_values) {
    window.Market.Filter.apply(this, arguments)
    this.type = 'checkbox'

    this.checkize_values = function(values) {
      this.place.parent().parent().slideDown()
      var instance = this
      this.place.find(this.options.item_selector).each(function(index, dom) {
        var picker = $(dom)
        var input = picker.find(instance.options.input_selector).first()
        if (!input.length) {
          return null
        }
        var val = input.val()
        if (values.includes(val)) {
          picker.children('div.checkbox').addClass('check_act')
          input.prop('checked', true)
        }
        // input.prop('disabled', false)
        // picker.removeClass('disabled')
      })
    }

    this.apply_values = function(values) {
      var instance = this
      this.place.find(this.options.item_selector).each(function(index, dom) {
        var picker = $(dom); if (picker.length != 1) { return null }
        var input = picker.find(instance.options.input_selector); if (input.length != 1) { return null }
        var val = input.val()
        if (values.includes(val)) {
          picker.children('div.checkbox').addClass('check_act')
          input.prop('checked', true)
        } else {
          picker.children('div.checkbox').removeClass('check_act')
          input.prop('checked', false)
        }
      })
    }

    this.reset_values = function() {
      this.apply_values([])
    }

    this.anable_all_pickers = function() {
      var instance = this
      this.place.find(this.options.item_selector).each(function(i,e) {
        var picker = $(e)
        var input = picker.find(instance.options.input_selector).first()
        if (!input.length) {
          return null
        }
        input.prop('disabled', false)
        picker.removeClass('disabled')
      })
    }

    this.disable_picker = function(picker_value) {
      var instance = this
      this.place.find(this.options.item_selector).each(function(i,e) {
        var picker = $(e)
        var input = picker.find(instance.options.input_selector).first()
        if (!input.length) {
          return null
        }
        var value = input.val()
        if (picker_value===value) {
          input.prop('disabled', true)
          picker.addClass('disabled')
        }
      })
    }
  }
  window.Market.FilterCheckbox.options = {
    variants: ['filter_checkbox','filter_checkbox_colored'], //
    event_changing: 'change',                                //
    filter_selector: '.blocks[filter_name]',                 //
    item_selector: '.filter_checkbox',                       // Пиккеры фильтра
    click_selector: 'label[data-filter_type="checkbox"]',    // label пиккера
    input_selector: 'input.filter_checkbox-input',           // input пиккера
    attr_name_filter_name: 'filter_name'
  }
  window.Market.FilterCheckbox.prototype = Object.create(window.Market.Filter.prototype)
  window.Market.FilterCheckbox.prototype.constructor = window.Market.FilterCheckbox
  // window.Market.FilterFactory.register(window.Market.FilterCheckbox)

  ////////////////////
  // FilterInterval //
  ////////////////////
  window.Market.FilterInterval = function FilterInterval(place, pickers_values) {
    window.Market.Filter.apply(this, arguments)
    this.type = 'interval'
    this.disabled_pickers_values = []
    this.$slider = null

    this.get_slider = function() {
      return this.place.parent().siblings(this.options.ui_wrapper_selector).find(this.options.slider_selector).first()
    }

    this.get_from = function() {
      return this.place.parent().siblings(this.options.ui_wrapper_selector).find(this.options.from_selector).first()
    }

    this.get_to = function() {
      return this.place.parent().siblings(this.options.ui_wrapper_selector).find(this.options.to_selector).first()
    }

    this.activate = function() {
      this.activate_slider()
      this.activate_fromto()
    }

    this.activate_slider = function() {
      var filter_values = this.pickers_values
      var slider = this.get_slider()
      var instance = this
      var enabled_filter_values = _.difference(filter_values, this.disabled_pickers_values)
      var from = (filter_values.indexOf(enabled_filter_values[0]) > 0)? filter_values.indexOf(enabled_filter_values[0]) : 0
      var to = (filter_values.lastIndexOf(enabled_filter_values[enabled_filter_values.length-1]) > 0)? filter_values.lastIndexOf(enabled_filter_values[enabled_filter_values.length-1]) : filter_values.length-1
      slider.slider({
        range: true,
        min: 0,
        max: filter_values.length-1,
        values: [from, to],
        slide: function(event, ui) {
          var slider = $(this)
          var from = slider.siblings('.int.from')
          var to = slider.siblings('.int.to')
          var from_value = filter_values[ui.values[0]]
          var to_value = filter_values[ui.values[1]]
          if (!from.length||!to.length) {
            return null
          }
          from.val(from_value)
          to.val(to_value)
          setTimeout(function(){
            var new_indexes = slider.slider('option', 'values')
            var new_values = [filter_values[new_indexes[0]], filter_values[new_indexes[1]]]
            if (
              from_value===new_values[0] &&
              to_value===new_values[1]
            ) {
              var filter_name = slider.parents('.boxs[filter_name]').attr('filter_name')
              if (!filter_name) {
                return null
              }
              instance.update_pick_data_with_calc_pick_items(filter_name, from_value, to_value)
            }
          }, 1000)
        }
      })
    }

    this.activate_fromto = function() {
      var from = this.get_from()
      var to = this.get_to()
      var keyup_handler = function(event) {
        var it = $(this)
        var slider = it.siblings('.slider-range')
        var filter_name = slider.parents('.boxs[filter_name]').attr('filter_name')
        if (!filter_name) {
          return null
        }
        var filter_values = instance.filters_data[filter_name]
        if (!filter_values.length) {
          return null
        }
        if (it.hasClass('from')) {
          var it_is = 'from'
          var from = it
          var to = it.siblings('.to')
        } else if (it.hasClass('to')) {
          var it_is = 'to'
          var to = it
          var from = it.siblings('.from')
        } else {
          return null
        }
        var from_value = (from.val().length===0)? slider.data('min') : from.val()
        var to_value = (to.val().length===0)? slider.data('max') : to.val()
        var slider_from_position = 0
        var slider_to_position = filter_values.length - 1
        for(var slider_position=0, l=filter_values.length; slider_position<l; slider_position++) {
          if (
            Number(filter_values[slider_position].replace(',','.')) >=
            Number(String(from_value).replace(',','.'))
          ) {
            slider_from_position = slider_position
            break
          }
        }
        for(var slider_position=filter_values.length-1; slider_position>=0; slider_position--) {
          if (
            Number(filter_values[slider_position].replace(',','.')) <=
            Number(String(to_value).replace(',','.'))
          ) {
            slider_to_position = slider_position
            break
          }
        }
        slider.slider({
          values: [slider_from_position, slider_to_position]
        })
        var filter_from_value = filter_values[slider_from_position]
        var filter_to_value = filter_values[slider_to_position]
        var it_value = (it.val().length===0)? slider.data((it_is=='from')?'min':'max') : it.val()
        setTimeout(function(){
          if (it_value===it.val()) {
            update_pick_data_with_calc_pick_items(filter_name, filter_from_value, filter_to_value)
          }
        }, 1000)
      }
      if (from.lenght) {
        from.on('keyup', {instance:this}, keyup_handler)
      }
      if (to.lenght) {
        to.on('keyup', {instance:this}, keyup_handler)
      }
    }

    /**
     * Начекать на фильтре указанные значения (и только)
     * @param  {Array} values
     * @return {void}
     */
    this.checkize_values = function(values) {
      // Определяем from и to
      var from_value = null
      var from_value_index = null
      for (var i=0, l=this.pickers_values.length; i<l; i++) {
        var picker_value = this.pickers_values[i]
        if (values.includes(picker_value)) {
          from_value = picker_value
          from_value_index = i
          break
        }
      }
      var to_value = null
      var to_value_index = null
      for (var i = this.pickers_values.length - 1; i >= 0; i--) {
        var picker_value = this.pickers_values[i]
        if (values.includes(picker_value)) {
          to_value = picker_value
          to_value_index = i
          break
        }
      }
      if (from_value == null || to_value == null || from_value_index == null || to_value_index == null)
        return null
      // Устанавливаем from и to
      this.get_from().val(from_value)
      this.get_to().val(to_value)
      this.get_slider().slider({
        values: [from_value_index, to_value_index]
      })
    }

    /**
     * Устанавливает ползунки на переданные индексы
     * @param {Number} from_index индекс левого ползунка
     * @param {Number} to_index   индекс правого ползунка
     */
    this.set_handles_position = function(from_index, to_index) {
      this.$slider.slider({values: [from_index, to_index]})
    }

    this.refresh_ui = function() {
      this.refresh_slider_position()
      this.refresh_fromto()
    }

    this.refresh_slider_position = function() {
      var enabled_filter_values = _.difference(this.pickers_values, this.disabled_pickers_values)
      var min = (this.pickers_values.indexOf(enabled_filter_values[0]) > 0)?
        this.pickers_values.indexOf(enabled_filter_values[0]) : 0
      var max = (this.pickers_values.lastIndexOf(enabled_filter_values[enabled_filter_values.length-1]) > 0)?
        this.pickers_values.lastIndexOf(enabled_filter_values[enabled_filter_values.length-1]) : this.pickers_values.length-1
      var slider = this.get_slider()
      slider.slider({
        values: [min, max]
      })
    }

    this.refresh_fromto = function() {
      var from = this.get_from()
      var to = this.get_to()
      console.log('from: ', from)
    }

    this.update_pick_data_with_calc_pick_items = function(filter_name, from_value, to_value) {
      var fc = window.Market.FilterCollection.get_instance()
      var tc = window.Market.TovarCollection.get_instance()
      // Отчищаем pick_data от текущего фильтра
      var new_pick_data = fc.pick_data.filter(function(pick_item, index, array){
        return (pick_item.substring(0,filter_name.length)===filter_name)? false : true
      })
      fc.pick_data = new_pick_data.clone()
      var add = true // А нужно ли добавлять фильтр?:
      // Если значение выставлены по краям то не нужно, ага
      if (
        fc.filters_data[filter_name][0] == from_value &&
        fc.filters_data[filter_name][fc.filters_data[filter_name].length-1] == to_value
      ) {
        add = false
      }
      // Добавляем подходящие значение в pick_data
      if (add) {
        var push = false
        for(var i=0, l=fc.filters_data[filter_name].length; i<l; i++) {
          var value_string = fc.filters_data[filter_name][i]
          if (value_string===from_value) { push = true }
          if (push) {
            var pick_item = filter_name + '-=delimiter=-' + value_string
            fc.pick_data.push(pick_item)
          }
          if (value_string===to_value) { push = false }
        }
      }
      // pick_data подготовлен, применяем фильтры к TovarCollection
      tc.apply_filter()
      fc.redisable_pickers()
    }

    this.disable_picker = function(picker_value) {
      this.disabled_pickers_values.push(picker_value)
      this.disabled_pickers_values = _.uniq(this.disabled_pickers_values)
      this.refresh_ui()
    }

    this.anable_all_pickers = function() {
      this.disabled_pickers_values = []
      this.refresh_ui()
    }

    this.reset_values = function() {
      this.reset_from()
      this.reset_to()
      this.reset_slider()
    }

    this.reset_from = function() {
      this.get_from().val('')
    }

    this.reset_to = function() {
      this.get_to().val('')
    }

    this.reset_slider = function() {
      this.get_slider().slider({
        values: [0, this.pickers_values.length - 1]
      })
    }

  }
  window.Market.FilterInterval.options = {
    variants: ['filter_interval'],
    slider_selector: '.slider-range',
    attr_name_filter_name: 'filter_name',
    ui_wrapper_selector: '.boxs[filter_name]',
    from_selector: 'input.from',
    to_selector: 'input.to'
  }
  window.Market.FilterInterval.prototype = Object.create(window.Market.Filter.prototype)
  window.Market.FilterInterval.prototype.constructor = window.Market.FilterInterval
  // window.Market.FilterFactory.register(window.Market.FilterInterval)

})();
