
window.main_dom = function() {
}

window.dom_group_blocks_to_tovar_blocks = function() {
  var groups, group, articul, slug

  groups = $('.products div[data-tovar_type="group"]')
  groups.each(function(i, g) {
    group = $(g)
    articul = (group.find('.art').text().split(/\n|\s+/).filter(function(e){return e != ''})[1] || '').trim()
    slug = group.attr('slug_container')
    if (!slug || !articul) {
      return null
    }

    dom_group_block_to_tovar_block(articul, slug)
  })
}

window.dom_group_block_to_tovar_block = function(articul, slug) {
  $("a[slug_href='"+ slug +"']").attr('href', "/cat/" + articul)
  $("div[slug_articul='"+ slug +"']").html("артикул "+ articul)
  $('div[slug_container="'+ slug +'"]').find('span[slug_price_articul]').attr('slug_price_articul', articul)
  $('div[slug_container="'+ slug +'"]').find('span[slug_amount_articul]').attr('slug_amount_articul', articul)

  // set trader_articul attr for tovar/group block catch by articul
  $('div[slug_container="'+ slug +'"]').attr('trader_articul', articul)
}

// OLD dom
window.dom_group_to_tovar_data = function(articul, slug) {
  $("a[slug_href='"+ slug +"']").attr('href', "/cat/" + articul)
  $("div[slug_articul='"+ slug +"']").html("артикул "+ articul)
  $('div[slug_container="'+ slug +'"]').find('span[slug_price_articul]').attr('slug_price_articul', articul)
  $('div[slug_container="'+ slug +'"]').find('span[slug_amount_articul]').attr('slug_amount_articul', articul)
}
