window.main_setting = function() {
  window.setting()
  window.activate_main_slider()
  window.activate_slider2()
  window.activate_slider2_1()
}

window.setting = function() {
	var time_id = null
	$(".subcat > ul").mouseenter(function(){
		if (time_id != null) {
      	clearTimeout(time_id);
      	time_id = null;
    }
		time_id = setTimeout(function(){
			$(".subcat").css({ zIndex: 15 });
			$(".subcat_overlay").fadeIn();
		},200)
	});

	$(".subcat > ul").mouseleave(function(){
		if (time_id != null) {
      	clearTimeout(time_id);
      	time_id = null;
    }
    time_id = setTimeout(function(){
			$(".subcat").css({ zIndex: 1 });
			$(".subcat_overlay").fadeOut();
		},400)
	});
	// Select current menu in nav
	// curr_page placed in _catalog_button partial

	//City modal
	$("#contacts .city_menu .city_link").click(function(){
		$(this).find('.cities').show()
	})

	$('.city_link .cities .close_modal').click(function(){
		$(this).parent().parent().find('.cities').fadeOut()
	})

	$('body').on('click', function(e) {
		if (!$(e.target).closest(".city_link").length) {
			$('.cities').fadeOut()
		}
		// e.stopPropagation()
	});
	//Modal
	$(".modals").click(function() {
		var modal_id = $(this).data("body")
		$("#modal-" + modal_id).arcticmodal({
			overlay: {
				css: { opacity: .3 }
			},
			beforeOpen: function(data, el) {
				if (modal_id == "3") {
				  var modal = $(".subscription_modal")
				  var form = $("#subscription_modal_form")
				  var error = $(".js_error_message")
				  var success = $(".js_success_message")
					var user_email_input = $("#market_model_user_email")
					var subscription_modal_email = $("#subscription_modal_email")
					subscription_modal_email.val(user_email_input.val())
		      success.hide()
		      error.hide()
		      form.show()
				}
				$(".error_report").animate({ right: -100 }, 400)
			},
			beforeClose: function(data, el) {
				$(".error_report").animate({ right: 0 }, 400);
			}
		});
		//Back chromium
		$(".arcticmodal-overlay").text(".");
	});

	//Choose city in header
	$('.your_town .btn_yes').click(function(){
		var choose_town =$('.your_town')
		choose_town.hide()
	})

	// Активация вызова/скрытия модального окна выбора филиала.
	$("body").on("click", ".js_modal_affiliates", function(event) {
		$(".overlay").fadeIn()
		$("#modal_affiliates").fadeIn()
	});
	$(".overlay, #modal_affiliates .close").click(function() {
		$("#modal_affiliates").fadeOut()
		$(".overlay").fadeOut()
	})


	// //Subcat hover
	// $(".subcat .dropdown").hover(function(){
	// 	hover1 ($(this).index(), $(this).find(".box"));
	// }, function(){
	// 	hover2 ($(this).index(), $(this).find(".box"));
	// });
	
	//Placeholder
	$("input, textarea").placeholder();

	//Search bar
	$(".search_b .open").click(function(){
		$(this).parent().parent().parent().addClass("search_act");
	});
	$(".search_b #searcher_test .close").click(function(){
		$(this).parent().parent().removeClass("search_act");
	});

	//Clear int
	$(".ints_b .clear").click(function(){
		$(this).parent().find(".ints").val("");
	});

	//Select
	if($(".select").length){
		$(".select select").each(function (i) {
			var SelectText = $(this).find("option:selected").text();
			$(this).parent().find(".int").val(SelectText);
		});
		// $(".select .int").show();
		// $(".select select").css({ opacity: 0 });
		$(".select select").change(function(){
			var SelectText = $(this).find("option:selected").text();
			$(this).parent().find(".int").val(SelectText);
		});
	}

	//Checkbox
	if($(".checkbox").length){
		$(".checkbox input").css({ opacity: 0 });
		$(".checkbox input").change(function(){
			if($(this).prop("checked") == true) {
				$(this).parent(".checkbox").addClass("check_act");
			} else {
				$(this).parent(".checkbox").removeClass("check_act");
			}
		});
		$('.checkbox input[type="checkbox"]:checked').parent(".checkbox").addClass("check_act");
	}

	//Radio
	if($(".radio").length){
		$(".radio input").css({ opacity: 0 });
		$(".radio input").change(function(){
			name = $(this).attr("name");
			$('input[name="' + name + '"]').parent(".radio").removeClass("radio_act");
			$(this).parent(".radio").addClass("radio_act");
		});
		$('.radio input[type="radio"]:checked').parent(".radio").addClass("radio_act");
	}

	//Phil hide
	function philHide(){
		$(".cont_page .phil_b .box").hide();
	}
	//Phil show
	function philShow(object){
		philHide();

    $("#phil_name_in_select").text($.cookie('phil'))
		var id = $(object).parent().parent().find(".left .phil .phil_box").index();
		var gg = $(".cont_page .phil_b .box").eq(id).show();
	}
	
	//Contact phil
	$(".cont_page .maps_block .maps .btn").click(function(){
		if(!$($(this)).hasClass("btn_disabled")){
			philShow($(this));
		}
	});
	$(".phil_b .close").click(function(){
		philHide();
	});
	
	//View
	$(".view_row").click(function(){
		$(".view_box").removeClass("active");
		$(".view_row").addClass("active");
		$(".products").addClass("products_r");
	});
	$(".view_box").click(function(){
		$(".view_row").removeClass("active");
		$(".view_box").addClass("active");
		$(".products").removeClass("products_r");
	});

	window.activate_main_slider = function() {
		var main_slider = $("#main_slider").first()
		if (!main_slider.length) {
			return null
		}
		window.main_slider = main_slider.zlader()
	}


	//Slider 2
	window.activate_slider2 = function() {
		if($(".slider2").length){
			$(".slider2").carouFredSel({
				scroll: { duration: 800, items: 1 },
				auto: { timeoutDuration: 15000 },
				pagination: ".js_pager2",
				prev: { button : ".prev2", key : "left" },
				next: { button : ".next2", key : "right" },
				items: { visible: 3 }
			});
		}
	}

	//Slider 2_1
	window.activate_slider2_1 = function() {
		if($(".slider2_1").length){
			$(".slider2_1").carouFredSel({
				scroll: { duration: 800, items: 1 },
				auto: { timeoutDuration: 60000 },
				pagination: ".js_pager2_1",
				prev: { button : ".prev2_1", key : "left" },
				next: { button : ".next2_1", key : "right" },
				items: { visible: 3 }
			});
		}
	}

	//Slider 3
	window.activate_slider3 = function() {
		$(".slider4 img").click(function() {
			var idImg = $(this).data("img")
			var id_main_photo = $(".slider3 .active").data('id_photo')
			if (idImg != id_main_photo) {
				$(".slider3 .active").removeClass("active");
				var slider3 = $(".slider3").find('[data-id_photo="'+idImg+'"]').addClass("active")
			}
		});
	}

	//Slider4
	window.activate_slider4 = function() {
		var slider4 = $(".slider4")
		if (slider4.length) {
			slider4.carouFredSel({
				direction: "up",
				auto: false,
				circular: false,
				items: {
					visible: 4
				},
				scroll: {
					duration: 800,
					items: 1
				},
				prev: { button : ".prev4", key : "left" },
				next: { button : ".next4", key : "right" }
			});
			slider4.on('click', ' > div', function(event) {
				var div = $(this)
				div.addClass('active').siblings().removeClass('active')
				// var number = div.index()
				// if ($(".slider3")) {
				// 	var childrens = $(".slider3").children('a')
				// 	var ch_num = childrens.eq(number)
				// 	ch_num.addClass('active').siblings().removeClass('active')
				// }
			})
		}
	}

	//Tabs
	$("#tab_b").tabs();
	$("#main_page_tabs").tabs();

	var link_click = $('#main_page_tabs ul a')
	link_click.click(function(){
		link_click.removeClass('active_tabs')
	  	$(this).addClass('active_tabs')
	})

	//Slider5
	if($(".slider5").length){
		$(".slider5").carouFredSel({
			scroll: { duration: 800, items: 1 },
			auto: { timeoutDuration: 60000 },
			pagination: ".pager5",
			prev: { button : ".prev5", key : "left" },
			next: { button : ".next5", key : "right" }
		});
	}

	//Slider pre cat
	window.activate_slider7 = function() {
		var slider7s = $(".slider7")
		if (slider7s.length) {
			slider7s.each(function(index, dom) {
				var slider7 = $(dom)
				if (slider7.length) {
					var slider_number = slider7.data('slider_number')
					console.log('slider_number: ', slider_number)
					slider7.carouFredSel({
						items : 5,
						scroll: { duration: 800, items: 1 },
						auto: false,
						prev: {button: '.prev7-' + slider_number},
						next: {button: '.next7-' + slider_number}
					});
				}
			})
		}
	}

	//Timer
	if($(".countdown").length){
		var newDate = new Date(2024, 1-1, 1);
		$(".countdown").countdown({
			until: newDate,
			compact: true,
			layout:		'{h10}{h1}:{m10}{m1}:{s10}{s1}'
		});
	}

	//
	if($('.radio input[name="delivery"]').length){
		$('.radio input[name="delivery"]').change(function(){
			$(".info_shipping .block").hide();
			$(".info_shipping .block").eq($(this).data("id") - 1).show();
		});
		$(".info_shipping .block").eq($('.radio input[name="delivery"]:checked').data("id") - 1).show();
	}
	
	//Datepicker
	$(".datepicker").datepicker($.datepicker.regional["fr"]);
	
	//Accordions
	if($(".accordions").length){
		$(".accordions").accordion({
			collapsible: true,
			heightStyle: "content"
		});
	}
	
	$(".toggle").click(function(e) {
		e.preventDefault();
	  
		var $this = $(this);
	  
		if ($this.next().hasClass("show")) {
			$this.next().removeClass("show");
			$this.next().slideUp(350);
			$this.removeClass("active");
		} else {
			$this.parent().parent().find("li .inner").removeClass("show");
			$this.parent().parent().find("li .inner").slideUp(350);
			$this.parent().parent().find(".toggle").removeClass("active");
			$this.next().toggleClass("show");
			$this.next().slideToggle(350);
			$this.addClass("active");
		}
	});

	//Ya map
	if($("#ya_map").length){
		ymaps.ready(function () {
			var myMap = new ymaps.Map('ya_map', {
				center: [55.851724, 77.668552],
				zoom: 3,
				controls: ['zoomControl']
			}, {
				searchControlProvider: 'yandex#search'
			}),
			objectManager = new ymaps.ObjectManager({
				// Чтобы метки начали кластеризоваться, выставляем опцию.
				clusterize: true,
				// ObjectManager принимает те же опции, что и кластеризатор.
				gridSize: 32
			});
			
			// Чтобы задать опции одиночным объектам и кластерам,
			// обратимся к дочерним коллекциям ObjectManager.
			objectManager.objects.options.set({
				// Необходимо указать данный тип макета.
				iconLayout: 'default#image',
				// Своё изображение иконки метки.
				iconImageHref: '/market/desktop/img/label.png',
				// Размеры метки.
				iconImageSize: [41, 57],
				// Смещение левого верхнего угла иконки относительно
				// её "ножки" (точки привязки).
				iconImageOffset: [-21, -37]
			});
			objectManager.clusters.options.set('preset', 'islands#orangeClusterIcons');
			myMap.geoObjects.add(objectManager);

			$.ajax({
				url: "/geo/all"
			}).done(function(data) {
				objectManager.add(data);
			});
		});
	}

	//Accordion history
	if($(".acc_his").length){
		$(".acc_his").accordion({
			collapsible: true,
			heightStyle: "content",
			active: false
		});
	}
}
