;(function() {

  "use strict"

  window.main_lazyload = function() {
    if (lazyload_allow() != false) {
      window.lazyload_init_eazy_all()
    }
  }

  window.lazyload_allow = function() {
    if (window.bowser != null && window.bowser.chrome == true) {
      if (window.bowser.version <= 51) {
        return false
      }
    }
    return true
  }

  window.lazyload_init_eazy_all = function() {
    window.lazyload_instance = new window.LazyLoad({
      elements_selector: '.lazyload'
    })
  }

  window.lazyload_trigger = function() {
    window.scrollTo(window.pageXOffset, window.pageYOffset + 1)
    window.scrollTo(window.pageXOffset, window.pageYOffset - 1)
  }

})();
