function InfinityScroll() {
}

InfinityScroll.activate = function(options={}) {
  $.extend(this.options, options)
  this.place = $(this.options.place_selector)
  if (this.place.length != 1) {return null}
  this.entries = this.place.find(this.options.entries_selector)
  if (this.entries.length != 1) {return null}
  this.pagination = this.place.find(this.options.pagination_selector)
  if (this.pagination.length != 1) {return null}
  $(window).on("scroll", this.scroll_handler.bind(this))
}

InfinityScroll.scroll_handler = function(event) {
  var html = document.documentElement
  var body = document.body
  var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
  if (window.pageYOffset >= height - window.innerHeight - this.options.bottom_padding) {
    var url = this.pagination.find(".next a").attr("href")
    if (this.loading_state == false && url != null) {
      this.loading_entries(url)
    }
  }
}

InfinityScroll.loading_entries = function(url) {
  var instance = this
  $.ajax({
    url: url,
    type: 'get',
    dataType: 'json',
    cache: false,
    beforeSend: function(jqXHR, settings) {
      instance.loading_state = true
    }
  }).fail(function(jqXHR, textStatus, errorThrown) {
    instance.loading_state = false
  }).done(function(data) {
    instance.loading_state = false
    instance.entries.append(data.entries)
    instance.pagination.html(data.pagination)
  })
}

// Флаг состояния загрузки записей (позволяет не выполнять запрос для загрузки
// записей, если предыдущий запрос ещё не был обработан)
InfinityScroll.loading_state = false

InfinityScroll.options = {
  place_selector: ".infinity_scroll",
  pagination_selector: ".infinity_scroll-pagination",
  entries_selector: ".infinity_scroll-entries",
  // Отступ от нижнего края страницы [px] до места в котором необходимо начать
  // загружать записи
  bottom_padding: 50
}

export default InfinityScroll
