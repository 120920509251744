//main
window.main_catalog_items = function() {
  // console.log('> main_catalog_items')
  catalog_items_globals()
  color_attrs_colorize()
  color_attrs_not_visible_current_move_to_the_last_visible_position()
  color_attrs_bind_pick()
  change_title_activate()
  second_attrs_bind_change()
  second_attrs_grizzle()
  color_attrs_grizzle()
  activate_filters_in_modal_of_group()
  countin_the_color() // подсчет количества цветов
  second_countin() // подсчет во втором фильтре
}

// items info
window.catalog_items_get_articuls = function(){
  var g_articuls, t_articuls

  if(page_catalog()){
    g_articuls = catalog_items_get_group_articuls()
    t_articuls = catalog_items_get_tovar_articuls()
    return g_articuls.concat(t_articuls)
  }
  if(page_tovar()){
    return [ $("#product").data('articul') ]
  }
}

window.catalog_items_get_tovar_articuls = function(){
  var articuls, t_selector

  articuls = []
  t_selector = $('.products div[tovar_id][data-tovar_type="tovar"]')
  t_selector.each(function(i, t_place) {
    articuls.push($(t_place).attr('data-tovar_articul') || '')
  })

  return articuls
}

window.catalog_items_get_group_articuls = function(){
  var articuls, g_selector

  articuls = []
  g_selector = $('.products div[data-tovar_type="group"]')
  g_selector.each(function(i, g_place) {
    articuls.push(($(g_place).find('.art').text().split(/\n|\s+/).filter(function(e) {return e != ''})[1] || ''))
  })

  return articuls
}

//helpers
window.articuls_of = function(hash, color){
  return (hash["Цвет уточнение"] || hash["Цвет чернил уточнение"] || hash["Цвет свечения уточнение"] || hash["Цвет"] || hash["Цвет чернил"] || hash["Цвет свечения"])[color]
}

//globals
window.catalog_items_globals = function(){
  window['json_slug'] = {}
  window['only_one'] = false
  window['color_current'] = ''
  window['pick_data'] = []
}

//color_attrs
window.color_attrs_colorize = function() {
  colors = window.Market.config.filter.colors
  urls = window.Market.config.filter.urls
  $("div[is_color='true']").each(function(){
    color_val = colors[$(this).attr('title')]
    color_url = urls[$(this).attr('title')]
    if (color_val != null) {
      $(this).css({'background-color': "#" + color_val})
    } else {
      $(this).css({'background-image': "url(" + color_url + ")"})
    }
  })
}

window.color_attrs_not_visible_current_move_to_the_last_visible_position = function() {
  $('.products .color_box .radio.radio_act').each(function(index, dom) {
    var radio = $(dom)
    var position = radio.index()
    if (position > 5) {
      var item5 = radio.siblings(':eq(4)')
      radio.detach()
      radio.insertAfter(item5)
    }
  })
}

window.countin_the_color = function(){
  var all_products = $('.products') // все продукты на странице
  var color_boxs = all_products.find('.color_box') //цвета продуктов
  color_boxs.each(function(i,d) {
    var color_box = $(d);
    var couting_color = color_box.find('.radio').length
    if (couting_color == 1) {
      var colors = color_box.children()
      colors.removeClass('radio_act')
      color_box.addClass('js_no_onclick')
    }
  })
}

window.second_countin = function() {  
  var all_products = $('.products') 
  var all_select = all_products.find('.select') 
  var input_select = all_products.find('input')
  $('.products select').each(function(i,d){ 
    var select = $(d)
    var options = select.children('option') 
    var option_count = options.length
    if (option_count == 1) { 
      select.addClass('js_select_change')
      select.attr('disabled',true)
      // if (input_select[0].scrollWidth <= input_select.width()) {
      //    input_select.css({'text-overflow': 'ellipsis'})
      //    проверка влез ли текст в инпут или нет 
      // }
    }
  })
}

window.second_attrs_grizzle = function() {
  var selects = $('.products select[slug]')
  selects.each(function(index, dom) {
    var select  = $(dom)
    var tovar_box = select.parents('.box[tovar_id]').first()
    var slug = tovar_box.attr('slug_container')
    if (!slug) {
      return null
    }
    var color_box = tovar_box.find('.color_box')
    var color_value = color_box.find('.radio_act').first().attr('title')
    if (!color_value) {
      return null
    }
    var group_menu = window.group_menu[slug]
    var second_name = window.second_attr[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var color_articuls = group_menu[color_name][color_value][0]

    var second_value_has_tovar_with_color = function(second_value) {
      var second_articuls = group_menu[second_name][second_value][0]
      var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
      return intersection.length > 0 ? true : false
    }

    select.children().each(function(index_o, dom_o) {
      var option = $(dom_o)
      var second_value = option.text()
      if (second_value_has_tovar_with_color(second_value)) {
        option.removeClass('disabled')
      } else {
        option.addClass('disabled')
      }
    })

  })
}

window.color_attrs_grizzle = function() {
  var color_boxs = $('.products .color_box')
  color_boxs.each(function(index, dom) {
    var color_box = $(dom)
    var tovar_box = color_box.parents('.box[tovar_id]').first()
    var slug = tovar_box.attr('slug_container')
    if (!slug) {
      return null
    }
    var select = tovar_box.find('select[slug]')
    if (!select) {
      return null
    }
    var second_value = select.siblings('input').val()
    if (!second_value) {
      return null
    }
    var group_menu = window.group_menu[slug]
    var second_name = window.second_attr[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var second_articuls = group_menu[second_name][second_value][0]

    var color_value_has_tovar_with_second = function(color_value) {
      var color_articuls = group_menu[color_name][color_value][0]
      var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
      return intersection.length > 0 ? true : false
    }

    color_box.children('.radio').each(function(index_c, dom_c) {
      var color = $(dom_c)
      var color_value = color.attr('title')
      if (color_value_has_tovar_with_second(color_value)) {
        color.removeClass('grizzle')
      } else {
        color.addClass('grizzle')
      }
    })

  })
}

window.color_attr_grizzle = function(slug) {
  if (!slug) {
    return null
  }
  var tovar_box = $('.products .box[slug_container="' + slug + '"]').first()
  if (!tovar_box) {
    return null
  }
  var color_box = tovar_box.find('.color_box')
  var select = tovar_box.find('select[slug]')
  if (!select) {
    return null
  }
  // var second_value = select.siblings('input').val()
  var second_value = select.val()
  if (!second_value) {
    return null
  }
  // debugger
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var second_articuls = group_menu[second_name][second_value][0]

  var color_value_has_tovar_with_second = function(color_value) {
    var color_articuls = group_menu[color_name][color_value][0]
    var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
    return intersection.length > 0 ? true : false
  }

  color_box.children('.radio').each(function(index_c, dom_c) {
    var color = $(dom_c)
    var color_value = color.attr('title')
    if (color_value_has_tovar_with_second(color_value)) {
      color.removeClass('grizzle')
    } else {
      color.addClass('grizzle')
    }
  })
}

window.second_attr_grizzle = function(slug) {
  if (!slug) {
    return null
  }
  var select = $('.products select[slug="' + slug + '"]')
  var tovar_box = select.parents('.box[tovar_id]').first()
  var color_box = tovar_box.find('.color_box')
  var color_value = color_box.find('.radio_act').first().attr('title')
  if (!color_value) {
    return null
  }
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var color_articuls = group_menu[color_name][color_value][0]

  var second_value_has_tovar_with_color = function(second_value) {
    var second_articuls = group_menu[second_name][second_value][0]
    var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
    return intersection.length > 0 ? true : false
  }

  select.children().each(function(index_o, dom_o) {
    var option = $(dom_o)
    var second_value = option.text()
    if (second_value_has_tovar_with_color(second_value)) {
      option.removeClass('disabled')
    } else {
      option.addClass('disabled')
    }
  })
}

window.color_attrs_click_handler = function(event) {
  var input = $(this)
  var wrapper = input.parent().parent()
  if (wrapper.hasClass("js_no_onclick")) {
    return null
  }

  $(event.target).parent().addClass('radio_act').siblings().removeClass('radio_act')
  window['color_current'] = $(this).parent().attr('title')
  var color_value = $(this).parent().attr('title')
  window['slug'] = $(this).attr('slug')
  json = window['group_menu'][slug]
  json_slug[slug] = json_slug[slug] || json
  if (second_attrs_is_present(slug)) {
    second_attrs_fill_select(slug, color_current, json_slug[slug])
    second_attrs_bind_change_when_double(slug)
    var select = $(".products select[slug='"+ slug +"']")
    var second_value = select.siblings('input').val()
    console.log('> запускаем событие изменения второго фильтра, при клике на первом, ааааааааааааааааааааааа.')
    select.val(second_value.trim()).trigger('change', [true])
  } else {
    articul = articuls_of(json_slug[slug], color_current)[0]
    if (articul.length > 1) {
      console.log('> В цвете два товара, почему бы и нет: ', articul)
    }
    articul = articul[0]
    trader_set_for_group(articul, slug, $.cookie('phil'))
    window.get_some_product_img(articul, slug)
  }
}

window.color_attrs_bind_pick = function() {
  $('body').on('click', '.products input[slug]', window.color_attrs_click_handler)
}

window.change_title_activate = function() {
  $('body').on('click', '.products .select.select2 select', window.title_change_handler) 
}

window.title_change_handler = function () {
 var second_filter_value = $(this).val()
 var title_place = $(this).attr('title',second_filter_value)

}
//second_attrs
window.second_attrs_bind_change = function() {
  var second_attrs = $('div[data-have_second_attribute="true"]')
  $(second_attrs).each(function(index, dom) {
    var slug = $(this).attr('box_for')
    var second_attr = $(dom)
    if (second_attr.parent().parent().find('div.color_box .radio').html() == undefined) {
      second_attrs_bind_change_when_solo(slug)
    } else {
      second_attrs_bind_change_when_double(slug, false)
    }
  })
}

window.second_attrs_is_present = function(slug) {
  var second_attr_name = $('div[box_for="' + slug + '"] > div.txt2').text().replace(/[\n\t\r\s]+/,'')
  if (second_attr_name == '') {
    return false
  }
  return true
}

window.second_attrs_fill_select = function(slug, color, json) {
  var select = $(".products select[slug='"+ slug +"']")
  var input = select.siblings('input')

  var color_value = color
  var second_value = input.val()

  function second_value_has_tovar_with_color(slug, color_value, second_value) {
    var group_menu = window.group_menu[slug]
    var second_name = window.second_attr[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var color_articuls = group_menu[color_name][color_value][0]
    var second_articuls = group_menu[second_name][second_value][0]
    var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
    return intersection.length > 0 ? true : false
  }

  select.children().each(function(index, dom) {
    var option = $(dom)
    var second_value = option.text()
    if (second_value_has_tovar_with_color(slug, color_value, second_value)) {
      option.removeClass('disabled')
    } else {
      option.addClass('disabled')
    }
  })

}

window.second_attrs_bind_change_when_solo = function(slug) {
  select = $(".products select[slug='"+ slug +"']")
  var handler = function() {
    json = window['group_menu'][slug]
    json_slug[slug] = json_slug[slug] || json
    width = $(this).val()
    width_with_bug = $(this).val() + ' '
    a = $("a[slug_href='"+ slug +"']")
    window['width_articuls'] = json_slug[slug][window.second_attr[slug]][width] || json_slug[slug][window.second_attr[slug]][width_with_bug]
    finded = width_articuls[0]
    if(finded.length > 1){
      str = ''
      for(i=0; i < finded.length; i++){ str += finded[i] + ";" }
    }
    articul = finded[0]
    href = "/cat/" + articul
    trader_set_for_group(articul, slug, $.cookie('phil'))
    window.get_some_product_img(articul, slug)
  }
  select.unbind('change', handler)
  select.change(handler)
}

window.second_attr_change_when_double_handler = function(event, is_color_click) {
  var select = $(this)
  var input = select.prev()
  var slug = select.attr('slug')
  var group_menu = window.group_menu[slug]
  var checked_input = $("input[slug='"+ slug +"']:checked")
  var color_value = checked_input.parent().attr('title')
  // var second_value = input.val()
  var second_value = event.target.value
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var color_articuls = group_menu[color_name][color_value][0]
  var second_articuls = group_menu[second_name][second_value][0]
  var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
  if (intersection.length > 1) {
    var str = intersection.join()
    console.log('> В цвете два товара, почему бы и нет: ', str)
  } else if (intersection.length == 0) {
    console.log('> intersection ПУСТ.')
    if (is_color_click) {
      intersection = color_articuls
      window.select_second_value_by_articul(slug, intersection[0])
    } else {
      intersection = second_articuls
      window.check_color_value_by_articul(slug, intersection[0])
    }
  }
  var articul = intersection[0]
  second_attr_grizzle(slug)
  color_attr_grizzle(slug)
  window.trader_set_for_group(articul, slug, $.cookie('phil'))
  window.get_some_product_img(articul, slug)
}

window.metric_to_squares = function(str){
  return str.replace(/, ([^\s]*)\s*$/, ' ($1)')
}

window.get_some_product_img = function(articul, slug) {
  $.ajax({
    type: 'get',
    url: '/tovar_img_some/' + articul,
    async: true,
    dataType: 'html',
    success: function(data, textStatus, jqXHR) {
      var group = $('.products div[tovar_id][slug_container="' + slug + '"]')
      var img_wrapper = group.find('.js_img_w')
      img_wrapper.empty().append(data)
    }
  })
}

window.check_color_value_by_articul = function(slug, articul) {
  var group_menu = window.group_menu[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var colors = group_menu[color_name]
  var color_current = null
  for (var color in colors) {
    var articuls = colors[color][0]||['']
    if (_.contains(articuls,articul)) {
      color_current = color
      break
    }
  }
  var group = $('.products div[tovar_id][slug_container="' + slug + '"]')
  var color_pickers = group.find('.color_box > .radio').each(function(index,dom) {
    var picker = $(dom)
    var color = picker.attr('title')
    if (color && color_current) {
      if (color == color_current) {
        picker.addClass('radio_act')
      } else {
        picker.removeClass('radio_act')
      }
    }
  })
  var current = group.find('.color_box > .radio.radio_act')
  var current_position = current.index()
  if (current_position > 5) {
    var item5 = current.siblings(':eq(4)')
    current.detach()
    current.insertAfter(item5)
  }
}

window.select_second_value_by_articul = function(slug, articul) {
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var second_values = group_menu[second_name]
  var second_value_current = null
  for (var second_value in second_values) {
    var articuls = second_values[second_value][0]||['']
    if (_.contains(articuls,articul)) {
      second_value_current = second_value
      break
    }
  }
  var group = $('.products div[tovar_id][slug_container="' + slug + '"]')
  var select = group.find('select[slug]')
  var options = select.children('option').each(function(index, dom) {
    var option = $(dom)
    var second_value = option.text()
    if (second_value && second_value_current) {
      if (second_value == second_value_current) {
        select.val(second_value)
        select.siblings('input').val(second_value)
      } else {
      }
    }
  })
}

window.second_attrs_bind_change_when_double = function(slug) {
  var select = $(".products select[slug='"+ slug +"']").first()
  console.log('> перебиндиваем второй фильтр, в переборе o.O')
  select.off('change', window.second_attr_change_when_double_handler)
  select.on('change', window.second_attr_change_when_double_handler)
}

window.numberWithSpaces = function(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return {
    rub: parts[0],
    pennie: parts[1]
  }
}

/**
 * 
 * Фильтры в модальном окне сгруппированного товара
 * 
 * активируем два фильтра ("color" и "second") в функции activate_filters_in_modal_of_group()
 * два обработчика соответственно (mog_color_click_handler, mog_second_change_handler)
 * логика обработчиков одинаковая
 * 
 */

window.activate_filters_in_modal_of_group = function() {
  $('body').on('click', '#modal-group input[slug]', window.mog_color_click_handler)
  /*
  $('body').on('change', '#modal-group select[slug]', window.mog_second_change_handler)
  */
  $('body').on('click', '#modal-group .radio3', window.mog_second_click_handler)
}

window.mog_color_click_handler = function(event) {
  var input = $(this) //отвечает за положение при клике
  var slug = input.attr('slug') //название товара
  var color = input.parent('.radio')
  var color_value = color.attr('title') //название цвета
  var modal = $('#modal-group')
  var second_value = modal.find('.js_second_filter .radio.radio_act').text()
  if (input.hasClass('radio_act')){
    color_value = input.attr('title')
  }
  modal.find('.color_change').text('Цвет: ' + color_value)
  // Указать выбранное значение
  color.addClass('radio_act').siblings().removeClass('radio_act')
  // Узнать пересечение
  var intersection = mog_intersection(slug, color_value, second_value)
  var articul = null
  if (intersection.length) {
    articul = intersection[0]
  } else {
    // Корректировка нулевого пересечения
    var group_menu = window.group_menu[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var second_name = window.second_attr[slug]
    articul = group_menu[color_name][color_value][0][0]
    second_value = window.group_menu[slug].tovars[articul][second_name]
    if (second_value != null) {
      mog_set_second_value(second_value)
    }
  }
  // Засерить от выбранного
  mog_grizzle(slug, color_value, second_value)
  // Вставить уже имеющуюся инфу
  mog_replace({articul: articul})
  mog_replace({name: window.group_menu[slug].tovars[articul].name})
  // Запросить инфу о выбранном
  mog_ajax(articul)
}

window.mog_second_change_handler = function(event) {
  var select = $(this)
  var input = select.siblings('input')
  var slug = select.attr('slug')
  var second_value = select.val()
  // А вот тут мерзкий баг фикс, select.val() при <option>value </option> вернёт "value"
  // (без пробела!), а у нас люди в админке херачат пробелы в конце значений - хлебом не корми.
  select.children('option').each(function(index, dom) {
    var option = $(dom)
    var second_value_current = option.text()
    if (second_value_current.trim() == second_value) {
      second_value = second_value_current
    }
  })
  // Конец мерзкий баг фикс.
  var modal = $('#modal-group')
  var color = modal.find('.radio.radio_act')
  var color_value = color.attr('title')

  // Указать выбранное значение
  input.val(second_value)
  // Узнать пересечение
  var intersection = mog_intersection(slug, color_value, second_value)
  var articul = null
  if (intersection.length) {
    articul = intersection[0]
  } else {
    // Корректировка нулевого пересечения
    var group_menu = window.group_menu[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var second_name = window.second_attr[slug]
    articul = group_menu[second_name][second_value][0][0]
    color_value = window.group_menu[slug].tovars[articul][color_name]
    mog_set_color_value(color_value)
  }
  // Засерить от выбранного
  mog_grizzle(slug, color_value, second_value)
  // Вставить уже имеющуюся инфу
  mog_replace({articul: articul})
  mog_replace({name: window.group_menu[slug].tovars[articul].name})
  // Запросить инфу о выбранном
  mog_ajax(articul)
}

window.mog_second_click_handler = function(event) {
  var button = $(this)
  var slug = button.parent().attr('slug')
  var second_value = button.text()
  var modal = $('#modal-group')
  var color = modal.find('.color_box .radio.radio_act')
  var color_value = color.attr('title')

  // Указать выбранное значение
  button.addClass('radio_act').siblings().removeClass('radio_act')
  // Узнать пересечение
  var intersection = mog_intersection(slug, color_value, second_value)
  var articul = null
  if (intersection.length) {
    articul = intersection[0]//Если артикул и 0 элемент массива равны, тогда все ок 
  } else {// иначе нужно искать пересечения с другими товарами 
    // Корректировка нулевого пересечения
    var group_menu = window.group_menu[slug]
    var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
    var second_name = window.second_attr[slug]
    articul = group_menu[second_name][second_value][0][0]
    color_value = window.group_menu[slug].tovars[articul][color_name]
    mog_set_color_value(color_value)
    modal.find('.color_change').text('Цвет: ' + color_value)
  }
  // Засерить от выбранного
  mog_grizzle(slug, color_value, second_value)
  // Вставить уже имеющуюся инфу
  mog_replace({articul: articul})
  mog_replace({name: window.group_menu[slug].tovars[articul].name})
  // Запросить инфу о выбранном
  mog_ajax(articul)
}

window.mog_intersection = function(slug, color_value, second_value) {
  if (second_value == null || second_value == '') {
    return []
  }
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var color_articuls = group_menu[color_name][color_value][0]
  var second_articuls = group_menu[second_name][second_value][0]
  var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
  return intersection
}

window.mog_grizzle = function(slug, color_value, second_value) {
  if (second_value != null) {
    mog_grizzle_colors(slug, color_value, second_value)
    mog_grizzle_seconds(slug, color_value, second_value)
  }
}

window.mog_grizzle_colors = function(slug, color_value, second_value) {
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var second_articuls = group_menu[second_name][second_value][0]

  var color_value_has_tovar_with_second = function(color_value) {
    var color_articuls = group_menu[color_name][color_value][0]
    var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
    return intersection.length > 0 ? true : false
  }

  $('#modal-group .color_box .radio').each(function(index_c, dom_c) {
    var color = $(dom_c)
    var color_value = color.attr('title')
    if (color_value_has_tovar_with_second(color_value)) {
      color.removeClass('grizzle')
    } else {
      color.addClass('grizzle')
    }
  })
}

window.mog_grizzle_seconds = function(slug, color_value, second_value) {
  var group_menu = window.group_menu[slug]
  var second_name = window.second_attr[slug]
  var color_name = group_menu['Цвет'] ? 'Цвет' : group_menu['Цвет чернил'] ? 'Цвет чернил' : group_menu['Цвет свечения'] ? 'Цвет свечения' : null
  var color_articuls = group_menu[color_name][color_value][0]

  var second_value_has_tovar_with_color = function(second_value) {
    var second_articuls = group_menu[second_name][second_value][0]
    var intersection = color_articuls.filter(function(a){return _.contains(second_articuls,a)})
    return intersection.length > 0 ? true : false
  }

  /*
  $('#modal-group select[slug] option').each(function(index_o, dom_o) {
    var option = $(dom_o)
    var second_value = option.text()
    if (second_value_has_tovar_with_color(second_value)) {
      option.removeClass('disabled')
    } else {
      option.addClass('disabled')
    }
  })
  */
  $('#modal-group .js_second_filter .radio').each(function(index, dom) {
    var button = $(dom)
    var second_value = button.text()
    if (second_value_has_tovar_with_color(second_value)) {
      button.removeClass('graize')
    } else {
      button.addClass('graize')
    }
  })
}

window.mog_set_second_value = function(second_value) {
  /*
  $('#modal-group select[slug]').siblings('input').val(second_value)
  $('#modal-group select[slug] option').each(function(index_o, dom_o) {
    var option = $(dom_o)
    var second_value_current = option.text()
    if (second_value == second_value_current) {
      option.prop('selected', true)
      option.attr('selected', 'selected')
    } else {
      option.prop('selected', false)
      option.removeAttr('selected')
    }
  })
  */
  $('#modal-group .js_second_filter .radio').each(function(index, dom) {
    var button = $(dom)
    var second_value_current = button.text()
    if (second_value_current == second_value) {
      button.addClass('radio_act')
    } else {
      button.removeClass('radio_act')
    }
  })
}

window.mog_set_color_value = function(color_value) {
  $('#modal-group .color_box .radio').each(function(index, dom) {
    var color = $(dom)
    var color_value_current = color.attr('title')
    if (color_value == color_value_current) {
      color.addClass('radio_act')
    } else {
      color.removeClass('radio_act')
    }
  })
}

window.mog_ajax = function(articul) {
  var phil = $.cookie('phil')
  $.ajax({
    type: 'post',
    url: '/trader/' + articul,
    data: {phil: phil},
    async: true,
    dataType: 'json',
    success: function(data, textStatus, jqXHR) {
      // data = [цена, количество, квантованное количество, метрика количества]
      if (data == null) {
        return null
      }
      // mog_replace({
      //   price: data[0],
      //   amount: data[1],
      //   quantity: data[2],
      //   amount_metric: data[3]
      // })
      var modal = $('#modal-group')
      if (modal.length!=1) {
        return null
      }
      // debugger
      var url = '/cat/' + articul
      modal.find(window.Market.TovarCollection.options.tovar.selectors.price).html(data[4])
      modal.find(window.Market.TovarCollection.options.tovar.selectors.amount).html(data[5])
      modal.find(window.Market.TovarCollection.options.tovar.selectors.buy).html(data[6])
      modal
        .find(window.Market.TovarCollection.options.modal_group.selectors.name)
        .html('<a href="' + url + '">' + data[7] + '</a>')
    }
  })
  $.ajax({
    type: 'get',
    url: '/tovar_img_some/' + articul,
    async: true,
    dataType: 'html',
    success: function(data, textStatus, jqXHR) {
      mog_replace({
        img_html: data,
      })
    }
  })
}

window.mog_replace = function(data) {
  var modal = $('#modal-group')
  if (data.img_html != null) {
    var img_w = modal.find('.js_img_w').first()
    if (img_w.length) {
      img_w.empty()
      img_w.html(data.img_html)
    }
  }
  if (data.price !== undefined) {
    var price = data.price || 0
    var modal_price_metric = data.amount_metric || ''
    var modal_price_rub = window.numberWithSpaces(price.toFixed(2)).rub
    var modal_price_pennie = window.numberWithSpaces(price.toFixed(2)).pennie
    var monete = '₽'
    modal.find('.price span.rub').text(modal_price_rub)
    modal.find('.price span.kop').text(modal_price_pennie)
    modal.find('.price span.symbol').text(' ' + monete + '/' + modal_price_metric)
  }
  // if (data.name !== undefined) {
  //   var name = data.name
  //   if (modal.find('.js_group_name a').length) {
  //     modal.find('.js_group_name a').first().text(name)
  //   } else {
  //     modal.find('.js_group_name').first().text(name)
  //   }
  // }
  if (data.amount !== undefined && data.amount_metric !== undefined) {
    var amount = data.amount || 0
    var amount_metric = data.amount_metric || ''
    modal.find('.text').first().text('наличие: ' + amount + ' ' + amount_metric)
  }
  // var turn = modal.find('.right .turn')
  // turn.removeClass('group')
  // Наличие в точках
  // var circles = modal.find('.right .turn > div')
  // if (data[2]!=null) {
  //   circles.each(function(index, dom) {
  //     var circle = $(dom)
  //     if (data[2] >= index + 1) {
  //       circle.addClass('disc')
  //     } else {
  //       circle.removeClass('disc')
  //     }
  //   })
  // } else {
  //   circles.each(function(index, dom) {
  //     var circle = $(dom)
  //     circle.removeClass('disc')
  //   })
  // }

  // Артикул
   if (data.articul != null) {
    var articul = data.articul
  //   Ссылка на товар
  //   var header = modal.find('.js_group_name')
  //   var header_text = header.text()
  //   var url = '/cat/' + articul
  //   var html = '<a href="' + url + '">' + header_text + '</a>'
  //   header.html(html)
  //   Артикул
    var articul_wrapper = modal.find('div.art')
    if (articul_wrapper.length) {
       articul_wrapper.text(articul)
    }
  }
}
