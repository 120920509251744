;(function() {

  "use strict"

  window.main_forum_posts = function() {
    forum_posts_initialize()
  }

  function forum_posts_initialize() {
    var latest_forum_posts_block = $('#forum_posts')
    if (latest_forum_posts_block.length !== 1) {
      return null
    }
    $.ajax({
      type: 'get',
      url: 'https://sign-forum.ru/api/v1/api.php?action=getLastPosts&limit=100',
      dataType: 'json',
      cache: false,
      async: true,
      error: function(jqXHR, textStatus, errorThrown) {
        console.log('- Ошибка получения последних сообщений форума.')
      },
      success: function(data, textStatus, jqXHR){
        var box = latest_forum_posts_block.children('.forum_posts-text')
        if (!box.length) {
          return null
        }
        box.empty()

        var unique = []
        nextVal: for (var i = 0; i < data.length; i++) {
          var all_elem = data[i]
          for (var j = 0; j < unique.length; j++) {
            if (unique[j]['topic_id'] == all_elem['topic_id']) continue nextVal;
          }
          unique.push(all_elem)
        }

        for(var i=0, l=unique.length; i<l; i++) {
          var post = unique[i]
          var time_result = unixtime_to_date_time(post['post_time'])
          /*
          {
            "post_id":"542268",
            "topic_id":"39875",
            "forum_id":"459",
            "poster_id":"35375",
            "poster_ip":"92.255.205.248",
            "post_time":"1511343751",
            "post_subject":"Re: Жалобы, пожелания и замечания к ЗЕНОН-ТЕХНИК",
            "post_text":"ДмитрийО, нет смысла с Зеноном обсуждать такие вещи. В отличие от бытовой электроники, платы для рекламной техники это редкий товар и продавец ставит такие условия продажи и гарантии которые сочтет нужным. Естественно эти условия максимально выгодные для него. Я бы на месте Зенона делал так же, а то бы и вообще без установки бы не продавал )))",
            "username":"Arslonga",
            "user_id":"35375",
            "user_rank":"0",
            "user_avatar":"35375.png",
            "user_avatar_type":"1",
            "user_avatar_width":"80",
            "user_avatar_height":"80",
            "user_posts":"565",
            "user_regdate":"1393942495",
            "user_lastvisit":"1511120906",
            "topic_title":"Жалобы, пожелания и замечания к ЗЕНОН-ТЕХНИК",
            "add_user_rank":{
              "title":"Завсегдатай",
              "img":"<img src=\"../../images/ranks/pips5.gif\" alt=\"Завсегдатай\" title=\"Завсегдатай\" />",
              "img_src":"../../images/ranks/pips5.gif"
            },
            "add_user_avatar":{
              "avatar":"<img class=\"avatar\" src=\"../../download/file.php?avatar=35375.png\" width=\"80\" height=\"80\" alt=\"Arslonga\" />"
            },
            "topic_link":"http://sign-forum.ru/viewtopic.php?p=542268#p542268"
          }
          */
          // console.log('time_result: ', time_result)
          var link = post['topic_link'] || '#'
          var text = post['topic_title'] || ''
          var html = '\
<div class="row">\
  <div class="wrapper_text">\
  <div class="time_text_result">' + time_result + '</div>\
  <a href="' + link + '" target="_blank" class="txt">' + text + '</a>\
  <!--<div class="count">9</div>-->\
  </div>\
</div>\
          '
          box.append(html)
        }
        // box.find('.row a').truncate({
        //   width: 'auto',
        //   token: '&hellip;',
        //   side: 'right',
        //   addclass: false,
        //   addtitle: false,
        //   multiline: true
        // })
      }
    })
  }

  function unixtime_to_date_time(post) {
    var now = new Date()
    var date_now = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf()
    var date = new Date(post*1000)
    var m = '0' + (date.getMonth() + 1).toString()
    var month = m.substring(m.length - 2)
    var day = ('0'+date.getDate()).substr(-2)
    var hour = ('0'+date.getHours()).substr(-2)
    var minute = ('0'+date.getMinutes()).substr(-2)
    var month_date_time = day+'.'+month
    var hour_date_time = hour+':'+minute
    if (date < date_now - 86400000) {
      return month_date_time
    } else if (date < date_now) {
      return month_date_time
    } else {
      return hour_date_time
    }
  }
})();
