window.main_modal = function() {
  modal_init_functions()
}

window.modal_init_functions = function() {
  window.Market.modal = {}
  window.Market.modal.buy = {
    place: [],
    init: function() {
      this.place = $(this.options.place_selector)
    },
    clean: function() {
      this.clean_articul()
      this.clean_phonenumber()
      this.clean_email()
    },
    clean_articul: function() {
      if (!this.place.length) {
        return null
      }
      this.place.find(this.options.articul_selector).text('')
    },
    clean_phonenumber: function() {
      if (!this.place.length) {
        return null
      }
      this.place.find(this.options.phonenumber_selector).text('')
    },
    clean_email: function() {
      if (!this.place.length) {
        return null
      }
      this.place.find(this.options.email_selector).text('')
    },
    replace: function(data) {
      if (!this.place.length) {
        return null
      }
      if (data.articul !== undefined) {
        this.place.find(this.options.articul_selector).text(data.articul)
      }
      if (data.phonenumber !== undefined) {
        this.place.find(this.options.phonenumber_selector).text(data.phonenumber + ' ')
      }
      if (data.email !== undefined) {
        this.place.find(this.options.email_selector).text(data.email)
      }
      if (data.name !== undefined) {
        this.place.find(this.options.name_selector).text(data.name)
      }
    },
    show: function() {
      if (!this.place.length) {
        return null
      }
      var input1 = this.place.find('.js_articul_for_order')
      if (input1.length != 1) {
        return null
      }
      var js_articul1 = this.place.find('.js_articul')
      if (js_articul1.length != 1) {
        return null
      }
      var articul1 = js_articul1.text()
      input1.val(articul1)
    },
    options: {
      place_selector: '#modal-4',
      articul_selector: '.js_articul',
      phonenumber_selector: '.js_phonenumber',
      email_selector:'.js_email',
      name_selector:'.product_name',
    }
  }
  window.Market.modal.buy.init()
}
