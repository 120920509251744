;(function() {


"use strict"

window.vue = {}
window.vue.application = {}
window.vue.component = {}
window.vue.main = function() {
  window.vue.component.EventBus.main()
  window.Vue.component("zagination", window.vue_zagination)
  // window.Vue.use(window['vue-js-modal'].default)
  window.Vue.use(window.VModal)
  // window.Vue.use(window['VueRouter'])
  window.Vue.use(VueRouter)
  // window.Vue.use(window.vueRecaptchaApiLoaded)
  window.vue.application.app.router.CabinetRouter.main() 
  window.vue.application.app.main()
}


})();
