;(function() {


"use strict"

window.vue.application.app = {}
window.vue.application.app.data = {
  config: {
    grecaptcha: Object.assign({}, window.uncached.config.grecaptcha)
  }
}
window.vue.application.app.mixin = {}
window.vue.application.app.router = {}
window.vue.application.app.component = {}
window.vue.application.app.main = function() {

  // Получим данные, затем активируем два приложения на них.
  $.ajax({
    url: '/ajax_cart/vue',
  }).done(function(data) {

    window.vue.application.app.mixin.CartMixin.methods.cart_set_data(data.cart)

    window.app = new Vue({
      mixins: [
        window.vue.application.app.mixin.CartMixin,
      ],
      data: window.vue.application.app.data,
      components: {
        "cart-count": window.vue.application.app.component.CartCount,
      }
    }).$mount('#cart-count')

    if ($("#cart-list").length == 1) {
      window.cart_list = new Vue({
        mixins: [
          window.vue.application.app.mixin.CartMixin,
        ],
        data: window.vue.application.app.data,
        components: {
          "cart-list": window.vue.application.app.component.CartList,
        }
      }).$mount('#cart-list')
    }
  })

  // if ($(".vue-grecaptcha").length > 0) {
  //   window.vue_grecaptcha = new Vue({
  //     data: window.vue.application.app.data,
  //     components: {
  //       "vue-grecaptcha": window.VueRecaptcha,
  //     }
  //   }).$mount('.vue-grecaptcha')
  // }
}


})();
