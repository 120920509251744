;(function($) {
  "use strict"

  window.Market.AjaxLiner = function(place, options) {
    options = options || null
    if (!(place instanceof $) || !place.length) {
      throw new window.Error('place is not jQuery object.')
    }
    this.options = _.extend(window.Market.AjaxLiner.options, options||{})
    this.place = place
    this.clickable = true
    this.line = place.children(this.options.line_selector)
    this.next_selector = this.options.next_selector
    this.prev_selector = this.options.prev_selector
    this.next = $(this.next_selector)
    this.prev = $(this.prev_selector)
    this.position = 0
    return this
  }

  window.Market.AjaxLiner.prototype.activate = function() {
    this.activate_ui()
  }

  window.Market.AjaxLiner.prototype.count = function() {
    return  this.line.children(this.options.division_selector).length
  }

  window.Market.AjaxLiner.prototype.activate_ui = function() {
    this.ajax_download()
    this.activate_scroll()
    this.ui_buttons_apply_position()
  }

  window.Market.AjaxLiner.prototype.ajax_download = function() {
    var place = $('#top')
    var player_number = 'player1'
    var where_is_place = place.find('.rows .boxs .box-liner .liner')
    for (var i = 0; i < 2; i++) {
      $.ajax({
        type: 'get',
        async: false,
        cache: false,
        contentType:"application/json; charset=utf-8",
        dataType:"json",
        url: '/videos/by_video_id?video_id=' + i,
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('doesnt work')
        },
        success: function(data, textStatus, jqXHR) {
          var split_data = data['text'].split('.')[0]
          var where_is_liner = where_is_place.find('.liner-line').append(
            '<div class="box blocks mini_video_slider"'+
            'style="background-image: url(https://img.youtube.com/vi/' + data['data']['video_id'] + '/0.jpg);"'+
            'data-id="'+ (i+1) +'">' +
              '<div class="news_name_text"><span class="news_span"> Новости:'+ ' '  + split_data + '</span></div>' +
              '<a style="" href="#" data-link="https://www.youtube.com/embed/' + data['data']['video_id'] + '"' +
              'onClick="window.videoblock_popup(this)"></a>'+
            '</div>'
          )
        }
      })
    }
  }

  window.Market.AjaxLiner.prototype.activate_scroll = function() {
    var listener = $(this.options.listener_selector)
    listener.on('click', this.next_selector, {instance: this}, this.next_handler)
    listener.on('click', this.prev_selector, {instance: this}, this.prev_handler)
  }

  window.Market.AjaxLiner.prototype.next_handler = function(event) {
    var instance = event.data.instance
    if (!instance.clickable) {
      return null
    }
    instance.clickable = false
    var liner_box = $(this).parent().parent()
    var liner_place = liner_box.find('.liner-line .box.blocks').filter( ':last' )
    var id_place = liner_place.data('id')
    var next_id = id_place + 1
    var new_position = instance.calc_position(1)
    var counter = instance.count()
    var next_handler_error = liner_box.find('.font span.liner-next')
    if (new_position != instance.position) {
      instance.set_position(new_position)
      instance.ui_apply_position()
    }
    if (counter - new_position == 1) {
      $.ajax({
        type: 'get',
        cache: false,
        url: '/videos/by_video_id?video_id=' + id_place,
        error: function(jqXHR, textStatus, errorThrown) {
          console.log('Load Error')
        },
        success: function(data, textStatus, jqXHR) {
          var player_number = 'player' + id_place
          var split_data = data['text'].split('.')[0]
          if (!data || data == null) {
            next_handler_error.removeClass('liner-next')
          } else {
            var liner_line = liner_box.find('.liner-line').append(
              '<div class="box blocks mini_video_slider"'+
              'style="background-image: url(https://img.youtube.com/vi/' + data['data']['video_id'] + '/0.jpg);"'+
              'data-id="'+ next_id +'">' +
              '<div class="news_name_text"><span class="news_span"> Новости:'+ ' ' + split_data + '</span></div>' +
                '<a style="" href="#" data-link="https://www.youtube.com/embed/' + data['data']['video_id'] + '"' +
                'onClick="window.videoblock_popup(this)" allowfullscreen="allowfullscreen"></a>'+
              '</div>'
            )
          }
        }
      })
    }
    setTimeout(function() {
     instance.clickable = true
    }, 900)
  }

  window.Market.AjaxLiner.prototype.prev_handler = function(event) {
    var instance = event.data.instance
    var new_position = instance.calc_position(-1)
    if (new_position != instance.position) {
      instance.set_position(new_position)
      instance.ui_apply_position()
    }
  }

  window.Market.AjaxLiner.prototype.calc_left = function() {
    return this.position * -this.options.division_width
  }

  window.Market.AjaxLiner.prototype.calc_position = function(direction) {
    direction = direction || 1
    var new_position = this.position + direction * this.options.division_step
    new_position = this.correct_position(new_position)
    return new_position
  }
  window.Market.AjaxLiner.prototype.correct_position = function(position) {
    if (position + 1 > this.count - this.options.window_division) {
      position = this.count - this.options.window_division
    }
    if (position < 0) {
      position = 0
    }
    return position
  }

  window.Market.AjaxLiner.prototype.set_position = function(position) {
    position = this.correct_position(position)
    this.position = position
  }

  window.Market.AjaxLiner.prototype.ui_apply_position = function() {
    this.ui_line_apply_position()
    this.ui_buttons_apply_position()
  }

  window.Market.AjaxLiner.prototype.ui_line_apply_position = function() {
    var left = this.calc_left()
    this.line.css('left', left + 'px')
  }

  window.Market.AjaxLiner.prototype.ui_buttons_apply_position = function() {
    this.ui_prev_apply_position()
    this.ui_next_apply_position()
  }

  window.Market.AjaxLiner.prototype.ui_prev_apply_position = function() {
    var next_handler_add = this.place.find('.font span.mad')
    next_handler_add.addClass('liner-next')
    if (this.position == 0) {
      this.prev.addClass('disable')
    } else {
      this.prev.removeClass('disable')
    }
  }

  window.Market.AjaxLiner.prototype.ui_next_apply_position = function() {
    var count = this.count()
    if (this.position < this.count ) {
      this.next.addClass('disable')
    }
  }

  window.Market.AjaxLiner.options = {
    listener_selector: 'body',
    next_selector: '.liner-next',
    prev_selector: '.liner-prev',
    dom: '.boxs .box-liner .liner',
    main_page_selector : '#wrapper #top .rows',
    line_selector: '.liner-line',
    division_selector: 'div.box.blocks',
    division_width: 310,
    window_division: 1,
    division_step: 1
  }
  window.Market.AjaxLiner.activate = function(){
    var main_page = $(window.Market.AjaxLiner.options.main_page_selector)
    var dom = $(window.Market.AjaxLiner.options.dom)
    if (!main_page.length || dom.length != 1) {
      return null
    }
    new window.Market.AjaxLiner(dom).activate()
  }

})(jQuery);
