window.main_buying_report = function() {
  buying_report_init()
}

window.buying_report_init = function() {
  var buying = $('body').find('#page .js_buy_wrapper .js_under_the_order')
  var modal4 = $('#modal-4')
  buying.click(function(){
    buying_show()
  })
  var order_modal_button = modal4.find('.btn.blue_order')
  var warning_message =  modal4.find('.js_error_message')
  order_modal_button.click(function(){
    var selected = modal4.find('select.zenoselect').val()
    var personal_data = modal4.find('input#goods_purchase_personal_data').prop('checked')
    if (selected != '' && personal_data == true) {
      modal4.find('.btn.blue_order').hide()
      modal4.find('.donut-abs').show()
    }
  })
  $('#modal-4').on('ajax:success', function(data, status, xhr) {
    if (data["detail"][0] == "ok") {
      // hide
      modal4.find('.donut-abs').hide()
      modal4.find('.goods_purchase_message').hide()
      modal4.find('.new_goods_purchase').hide()
      modal4.find(".js_incorrect_turing_test").hide()
      modal4.find('.js_error_message').hide()
      // show
      modal4.find('.buying .message_send').show()
      modal4.find('.buying .thanks').show()
    } else if (data["detail"][0] == "bot") {
      // hide
      modal4.find('.js_error_message').hide()
      // show
      modal4.find(".js_incorrect_turing_test").show()
      window.grecaptcha.reset()
      modal4.find('.btn.blue_order').show()
      modal4.find('.donut-abs').hide()
    } else {
      // hide
      modal4.find(".js_incorrect_turing_test").hide()
      // show
      modal4.find('.js_error_message').empty()
      modal4.find('.js_error_message').append(data["detail"][0])
      modal4.find('.js_error_message').show()
      modal4.find('.btn.blue_order').show()
      modal4.find('.donut-abs').hide()
      window.grecaptcha.reset()
      modal4.find('.btn.blue_order').show()
      modal4.find('.donut-abs').hide()
    }
  })
  $('body').on('ajax:error', function(data, status, xhr) {
    console.log('> НЕ РАБОТАЕТ')
    modal4.find('.btn.blue_order').show()
    modal4.find('.donut-abs').hide()
  })
}

window.buying_show = function() {
  var modal4 = $('#modal-4')
  modal4.arcticmodal({
    overlay: {
      css: { opacity: .3 }
    },
    beforeOpen: function(data, el) {
      modal4.find('.goods_purchase_message').show()
      modal4.find('.new_goods_purchase').show()
      modal4.find('.btn.blue_order').show()
      modal4.find('.buying .message_send').hide()
      modal4.find('.buying .thanks').hide()
      modal4.find('.donut-abs').hide()
      modal4.find('.js_error_message').hide()
      modal4.find('.js_error_message').empty()
    },
    beforeClose: function(data, el) {
    }
  });
}
