;(function() {


"use strict"

window.main_account = function() {
  window.account_activate_select()
  window.account_activate_add_client()
  window.account_activate_yandex_metric_new_client_added()
}

// TODO Активация смены текущего клиента в шаблоне аккаунта.
window.account_activate_select = function() {
  $(".account .client_select").on("change", function(event) {
    var select = $(event.target)
    if (select.length != 1) {
      return null
    }
    var client_id = select.val()
    if (typeof client_id !== "string") {
      return null
    }
    if (client_id.length == 0) {
      return null
    }
    if (/account\/order_history/i.test(window.location.pathname)) {
      window.location.href = "/account/order_history/" + client_id
    } else {
      window.location.href = "/account/" + client_id
    }
  })
}

// Активация на странице добавления Клиента.
// 1. Покажем поля для текущей Организационно-правовой формы
// 2. Активируем обработчик изменения Организационно-правовой формы
window.account_activate_add_client = function() {
  // 1.
  var selected_form_iput = $('form.add_client_form input[name="market_model_client[form]"]:checked')
  if (selected_form_iput.length == 1) {
    window.account_add_client_switch_form(selected_form_iput.val())
  }
  // 2.
  $('form.add_client_form input[name~="market_model_client[form]"]').on("change", function(event) {
    var form_input = event.target
    var form_key = form_input.value
    window.account_add_client_switch_form(form_key)
  })
}

// Переключает поля для указанной Организационно-правовой формы при созданиие Клиента.
window.account_add_client_switch_form = function(form_key) {
  var field_rows = $("form.add_client_form .field_row").each(function(index, dom) {
    var row = $(dom)
    if (row.hasClass(form_key)) {
      row.slideDown()
    } else {
      row.slideUp()
    }
  })
}

window.account_activate_yandex_metric_new_client_added = function() {
  var is_added = $(".js_new_client_added").length > 0
  if (is_added) {
    if (!window.uncached.env.seo_counters_off) {
      window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'NEW_CLIENT_ADDED')
    }
  }
}


})();
