window.main_write_to_leader = function() {
  write_to_leader_activate_open()
}

window.write_to_leader_activate_open = function() {
  var button = $(".write_to_leader.side_buttons-button")
  button.on("click", function() {
    var modal = $("#modal-6")
    modal.arcticmodal({
      overlay: {
        css: { opacity: .3 }
      },
      beforeOpen: function(data, el) {
        // $(".write_to_leader").animate({ right: -100 }, 400);
        // $(".callback_report").animate({ right: -100 }, 400);
        // modal.find(".form").show()
        // modal.find(".report_error .message_send").hide()
        // modal.find(".report_error .thanks").hide() 
        // modal.find(".error_explanation").hide()
        // modal.find(".info_block").show()
        // modal.find(".name").show()
      },
      beforeClose: function(data, el) {
        // $(".write_to_leader").animate({ right: 0 }, 400);
        // $(".callback_report").animate({ right: 0 }, 400);
      }
    });
  })
}
