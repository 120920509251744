;(function(){
  "use strict"

  window.Market.TovarCollection = (function() {
    var instance
    var create_instance = function() {
      var options = window.Market.TovarCollection.options
      var place = $(window.Market.TovarCollection.options.place_selector)
      if (!place.length) {
        console.log('Не обнаружено место коллекции товаров.')
        // return null
      }
      var instance = new Object({
        place: place,
        options: options,
        mouseenter_timeout_id: null,
        intersection_ids: [],
        status: {
          add_to_cart_disabled: {}
        },
        activate: function() {
          this.place = $(this.options.place_selector)
          this.intersection_ids = []

          // this.activate_instead_unlimited_loading()
          this.activate_unlimited_loading()
          this.activate_button_show_modal_group()
          this.activate_button_show_modal_buy()
          this.activate_button_add_to_cart()
          this.activate_button_add_category_products_to_cart()
          // this.check_browzer()
          this.activate_button_show_sale_products()
          this.activate_button_show_all_products()
          this.activate_hovers_on_amount()
          this.activate_choose_count()
          // this.activate_liners()
          window.tc = this
        },
        activate_instead_unlimited_loading: function() {
          var button_block = $('.content .filters')
          var all_product_show = button_block.find(this.options.show_all_products_button_selector)
          var all_sales_show = button_block.find(this.options.show_sales_button_selector)
          var instance = this
          var sales_count = 
            instance.place.find('.promotion, .liquidation').length
          var sales_button = all_sales_show.find('.sales_number').text(sales_count)
          if (sales_count > 0) {
            all_sales_show.attr('disabled', false)
          }
          all_product_show.attr('disabled', false)
          window.main_lazyload()
          window.countin_the_color()
          window.second_countin()
          window.second_attrs_bind_change()
        },
        // Загрузка остальных товаров, если загружены только 4 первых.
        activate_unlimited_loading: function() {
          if (this.place.length != 1) {
            return null
          }
          if (this.place.data("is_limited")) {
            var loaded = new Promise(function(resolve, reject) {
              $.ajax({
                type: 'get',
                url: window.location.pathname,
                data: {
                  unlimited_loading: null
                },
                error: function(jqXHR, textStatus, errorThrown) {
                  reject(jqXHR.status + ' ' + errorThrown)
                },
                success: function(data, textStatus, jqXHR) {
                  resolve(data)
                }
              })
            })
            loaded.then(
              this.unlimited_insert.bind(this),
              function(reason) {
                console.log('reason: ', reason)
              }
            )
          }
        },
        unlimited_insert: function(html_data) {
          console.log('> unlimited_insert')
          var button_block = $('.content .filters')
          var all_product_show = button_block.find(this.options.show_all_products_button_selector)
          var all_sales_show = button_block.find(this.options.show_sales_button_selector)
          var products_html = $(html_data)
          var liners = products_html.filter('.liner')
          var instance = this
          liners.each(function(i, d) {
            var liner = $(d)
            var liner_id = liner.data('liner_id')
            if (!liner_id) {
              return null
            }
            var limited_liner = instance.place.children('.liner[data-liner_id=' + liner_id + ']')
            if (!limited_liner.length) {
              return null
            }
            var limited_line = limited_liner.children('.liner-line')
            var limited_count = limited_line.children('[tovar_id]').length
            var line = liner.children('.liner-line')

            // Удаляем первые товары в пришедшем контенте перед вставкой
            // line.children("[tovar_id]").remove(":nth-child(-n+" + limited_count + ")")

            // Вставляем пришедший контент
            // limited_line.empty()
            // limited_line.html(line.html().trim())
            limited_line.find(">.box[tovar_id]:last-child").after(line.html().trim())

            // Удаляем первые товары в пришедшем контенте после вставки
            // var slice_begin = limited_count
            // var slice_end = limited_count * 2
            // limited_line.children('[tovar_id]').slice(slice_begin, slice_end).remove()

            if (bowser.chrome) {
              if (bowser.version <= 51) {
                var limited_line_box = limited_line.find('.box .img a img')
                limited_line_box.each(function(){
                  var attr_text_url = $(this).attr('data-src')
                  var new_img_attr = $(this).attr('src', attr_text_url)
                  var attr_delete = $(this).removeAttr('data-src')
                })
              }
            }
          })
          var script = products_html.find("script")
          if (script.length == 1) {
            eval(script.text())
          }
          var sales_count = 
            instance.place.find('.promotion, .liquidation').length
          var sales_button = all_sales_show.find('.sales_number').text(sales_count)
          if (sales_count > 0) {
            all_sales_show.attr('disabled', false)
          }
          all_product_show.attr('disabled', false)
          this.activate_liners()
          window.main_lazyload()
          // trader_apply_async($.cookie('phil'))
          window.countin_the_color()
          window.second_countin()
          window.second_attrs_bind_change()
          window.main_jquery_ui()
          var fc = window.Market.FilterCollection.get_instance()
          fc.pick_data = fc.unpuck_pick_data(window['filter_session'])
          fc.sort_filters_data()
          fc.apply_filters_data_to_collection()
          fc.checkize_pick_data()
          fc.buttons_reset_apply_pick_data()
          fc.redisable_pickers()
          if (fc.pick_data.length) {
            this.apply_filter()
          }
        },
        check_browzer: function(){
          var liner_in_searcher = this.place.find('.liner')
          if ( liner_in_searcher.length > 0) {
            return null
          }
          var image = this.place.find('.box.blocks .img img')
          image.each(function(){
            var attr_text_url = $(this).attr('data-src')
            var new_img_attr = $(this).attr('src', attr_text_url)
            var attr_delete = $(this).removeAttr('data-src')
          })
        },
        activate_choose_count: function() {
          $('body').on(
            'click',
            this.options.plus_add_tovar_count,
            {instance:this},
            this.add_tovar_count_handler
          )
          $('body').on(
            'click',
            this.options.minus_remove_tovar_count,
            {instance:this},
            this.remove_tovar_count_handler
          )
        },
        activate_liners: function() {
          var callback_scroll = function(position, count, place, prev, next) {
            // Тригер на появление картинки (скролим +1 пиксел вниз и вверх, ага)
            window.lazyload_trigger
          }
          this.place.find('.liner').each(function(i, dom) {
            new window.Market.Liner(
              $(dom),
              {
                callback_scroll: callback_scroll,
                window_division: 4,
                division_width: 214
              }
            ).activate()
          })
        },
        activate_button_show_all_products: function() {
          $('body').on(
            'click',
            this.options.show_all_products_button_selector,
            {instance:this},
            this.show_all_products_handler
          )
        },
        activate_button_show_sale_products: function() {
          $('body').on('click', this.options.show_sales_button_selector,
            {instance:this},
            this.show_all_sales_handler
          )
        },
        activate_hovers_on_amount: function() {
          $('body').on(
            'mouseenter',
            this.options.amount_show.selectors.amount,
            {instance:this},
            this.mouseenter_on_amount
          )
          $('body').on(
            'mouseleave', 
            this.options.amount_show.selectors.amount,
            {instance:this}, 
            this.mouseleave_from_amount
          )
          // this.check_browzer()
        },
        remove_tovar_count_handler: function(event) {
          var input = $(this).parent().find(".int")
          var count = parseFloat(input.val())
          // Это раньше имело значение как минимального значения так и
          // минимального шага. Теперь понимать это как минимальный шаг, живи
          // теперь с этим.
          var minimum = parseFloat(input.data("minimum"))
          if (isNaN(minimum) || isNaN(count)) {
            return null
          }
          // Если не кратно минимальному - делаем кратным, в большую сторону.
          if ((count*1000) % (minimum*1000) > 0) {
            count = (Math.floor(count / minimum) + 1) * minimum
            count = Math.round(count*1000)/1000
          }
          count = count - minimum
          count = Math.round(count*1000)/1000
          // Нижнее значение, бывает на странице Коммерческих предложений.
          var lower_limit = input.data("lower_limit")
          if (lower_limit != null) {
            var min = lower_limit
          } else {
            var min = 0
          }
          if (count < min) {
            count = min
          }
          input.val(count)
        },
        add_tovar_count_handler: function(event) {
          var input = $(this).parent().find(".int")
          var count = parseFloat(input.val())
          var minimum = parseFloat(input.data("minimum"))
          if (isNaN(minimum) || isNaN(count)) {
            return null
          }
          // Если не кратно минимальному - делаем кратным, в большую сторону.
          if ((count*1000) % (minimum*1000) > 0) {
            count = (Math.floor(count / minimum) + 1) * minimum
            count = Math.round(count*1000)/1000
          }
          count = count + minimum
          count = Math.round(count*1000)/1000
          input.val(count)
        },
        mouseleave_from_amount: function(event) {
          var instance = event.data.instance
          var amount_window = $(instance.options.amount_show.selectors.all_amount)
          var hide_wrapper = $(instance.options.amount_show.selectors.wrapper)
          if (instance.mouseenter_timeout_id!=null) {
            clearTimeout(instance.mouseenter_timeout_id)
            instance.mouseenter_timeout_id = null
          }
          amount_window.empty()
          hide_wrapper.hide()
        },
        mouseenter_on_amount: function(event) {
          var instance = event.data.instance
          var wrapper_amountlist = this
          instance.mouseenter_timeout_id = setTimeout(function (){
            instance.get_data_amount.bind(wrapper_amountlist)()
          }, instance.options.amount_show.selectors.enter_delay)
        },
        get_data_amount: function() {
          var amount_places = $(this)
          var tovar_box = amount_places.parents(instance.options.tovar_selector)
          var articul_info = tovar_box.find('.box2 .art').text()
          var single_tovar = amount_places.parents(instance.options.tovar_info)
          var single_articul = single_tovar.find('.art').text()
          // var articul = articul_info.replace(/[^0-9]/gim,'')
          var articul = amount_places.data("code")
          var articul_in_product = single_articul.replace(/[^0-9]/gim,'')
          $.ajax({
            type: 'get',
            url: '/products/by_phil?articul='+ (articul || articul_in_product), 
            success: function(data, textStatus, jqXHR) {
              if (data == 0) {
                return null
              }
              var amount_phil_info_place = amount_places.find(instance.options.amount_show.selectors.all_amount)
              for (var i = 0, l = data.length; i < l; i++) {
                var phil_name = data[i]
                amount_phil_info_place.append(`
                  <div class="only_phil">
                    <div class="turn blocks">
                      <div class="row" style="disc blocks"></div>
                    </div>
                    <div class="amoun_in_phil">` + phil_name + `</div> 
                  </div>
                `)
              }
              for (var i = 0; i < data.length; i++) {
                var phil_name = data[i]
              }
              amount_places.find(instance.options.amount_show.selectors.wrapper).show()
            }
          })
        },
        show_all_products_handler: function(event) {
          var button = $(event.currentTarget)
          var instance = event.data.instance
          if (instance.place.hasClass('check')) {
            if (instance.place.hasClass('limited')) {
              instance.remove_limited()
              button.addClass("active")
            } else {
              instance.add_limiter_class()
              button.removeClass("active")
            }
          }
        },
        remove_limited: function() {
          this.place.removeClass('limited')
          this.place.find('.liner-line').css("left", "auto")
        },
        remove_limiter_class: function() {
          this.place.removeClass('limited')
          this.place.removeClass('check')
        },
        add_limiter_class: function() {
          var button_place_add = $('.count_prod .label.blocks')
          var button_all_selector_add = button_place_add.find(this.options.show_all_products_button_selector)
          this.place.addClass('limited')
          this.place.addClass('check')
        },
        show_all_sales_handler: function(event) {
          var instance = event.data.instance
          var button_place = $(".content .filters")
          var button = $(event.target)
          var all_products_button = button_place.find(instance.options.show_all_products_button_selector)
          if (instance.place.hasClass('sales')) {
            // Показать товары только с маркетинговыми мероприятиями
            button.addClass('sales_button')
            all_products_button.attr("disabled", true)
            all_products_button.removeClass("active")
            instance.place.find('.liner-line').css("left", "auto")
            instance.place.find('.liner-line > .box.blocks').hide()
            instance.place.find('> .box.blocks').hide()
            instance.remove_limiter_class()
            instance.place.removeClass('sales')
            instance.place.find('.liner-line > .box.blocks.liquidation').show()
            instance.place.find('> .box.blocks.liquidation').show()
            instance.place.find('.liner-line > .box.blocks.promotion').show()
            instance.place.find('> .box.blocks.promotion').show()
            instance.place.find('.liner').filter((i,d)=>{return $(d).height()==0}).each((i,d)=>{$(d).prev('.h2').hide()})
            window.lazyload_trigger()
          } else {
            // Показать все товары
            button.removeClass('sales_button')
            all_products_button.attr("disabled", false)
            instance.add_limiter_class()
            instance.place.addClass('sales')
            instance.place.find('.h2').show()
            instance.place.find('.liner-line > .box.blocks').show()
            instance.place.find('> .box.blocks').show()
          }
        },
        add_button_show_all_products: function() {
          $('body').find(this.options.show_all_products_button_selector).show()
        },
        activate_button_show_modal_group: function() {
          this.place.on(
            'click',
            this.options.button_show_modal_group_selector,
            {instance:this},
            this.show_modal_group_handler
          )
        },
        activate_button_add_to_cart: function() {
          this.place.on(
            'click',
            this.options.button_add_to_cart,
            {instance:this},
            this.add_to_cart_handler
          )
        },
        activate_button_add_category_products_to_cart: function() {
          var button = $(".js_add_category_products_to_cart")
          if (button.length != 1) {
            return null
          }
          button.on("click", function(event) {
            var button = $(this)
            if (button.hasClass("too_many")) {
              button.removeClass("success_action")
              button.attr("data-success_action", "Слишком много товаров (>100)")
              setTimeout(function() {
                button.addClass("success_action")
              }, 300)
              return null
            }
            var id = button.data("category_id")
            button.removeClass("success_action")
            button.addClass("loading")
            $.ajax({
              type: 'get',
              url: ('/ajax_cart/add_category/' + id),
              custom_data: {button: button},
              cache: false
            }).done(function(data, textStatus, jqXHR) {
              this.custom_data.button.attr("data-success_action", "Товары добавлены")
              window.app.$refs.CartCount.$_ajax_del("_")
            }).fail(function(jqXHR, textStatus, errorThrown) {
              this.custom_data.button.attr("data-success_action", "Внутренняя ошибка сервера")
            }).always(function(data_jqXHR, textStatus, jqXHR_errorThrown) {
              this.custom_data.button.removeClass("loading")
              this.custom_data.button.addClass("success_action")
            })
          })
        },
        add_to_cart_handler: function(event) {
          if (!window.uncached.env.seo_counters_off) {
            window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'ADD_TO_CART_HANDLER')
          }
          var instance = event.data.instance
          var button = $(this)
          var tovar = button.parents(instance.options.tovar_selector)
          var articul = tovar.find('.art').text().trim().slice(8)
          if (instance.status.add_to_cart_disabled[articul] == true) {
            return null
          }
          var input = tovar.find(instance.options.tovar.selectors.amount_input)
          var count = input.val()
          button.removeClass("success_action")
          instance.status.add_to_cart_disabled[articul] = true
          button.prop("disabled", true)
          button.addClass("loading")
          window.app.cart_add(articul, count).then(
            function(data) {
              if (Object.prototype.toString.call(data.list) === '[object Array]') {
                for (var i = 0, l = data.list.length; i < l; i++) {
                  var item = data.list[i]
                  if (item.articul == articul) {
                    var count = item.count
                    var text = "в корзине " + count + " " + count.declension("товаров,товар,товара")
                    button.attr("data-success_action", text)
                  }
                }
              }
              instance.status.add_to_cart_disabled[articul] = false
              button.removeClass("loading")
              button.prop("disabled", false)
              button.addClass("success_action")
            },
            function(reason) {
              instance.status.add_to_cart_disabled[articul] = false
              button.removeClass("loading")
              button.prop("disabled", false)
            }
          )
        },
        activate_button_show_modal_buy: function() {
          this.place.on(
            'click',
            this.options.button_show_modal_by_selector,
            {instance:this},
            this.show_modal_by_handler
          )
        },
        show_modal_by_handler: function(event) {
          var instance = event.data.instance
          var button = $(this)
          var tovar = button.parents(instance.options.tovar_selector)
          var subdivision_place = tovar.attr('data-subdivision')
          var subdivisions_phone = subdivisions.Техник.phone_number
          var subdivisions_email = subdivisions.Техник.email

          if (tovar.length != 1) {
            console.log('> Не определён товар.')
            return null
          }
          var modal = $('#modal-4')
          if (!modal.length) {
            console.log('> Не найдена модаль покупки.')
            return null
          }
          // Очистить
          window.Market.modal.buy.clean()
          // Взять
          var articul = tovar.find('.art').text().trim().slice(8)
          var name = tovar.find(".name_wrap .name").text()
          var affiliate_phonenumber = $('#header .phone').first().text() || ''
          var phonenumber = subdivision_place == 'Техник' ? subdivisions_phone : affiliate_phonenumber
          var affiliate_email = window.cache.affiliate[$.cookie('phil')]["email"]
          var email = subdivision_place == 'Техник' ? subdivisions_email : affiliate_email
          // Вставить
          window.Market.modal.buy.replace({
            articul: articul,
            name: name,
            phonenumber: phonenumber,
            email: email
          })
          window.Market.modal.buy.show()
          window.main_buying_report()
        },
        show_modal_group_handler: function(event) {
          var instance = event.data.instance
          var button = $(this)
          var tovar = button.parents(instance.options.tovar_selector)
          if (tovar.length != 1) {
            console.log('> Не определён товар.')
            return null
          }
          var modal = $('#modal-group')
          if (!modal.length) {
            console.log('> Не найдена модаль группы.')
            return null
          }

          // Очистить
          modal.find(instance.options.modal_group.selectors.name).text('')
          modal.find(instance.options.modal_group.selectors.img_w).empty()
          modal.find(instance.options.modal_group.selectors.color_box).empty()
          modal.find(instance.options.modal_group.selectors.second_filter).empty()
          //modal.find('.price').text('')
          modal.find('.art').text('')

          // Взять
          var color_change = null
          var articul = tovar.find('.art').text().trim().slice(8)
          var slug = tovar.attr('slug_container')
          var tovar_name = tovar.find(instance.options.tovar.selectors.name).text()
          var tovar_img = tovar.find(instance.options.tovar.selectors.img_w).children().clone()
          var tovar_color_box = ''
          tovar.find(instance.options.tovar.selectors.color_box)
            .children()
            .clone()
            .slice(0, -1)
            .each(function(i,d) {
              var div_radio = $(d)
              if (div_radio.hasClass('radio_act')){
                color_change = div_radio.attr('title')
              } 
              var input = div_radio.children('input')
              var label = div_radio.children('label')
              input.attr('id', 'modal_group' + input.attr('id'))
              label.attr('for', 'modal_group' + label.attr('for'))
              tovar_color_box+= $(d).get(0).outerHTML
            })
          var second_filter_name = tovar.find('.txt2').text()
          var second_filter = ''
          var second_value = tovar.find('.int').val()
          var select = tovar.find('.select select')
          select.children().each(function(index, dom) {
            var option = $(dom)
            var second_value_current = option.text()
            var current = ''
            var graize = ''
            if (option.hasClass('disabled')) {
              graize = ' graize'
            }
            if (second_value == second_value_current) {
              current = ' radio_act'
            }
            second_filter += '\
              <span class="radio radio3' + current + graize + '">' + second_value_current + '</span>'
          })

          // Вставить
          modal.find(instance.options.modal_group.selectors.color_text).text('Цвет: ' + color_change)
          modal.find(instance.options.modal_group.selectors.name).text(tovar_name)
          modal.find(instance.options.modal_group.selectors.img_w).append(tovar_img)
          modal.find(instance.options.modal_group.selectors.color_box).html(tovar_color_box)
          modal.find(instance.options.modal_group.selectors.second_filter_name).text(second_filter_name)
          modal.find(instance.options.modal_group.selectors.second_filter).append(second_filter)
          modal.find(instance.options.modal_group.selectors.second_filter).attr('slug', slug)
          window.mog_replace({articul: articul})
          window.mog_ajax(articul)

          modal.arcticmodal({
            overlay: {
              css: { opacity: .3 }
            }
          })

          //Back chromium
          $(".arcticmodal-overlay").text(".")
        },
        get_articuls: function() {
          if (!this.place.length) {
            return null
          }
          var articuls = []
          this.place.find(this.options.tovar_selector).each(function(index, dom){
            var t = $(dom)
            var articul = t.data('tovar_articul')
            if (articul) {
              articuls.push(articul)
            }
          })
          return articuls
        },
        apply_filter: function() {
          var is_place_limited_before_apply_filter = this.place.hasClass('limited')
          this.remove_limiter_class()
          $("div[tovar_id]").hide()
          $('.products > div.h2').hide()
          var pdata = window.Market.FilterCollection.get_instance().pick_data
          if (pdata.length == 0) {
            $("div[tovar_id]").show()
            var is_previous_view_limited = this.place.data('previous_view_is_limited')
            if (is_previous_view_limited) {
              this.add_limiter_class()
              this.add_button_show_all_products()
            }
          } else {
            if (is_place_limited_before_apply_filter) {
              this.place.data('previous_view_is_limited', true)
            }
            var intersection_ids = window.Market.FilterCollection.get_instance().calc_intersection_ids()
            for (var n = 0; n < intersection_ids.length; n++) {
              $("div[tovar_id='" + intersection_ids[n] + "']").show()
            }
          }

          // START Скрываем неподходящие уточнения если натыкан фильтр Цвет
          var pick_colors = []
          var delimiter = window.Market.FilterCollection.get_instance().options.pick_data_delimiter
          for (var i=0, l=pdata.length; i<l; i++) {
            var pick_data_array = pdata[i].split(delimiter)
            var filter_name = pick_data_array[0]
            var filter_value = pick_data_array[1]
            if (filter_name=='Цвет') {
              pick_colors.push(filter_value)
            }
          }
          if (pick_colors.length) {
            for(var i=0, l=pick_colors.length; i<l; i++) {
              var pick_color = pick_colors[i]
              var picks_to_sort = $('.products .color_box .radio[data-filter_color="' + pick_color + '"]')
              picks_to_sort.each(function(index, dom) {
                var pick = $(dom)
                var color_box = pick.parent()
                pick.detach()
                pick.prependTo(color_box)
              })
            }
          }
          // FINISH Скрываем неподходящие уточнения если натыкан фильтр Цвет

          // filter_picker_set_to_session(pdata)
          $('.products > div.h2').each(function(index, dom) {
            var header = $(dom)
            var count_of_visible_products = header.nextUntil('div.h2').find('.box.blocks:visible').length
            if (count_of_visible_products > 0) {
              header.show()
            }
          })
        }
      })
      return instance
    }
    return {
      options: {
        place_selector: '.products',
        tovar_selector: 'div[tovar_id]',
        tovar: {
          promotion_selector: "div[tovar_id].promotion",
          selectors: {
            name: '.name',
            img_w: '.js_img_w',
            color_box: '.color_box',
            price: '.js_price_wrapper',
            amount: '.js_amount_wrapper',
            buy: '.js_buy_wrapper',
            amount_input: '.js_buy_wrapper input.int',
          }
        },
        tovar_info: '#product .right',
        modal_group: {
          selectors: {
            name: '.js_group_name',
            img_w: '.js_img_w',
            color_text: '.color_change',
            color_box: '.color_box',
            second_filter_name: '.js_second_filter_name',
            second_filter: '.js_second_filter'
          }
        },
        amount_show: {
          selectors: {
            amount: '.js_available_by_network',
            all_amount: '.wrapper .show_amount',
            wrapper:'.wrapper',
            enter_delay: 200
          }
        },
        button_add_to_cart: '.js_button_add_to_cart:not(.disabled)',
        button_show_modal_group_selector: 'div[data-tovar_type="group"] div.color_box .modals',
        button_show_modal_by_selector: '.js_under_the_order',
        show_all_products_button_selector: '.js_show_all_products',
        plus_add_tovar_count:'.plus.blocks',
        minus_remove_tovar_count:'.minus.blocks',
        show_sales_button_selector: '.js_show_all_sales',
        group: {
          selector: '[data-tovar_type="group"]'
        },
        // TODO спросить у Жени (уже спросили), может выпилить бизнес логику min_price из группы товара,
        // она кстати уже не работает кажись (точно).
        // Теперь работает логика выбора товара с ненулевым наличием в группе (по порядку в группе).
        // так что логику min_price выпиливаем!!!
        min_price: {
          ajax: {
            type: 'get',
            url: '/groups/min_price',
            dataType: 'json',
            cache: false
          },
          selector: 'div.price span.price',
          from: 'от ',
          currency: ' &#8381;'
        },
        color_slider: {
          selector: '.slider_color',
          slide_if_more_than: 6,
          number_of_scrollable: 3,
          duration: 600
        }
      },
      get_instance: function() {
        if (instance==null) {
          instance = create_instance()
        }
        return instance
      },
      activate: function() {
        var instance = this.get_instance()
        if (!instance) {
          return null
        }
        return instance.activate()
      }
    }
  })()

})();
