// register modal component
Vue.component('modal', {
  template: `
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              Компания зарегистрирована успешно
              <button class="modal-default-button" @click="$emit('close')">
                Ok
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  `,
})
