window.main_order = function() {
  order_activate_seo()
  order_activate_market_model_order_pickup()
  order_activate_market_model_order_switch_client()
}

window.order_activate_seo = function() {
  var order_mark = $("#js_success_buy_order")
  if (order_mark.length == 1) {
    if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
      window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SUCCESS_BUY_ORDER')
    }
  }
}

/**
 * Активирует выбор способа доставки (прячет/показывает необходимые поля формы)
 * @return [void]
 */
window.order_activate_market_model_order_pickup = function() {
  var market_model_order_pickup_input = $("input[name=market_model_order\\[pickup\\]]")
  if (market_model_order_pickup_input.length == 0) {
    return null
  }
  var switch_pickup = function(pickup) {
    if (pickup) {
      if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
        window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SWITCH_PICKUP_TRUE')
      }
      $('#by_order_delivery_address').slideUp()
      $('#by_order_pickup_affiliate').slideDown()
    } else {
      if (!window.uncached.env.seo_counters_off && window.uncached.config.yandex_metrika.id != null) {
        window.ym(window.uncached.config.yandex_metrika.id, 'reachGoal', 'SWITCH_PICKUP_FALSE')
      }
      $('#by_order_delivery_address').slideDown()
      $('#by_order_pickup_affiliate').slideUp()
    }
  }
  market_model_order_pickup_input.on("change", function(event) {
    switch_pickup($(this).val() == "true")
  })
  switch_pickup($("input[name=market_model_order\\[pickup\\]]:checked").val() == "true")
}

/**
 * Активируем поле селекта для немедленного переключения на другого клиента.
 * @return [void]
 */
window.order_activate_market_model_order_switch_client = function() {
  var select = $("#market_model_order_switch_client_id")
  if (select.length != 1) {
    return null
  }
  select.on("change", function(event) {
    var initial_client_id = $(this).data("initial_client_id")
    var new_client_id = $(this).val()
    if (new_client_id == initial_client_id) {
      return null
    }
    var a = confirm('Вы действительно хотите использовать другого клиента для совершения заказа?')
    if (a) {
      window.location.replace("/cart/to_order?client_id=" + new_client_id)
    }
  })
}
