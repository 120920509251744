;(function(){
  "use strict"

  window.Market.FilterCollection = (function() {
    var instance
    var create_instance = function() {
      var options = window.Market.FilterCollection.options
      var place = $(window.Market.FilterCollection.options.place_selector)
      var instance = new Object({
        options: options,
        place: place,

        // Выбранные пиккеры
        // ["ИмяФильтра-=delimiter=-ЗначениеПиккера"]
        pick_data: [],

        // Данные фильтра
        // {"ИмяФильтра": ["ЗначениеПиккера1", "ЗначениеПиккера2"]}
        filters_data: window.filter_data || {},

        // Данные соответствия пиккера товарам
        // {"ИмяФильтра ЗначениеПиккера": [IdТовара1, IdТовара2]}
        pickers_data: window.filter_picker || {},

        collection: {},

        activate: function() {
          this.place = $(this.options.place_selector)
          this.pick_data = []
          this.filters_data = window.filter_data || {}
          this.pickers_data = window.filter_picker || {}
          this.sort_filters_data()
          if (!this.check_place()) {
            return this
          }
          // Генерируется коллекция фильтров и сохраняется в window.Market.FilterCollection.get_instance().collection:
          this.generate_collection()
          // this.activate_collection() // не реализовано, активируется тут: this.activate_changer()
          this.activate_changer()
          this.activate_slideup()
          this.activate_limiter()
          this.activate_colorize()
          this.activate_buttons_reset()
          window.fc = this
          return this
        },

        /**
         * Сортирует данные фильтра „Цена“, если определены
         * @return {void}
         */
        sort_filters_data: function() {
          if (Object.prototype.toString.call(this.filters_data.Цена) === '[object Array]') {
            this.filters_data.Цена.sort(function(a, b) {return a-b})
          }
        },

        /**
         * filters_data применить к уже проинициализированным интервальным фильтрам
         * После подзагрузки безлимитных данных мы имеем новые значения в filters_data
         * @return {void}
         */
        apply_filters_data_to_collection: function() {
          for (var filter_name in this.collection) {
            var filter = this.collection[filter_name]
            if (filter.type == 'interval' && this.filters_data[filter_name] && filter.$slider) {
              filter.pickers_values = this.filters_data[filter_name]
              filter.$slider.slider('option', 'min', 0)
              filter.$slider.slider('option', 'max', filter.pickers_values.length - 1)
              filter.set_handles_position(0, filter.pickers_values.length - 1)
              filter.get_from().attr('placeholder', 'от ' + filter.pickers_values[0])
              filter.get_to().attr('placeholder', 'до ' + filter.pickers_values[filter.pickers_values.length - 1])
            }
          }
        },

        /**
         * Начекать (добавить к выбранным) переданные пиккеры (без эфекта фильтрации!)
         * @param  {Array} pick_data Выбранные пиккеры
         * @return {void}
         */
        checkize_pick_data: function(pick_data) {
          if (!pick_data) {
            pick_data = this.pick_data
          }
          // Разберём напиканное по фильтрам, чтобы применить сразу массив к фильтру
          var ipick_filtername = {} // Например: {"Цвет":["Красный","Синий"]}
          for (var i = 0, l = pick_data.length; i<l; i++) {
            var pick_data_array = pick_data[i].split(this.options.pick_data_delimiter)
            var filter_name = pick_data_array[0]
            var filter_value = pick_data_array[1]
            if (ipick_filtername[filter_name]==null) {
              ipick_filtername[filter_name] = []
            }
            ipick_filtername[filter_name].push(filter_value)
          }
          for (var filter_name in ipick_filtername) {
            var values = ipick_filtername[filter_name]
            var filter = this.collection[filter_name]
            if (filter == null) {
              console.log('! Не найден фильтр: „' + filter_name + '“')
            } else {
              if (filter_name == 'Цена') {
                values = values.map(function(e, i, a) {
                  if (Object.prototype.toString.call(e) === '[object String]') {
                    e = parseFloat(e)
                  }
                  return e
                })
              }
              filter.checkize_values(values)
            }
          }
        },

        /**
         * Запаковывает pick_data (слишком много пиков в интервальных фильтрах, оссобенно цены)
         * @return {Array} ["ИмяФильтра-=delimiter=-ЗначениеПиккера", "100", "199.99"]
         */
        puck_pick_data: function() {
          var price_prefix = 'Цена' + this.options.pick_data_delimiter
          var price_prefix_length = price_prefix.length
          var puck = this.pick_data
          var first_price_pick = true
          for (var i = puck.length - 1; i >= 0; i--) {
            var pick = puck[i]
            var next_pick = puck[i - 1]
            if (pick.slice(0, price_prefix_length) == price_prefix) {
              if (first_price_pick) {
                first_price_pick = false
                continue
              }
              if (!next_pick || next_pick.slice(0, price_prefix_length) != price_prefix) {
                break
              }
              puck.splice(i, 1)
            }
          }
          return puck
        },

        unpuck_pick_data: function(puck) {
          // Определяем есть ли пиккеры цены (больше двух)
          var price_prefix = 'Цена' + this.options.pick_data_delimiter
          var price_prefix_length = price_prefix.length
          var prices_picks = []
          var prices_pickers_exist = puck.filter(function(e) {
            var exist = e.slice(0, price_prefix_length) == price_prefix
            if (exist) {
              prices_picks.push(e)
            }
            return exist
          }).length >= 2 ? true : false
          var pick_data = puck

          // Если есть пиккеры цены и Есть на странице фильтр цена
          if (this.collection.Цена && prices_pickers_exist) {
            // Удалить старые пики цен
            pick_data = pick_data.filter(function(e, i, a) {
              return e.slice(0, price_prefix_length) != price_prefix
            })
            // Восстановить все промежуточные пики
            // var pv = this.collection.Цена.pickers_values
            var pv = this.filters_data.Цена
            var price_first = parseFloat(prices_picks[0].slice(price_prefix_length))
            var price_last = parseFloat(prices_picks[prices_picks.length - 1].slice(price_prefix_length))
            for (var i = 0, l = pv.length; i < l; i++) {
              var price = pv[i]
              if (price_first <= price && price <= price_last) {
                pick_data.push('Цена' + this.options.pick_data_delimiter + price.toString())
              }
            }
          }
          return pick_data
        },

        generate_collection: function() {
          this.collection = {}
          var instance = this
          this.place.find(this.options.filter_selector).each(function(i, e) {
            var $filter = $(e)
            var filter_variant = $filter.attr(instance.options.attr_name_filter_type)
            var filter_name = $filter.attr(instance.options.attr_name_filter_name)
            if (!filter_variant||!filter_name) {
              return null
            }
            var filter_type = null
            for (var type in instance.options.itypes) {
              var o = instance.options.itypes[type]
              if (o.variants && _.contains(o.variants,filter_variant)) {
                filter_type = type
              }
            }
            if (!filter_type) {
              console.log('> Ошибка! Не удалось определить тип фильтра.')
              return null
            }
            var pickers_values = instance.filters_data[filter_name]
            if (!pickers_values) {
              console.log('> Ошибка! Не удалось найти данные фильтра.')
              return null
            }
            // if (filter_type == 'interval') {
            //   console.log('asd pickers_values: ', pickers_values)
            //   var float_values = []
            //   for(var j = 0; j < pickers_values.length; j++){
            //     // alert(typeof pickers_values[i])
            //     float_values.push(
            //       typeof(pickers_values[j]) == 'string' ? parseFloat(pickers_values[j].replace(',','.')) : parseFloat(pickers_values[j])
            //     )
            //   }
            //   console.log('float_values: ', float_values.slice(0))
            //   instance.filters_data[filter_name] = float_values.slice(0)
            //   pickers_values = float_values.sort()
            // }
            pickers_values = pickers_values.clone()
            var filter = window.Market.FilterFactory.new(filter_type, $filter, pickers_values)
            if (pickers_values.length>1) {
              instance.collection[filter_name] = filter
            } else {
              filter.remove()
            }
          })
          // console.log('this.collection: ', this.collection)
        },
        activate_collection: function() {
          for (var filter_name in this.collection) {
            this.collection[filter_name].activate()
          }
        },
        activate_changer: function() {
          // this.activate_picker()
          this.activate_checkbox()
          this.activate_interval()
          return this
        },
        activate_slideup: function() {
          // ;-)
          // NOTE: If you want multiple sections open at once, don't use an accordion
          // https://stackoverflow.com/questions/3479447/jquery-ui-accordion-that-keeps-multiple-sections-open
          var accordion = this.place.find('.accordion')
          accordion.accordion({
            collapsible: true,
            heightStyle: "content",
            beforeActivate: function(event, ui) {
              // The accordion believes a panel is being opened
              if (ui.newHeader[0]) {
                var currHeader  = ui.newHeader;
                var currContent = currHeader.next('.ui-accordion-content');
              // The accordion believes a panel is being closed
              } else {
                var currHeader  = ui.oldHeader;
                var currContent = currHeader.next('.ui-accordion-content');
              }
              if( currContent.is(':animated') ) {
                return false
              }
              // Since we've changed the default behavior, this detects the actual status
              var isPanelSelected = currHeader.attr('aria-selected') == 'true';
              // Toggle the panel's header
              currHeader.toggleClass('ui-corner-all',isPanelSelected).toggleClass('accordion-header-active ui-state-active ui-corner-top',!isPanelSelected).attr('aria-selected',((!isPanelSelected).toString()));
              // Toggle the panel's icon
              currHeader.children('.ui-icon').toggleClass('ui-icon-triangle-1-e',isPanelSelected).toggleClass('ui-icon-triangle-1-s',!isPanelSelected);
              // Toggle the panel's content
              currContent.toggleClass('accordion-content-active',!isPanelSelected)    
              if (isPanelSelected) {
                currContent.slideUp();
              } else {
                currContent.slideDown();
              }
              return false
            }
          })
          var filter_headers = this.place.find('.txt_a')
          filter_headers.each(function(i, dom) {
            var header = $(dom)
            var filter_name = header.text()
            if (
              instance.options.iname[filter_name]!=null &&
              instance.options.iname[filter_name].opened
            ) {
              accordion.accordion("option", "active", i)
            }
          })
          return this
        },
        activate_limiter: function() {
          var filters = $(this.options.filter_selector)
          var instance = this
          var append_more = function(filter, hidden_count) {
            filter.append('<span class="more" data-hidden_count="' + hidden_count + '">Еще ' + hidden_count + '</span>')
          }
          var activate_more = function(filter) {
            filter.on('click', '.more', {instance:this}, function(event){
              var more = $(this)
              filter.toggleClass('limited')
              if (filter.hasClass('limited')) {
                more.text('Ещё ' + more.data('hidden_count'))
              } else {
                more.text('Основное')
              }
            })
          }
          filters.each(function(i,dom){
            var filter = $(dom)
            var filter_name = filter.attr(instance.options.attr_name_filter_name)
            var items = filter.children('.row')
            if (
              instance.options.iname[filter_name]!=null &&
              instance.options.iname[filter_name].limitary
            ) {
              if (items.length > instance.options.limiter.limit_if_more) {
                filter.addClass('limited')
                var hidden_count = items.length - instance.options.limiter.limit
                append_more(filter, hidden_count)
                activate_more(filter)
              } else {
                filter.removeClass('limited')
              }
            }
            if (
              instance.options.iname[filter_name]!=null &&
              instance.options.iname[filter_name].columnar
            ) {
              filter.addClass('columned')
            }
          })
          return this
        },
        activate_colorize: function() {
          var filters = $(this.options.filter_selector)
          var colorized_item_color = filters.find('[data-color_name]')
          colorized_item_color.each(function(i,dom){
            var item_color = $(dom)
            var color_name = item_color.data('color_name')
            if (window.Market.config.filter.colors[color_name]!=null) {
              item_color.css({'background-color':'#' + window.Market.config.filter.colors[color_name]})
            } else {
              item_color.css({'background-image': 'url(' + window.Market.config.filter.urls[color_name] + ')' })
            }
          })
          return this
        },

        // Активируем все кнопки "Сбросить все фильтры"
        activate_buttons_reset: function() {
          var buttons_reset = $(this.options.buttons_reset_selector)
          if (!buttons_reset.length) {
            return null
          }
          buttons_reset.on('click', this.options.buttons_reset_risen_selector, {instance: this}, this.buttons_reset_handler)
          this.buttons_reset_apply_pick_data()
        },

        // Обработчик нажатия на кнопку "Сбросить все фильтры"
        buttons_reset_handler: function(event) {
          var instance = event.data.instance
          instance.pick_data = []
          for (var filter_name in instance.collection) {
            var filter = instance.collection[filter_name]
            filter.reset_values()
          }
          window.Market.TovarCollection.get_instance().apply_filter()
          instance.redisable_pickers()
          instance.hide_buttons_reset()
        },

        // Прячем все кнопки "Сбросить все фильтры"
        // и проверяем есть ли другие кнопики в блоке-родителе, если нет, то прячем и сам пустой блок-родитель
        hide_buttons_reset: function() {
          var buttons_reset = $(this.options.buttons_reset_selector)
          // buttons_reset.slideUp()
          // buttons_reset.addClass('slideUp')
          buttons_reset.hide()
          buttons_reset.each(function(index, dom) {
            var button = $(dom)
            var parent_block = button.parent('.block')
            if (parent_block.length != 1) { return null }
            // var count_visible_items = parent_block.children(':visible:not(.slideUp)').length
            var count_visible_items = parent_block.children(':visible').length
            if (!count_visible_items) {
              // parent_block.slideUp()
              parent_block.hide()
            }
          })
        },

        // Показываем все кнопки "Сбросить все фильтры"
        // перед этим проверяем показан ли блок-родитель, если нет, то показываем блок-родитель
        show_buttons_reset: function() {
          var buttons_reset = $(this.options.buttons_reset_selector)
          buttons_reset.each(function(index, dom) {
            var button = $(dom)
            var parent_block = button.parent('.block')
            if (parent_block.length != 1)
              return null
            if (parent_block.is(':hidden')) {
              // parent_block.slideDown()
              parent_block.show()
            }
          })
          // buttons_reset.slideDown()
          buttons_reset.show()
          // buttons_reset.removeClass('slideUp')
        },

        /**
         * Если что-то напикано - показываем "Сбросить все фильтры", иначе - скрываем
         * @return {[type]} [description]
         */
        buttons_reset_apply_pick_data: function() {
          if (this.pick_data.length == 0) {
            this.hide_buttons_reset()
          } else {
            this.show_buttons_reset()
          }
        },

        activate_picker: function() {
          return this
        },
        activate_interval: function() {
          instance = this
          function activate_slider() {
            var sliders = instance.place.find('.slider-range')
            var froms = instance.place.find('.int.from')
            var tos = instance.place.find('.int.to')
            if (!sliders.length||!froms.length||!tos.length) {
              return null
            }
            var update_pick_data_with_calc_pick_items = function(filter_name, from_value, to_value) {
              // Отчищаем pick_data от текущего фильтра
              var new_pick_data = instance.pick_data.filter(function(pick_item, index, array){
                return (pick_item.substring(0,filter_name.length)===filter_name)? false : true
              })
              instance.pick_data = new_pick_data.clone()
              var add = true // А нужно ли добавлять фильтр?:
              // Если значение выставлены по краям то не нужно, ага
              if (
                instance.filters_data[filter_name][0] == from_value &&
                instance.filters_data[filter_name][instance.filters_data[filter_name].length-1] == to_value
              ) {
                add = false
              }
              // Добавляем подходящие значение в pick_data
              if (add) {
                var push = false
                for(var i=0, l=instance.filters_data[filter_name].length; i<l; i++) {
                  var value_string = instance.filters_data[filter_name][i]
                  if (value_string===from_value) { push = true }
                  if (push) {
                    var pick_item = filter_name + instance.options.pick_data_delimiter + value_string
                    instance.pick_data.push(pick_item)
                  }
                  if (value_string===to_value) { push = false }
                }
              }
              // pick_data подготовлен, применяем фильтры к TovarCollection
              instance.buttons_reset_apply_pick_data()
              window.Market.TovarCollection.get_instance().apply_filter()
              instance.redisable_pickers()
              window.lazyload_trigger()
            }
            sliders.each(function(i, dom) {
              var slider = $(dom)
              var from = slider.siblings('.from')
              var to = slider.siblings('.to')
              var filter_name = slider.parents('.boxs[filter_name]').attr('filter_name')
              if (!filter_name) {
                return null
              }
              var filter_values = instance.filters_data[filter_name]
              if (!filter_values) {
                return null
              }
              if (instance.collection && instance.collection[filter_name]) {
                instance.collection[filter_name].$slider = slider
              }
              // var min = Math.floor(Number(filter_values[0].replace(',','.')))||0
              // var max = Math.ceil(Number(filter_values[filter_values.length-1].replace(',','.')))||0
              // var min = Number(filter_values[0].replace(',','.'))||0
              // var max = Number(filter_values[filter_values.length-1].replace(',','.'))||0
              slider.attr('data-min', filter_values[0])
              slider.attr('data-max', filter_values[filter_values.length - 1])
              from.attr('placeholder', 'от ' + filter_values[0])
              to.attr('placeholder', 'до ' + filter_values[filter_values.length - 1])
              slider.slider({
                range: true,
                min: 0,
                max: filter_values.length-1,
                values: [0, filter_values.length-1],
                slide: function(event, ui) {
                  var slider = $(this)
                  var from = slider.siblings('.int.from')
                  var to = slider.siblings('.int.to')
                  var from_value = filter_values[ui.values[0]]
                  var to_value = filter_values[ui.values[1]]
                  if (!from.length || !to.length)
                    return null
                  from.val(from_value)
                  to.val(to_value)
                  setTimeout(function() {
                    var new_indexes = slider.slider('option', 'values')
                    var new_values = [filter_values[new_indexes[0]], filter_values[new_indexes[1]]]
                    if (
                      from_value === new_values[0] &&
                      to_value === new_values[1]
                    ) {
                      var filter_name = slider.parents('.boxs[filter_name]').attr('filter_name')
                      if (!filter_name) {
                        return null
                      }
                      update_pick_data_with_calc_pick_items(filter_name, from_value, to_value)
                    }
                  }, 1000)
                }
              })
            })
            var keyuper = function(event) {
              var it = $(this)
              var slider = it.siblings('.slider-range')
              var filter_name = slider.parents('.boxs[filter_name]').attr('filter_name')
              if (!filter_name)
                return null
              var filter_values = instance.filters_data[filter_name]
              if (!filter_values.length)
                return null
              if (it.hasClass('from')) {
                var it_is = 'from'
                var from = it
                var to = it.siblings('.to')
              } else if (it.hasClass('to')) {
                var it_is = 'to'
                var to = it
                var from = it.siblings('.from')
              } else {
                return null
              }
              var from_value = (from.val().length===0)? slider.data('min') : from.val()
              var to_value = (to.val().length===0)? slider.data('max') : to.val()
              var slider_from_position = 0
              var slider_to_position = filter_values.length - 1
              for(var slider_position=0, l=filter_values.length; slider_position<l; slider_position++) {
                var current_filter_value = filter_values[slider_position]
                if (typeof(current_filter_value) === 'string') {
                  current_filter_value = Number(current_filter_value.replace(',','.'))
                }
                if (
                  current_filter_value >=
                  Number(String(from_value).replace(',','.'))
                ) {
                  slider_from_position = slider_position
                  break
                }
              }
              for(var slider_position=filter_values.length-1; slider_position>=0; slider_position--) {
                var current_filter_value = filter_values[slider_position]
                if (typeof(current_filter_value) === 'string') {
                  current_filter_value = Number(current_filter_value.replace(',','.'))
                }
                if (
                  current_filter_value <=
                  Number(String(to_value).replace(',','.'))
                ) {
                  slider_to_position = slider_position
                  break
                }
              }
              slider.slider({
                values: [slider_from_position, slider_to_position]
              })
              var filter_from_value = filter_values[slider_from_position]
              var filter_to_value = filter_values[slider_to_position]
              var it_value = (it.val().length===0)? slider.data((it_is=='from')?'min':'max') : it.val()
              setTimeout(function(){
                if (it_value===it.val()) {
                  update_pick_data_with_calc_pick_items(filter_name, filter_from_value, filter_to_value)
                }
              }, 1000)
            }
            froms.keyup(keyuper)
            tos.keyup(keyuper)
          }
          activate_slider()
          return this
        },
        activate_checkbox: function() {
          var filter_place = $(this.options.place_selector)
          filter_place.on(
            this.options.itypes.checkbox.event_changing,
            this.options.itypes.checkbox.input_selector,
            {instance: this},
            function(event) {
              var instance = event.data.instance
              var checkbox_options = instance.options.itypes.checkbox
              var input = $(this)
              var item = input.parents(checkbox_options.item_selector)
              var filter = item.parents(checkbox_options.filter_selector)
              var filter_name = filter.attr(instance.options.attr_name_filter_name).trim()
              var filter_value = input.val().trim()
              var pick_item = filter_name + instance.options.pick_data_delimiter + filter_value
              if (this.checked) {
                instance.pick_data.push(pick_item)
                // instance.show_buttons_reset()
              } else {
                // instance.pick_data.remove_all(pick_item)
                for (var i = 0; i < instance.pick_data.length; i++) {
                  if (instance.pick_data[i] == pick_item) {
                    instance.pick_data.splice(i, 1)
                    i--
                  }
                }
                // filter_remove_picker_from_session(pick_item)
              }
              window.lazyload_trigger()
              instance.buttons_reset_apply_pick_data()
              window.Market.TovarCollection.get_instance().apply_filter()
              instance.redisable_pickers()
            }
          )
          return this
        },
        calc_intersection_ids: function() {
          var union_ids = {}
          var intersection_ids = []
          for (var i = 0; i < this.pick_data.length; i++) {
            var selector_with_delimiter = this.pick_data[i]
            var filter_name = selector_with_delimiter.split(this.options.pick_data_delimiter)[0]
            var selector = selector_with_delimiter.replace(this.options.pick_data_delimiter, ' ')
            var ids = window['filter_picker'][selector]
            union_ids[filter_name] = _.uniq( (union_ids[filter_name]||[]).concat(ids) )
          }
          for (var filter_name in union_ids) {
            intersection_ids.push(union_ids[filter_name])
          }
          if (intersection_ids.length === 1) {
            intersection_ids = intersection_ids[0]
          } else if (intersection_ids.length > 1) {
            intersection_ids = _.intersection.apply(_, intersection_ids)
          }
          return intersection_ids
        },
        calc_union_ids: function() {
          var union_ids = []
          for (var i = 0; i < this.pick_data.length; i++) {
            var selector_with_delimiter = this.pick_data[i]
            var selector = selector_with_delimiter.replace(this.options.pick_data_delimiter, ' ')
            var ids = this.pickers_data[selector]
            union_ids = _.uniq( union_ids.concat(ids) )
          }
          return union_ids
        },
        calc_user_selected_filters: function() {
          var user_selected_filters = []
          for (var i = 0, l = this.pick_data.length; i < l; i++) {
            var selector_with_delimiter = this.pick_data[i]
            var filter_name = selector_with_delimiter.split(this.options.pick_data_delimiter)[0]
            user_selected_filters.push(filter_name)
          }
          user_selected_filters = _.uniq(user_selected_filters)
          return user_selected_filters
        },
        redisable_pickers: function() {
          this.anable_all_pickers()
          this.disable_pickers_by_pick_data()
        },
        disable_pickers_by_pick_data: function() {
          if (this.pick_data.length) {
            var filter_picker_names_for_disable = this.calc_filter_picker_names_for_disable()
            this.disable_pickers(filter_picker_names_for_disable)
          }
        },
        anable_all_pickers: function() {
          for (var filter_name in this.collection) {
            var filter = this.collection[filter_name]
            if (filter && filter.type != 'interval') {
              filter.anable_all_pickers()
            }
          }
        },
        calc_filter_picker_names_for_disable: function() {
          var filter_picker_names_for_disable = []
          if (!this.pick_data.length) {
            return filter_picker_names_for_disable
          }
          var union_ids = this.calc_union_ids()
          var intersection_ids = this.calc_intersection_ids()
          var user_selected_filters = this.calc_user_selected_filters()
          if (user_selected_filters.length == 1) {
            var regexp = new RegExp('^' + user_selected_filters[0].replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
            for (var filter_picker_name in this.pickers_data) {
              if (regexp.test(filter_picker_name)) {
                continue
              }
              var picker_data = this.pickers_data[filter_picker_name]
              var intersection = _.intersection(union_ids, picker_data)
              if (!intersection.length) {
                filter_picker_names_for_disable.push(filter_picker_name)
              }
            }
          } else {
            var dinamic_regexp = new RegExp(user_selected_filters.map(function(e,i,a) { return '(?:^' + (e.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) + ')' }).join('|'))
            for (var filter_picker_name in this.pickers_data) {
              var picker_data = this.pickers_data[filter_picker_name]
              if (dinamic_regexp.test(filter_picker_name)) {
                // var intersection = _.intersection(union_ids, picker_data)
                var intersection = _.intersection(intersection_ids, picker_data)
              } else {
                // var intersection = _.intersection(intersection_ids, picker_data)
                var intersection = _.intersection(intersection_ids, picker_data)
              }
              if (!intersection.length) {
                filter_picker_names_for_disable.push(filter_picker_name)
              }
            }
          }
          return filter_picker_names_for_disable
        },
        disable_pickers: function(filter_picker_names) {
          for (var filter_name in this.filters_data) {
            for (var i=0, l=this.filters_data[filter_name].length; i<l; i++) {
              var picker_value = this.filters_data[filter_name][i]
              if (_.contains(filter_picker_names,filter_name + ' ' + picker_value)) {
                var filter = this.collection[filter_name]
                if (filter && filter.type != 'interval') {
                  filter.disable_picker(picker_value)
                }
              }
            }
          }
        },

        /**
         * Проверяет наличие места расположения фильтров, возвращает булево значение
         * @return {Boolean}
         */
        check_place: function() {
          var filter_place = $(this.options.place_selector)
          if (!filter_place.length) {
            window.console.log('Не обнаружено место коллекции фильтров.')
            return false
          }
          if (filter_place.length > 1) {
            window.console.log('Ошибка DOM, обнаружено несколько мест для фильтров.')
            return false
          }
          return true
        }

      })
      return instance
    }
    return {
      options: {

        place_selector: '.filter_b',               // Где все фильтры
        filter_selector: '.blocks[filter_name]',   // Как взять все фильтры
        attr_name_filter_name: 'filter_name',      // Как взять имя фильтра (оно уникально)
        attr_name_filter_type: 'filter_type',      // Как определить тип фильтра
        pick_data_delimiter: '-=delimiter=-',
        limiter: {
          limit: 12,                               // Ограничить до limit пиккеров
          limit_if_more: 20                        // если пиккеров больше limit_if_more
        },
        buttons_reset_selector: '.js_reset_all_filters',
        buttons_reset_risen_selector: 'span',
        itypes: {
          checkbox: window.Market.FilterCheckbox.options,
          interval: window.Market.FilterInterval.options
        },
        iname: {                                   // Настройка отдельных фильтров
          "Цена, руб.": {
            columnar: false,
            limitary: false,
            opened: true
          },
          "Бренды": {
            columnar: true,
            limitary: true,
            opened: true
          },
          "Материал": {
            columnar: true,
            limitary: false,
            opened: false
          },
          "Длина (м)": {
            columnar: false,
            limitary: false,
            opened: false
          },
          "Ширина (мм)": {
            columnar: false,
            limitary: false,
            opened: false
          },
          "Цвет": {
            columnar: true,
            limitary: false,
            opened: false
          },
          "Категории": {
            columnar: false,
            limitary: false,
            opened: true
          }
        }
      },
      get_instance: function() {
        if (instance==null) {
          instance = create_instance()
        }
        return instance
      },
      activate: function() {
        var instance = this.get_instance()
        if (!instance) {
          return null
        }
        return instance.activate()
      }
    }
  })()
})();
