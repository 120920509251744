"use strict"

$(document).on("turbolinks:load", function() {
  activate_mailing_schedules_edit()
  activate_mailing_schedules_select()
  activate_mailing_schedules_column_input()
})

function activate_mailing_schedules_edit() {
  $(".mailing_schedules").on("click", ".mailing_schedules-edit", function(event) {
    var edit = $(this)
    var column = edit.parents("td")
    var column_show_view = column.find(".mailing_schedules-column_show_view")
    var column_edit_view = column.find(".mailing_schedules-column_edit_view")
    var column_input = column.find(".mailing_schedules-column_input")
    var column_select = column.find(".mailing_schedules-column_select")
    column_show_view.hide()
    column_edit_view.show()
    if (column_input.length == 1) {
      column_input.focus()
    } else {
      column_select.focus()
    }
  })
}

function activate_mailing_schedules_column_input() {
  $(".mailing_schedules").on("keydown", ".mailing_schedules-column_input", function(event) {
    if (event.keyCode == 27) {
      var input = $(this)
      var column_edit_view = input.parents(".mailing_schedules-column_edit_view")
      var column_show_view = input.parents("td").find(".mailing_schedules-column_show_view")
      column_show_view.show()
      column_edit_view.hide()
    }
    if (event.keyCode == 13) {
      var input = $(this)
      var form = input.parents("form")
      $.rails.fire(form[0], "submit")
    }
  })
  $(".mailing_schedules").on("focusout", ".mailing_schedules-column_input", function(event) {
    var input = $(this)
    var column_edit_view = input.parents(".mailing_schedules-column_edit_view")
    var column_show_view = input.parents("td").find(".mailing_schedules-column_show_view")
    column_show_view.show()
    column_edit_view.hide()
  })
  $(".mailing_schedules").on("change", '.mailing_schedules-column_input[type=checkbox]', function(event) {
    var input = $(this)
    var form = input.parents("form")
    $.rails.fire(form[0], "submit")
  })
}


function activate_mailing_schedules_select() {
  $(".mailing_schedules").on("change", ".mailing_schedules-column_select", function(event) {
    var select = $(this)
    var form = select.parents("form")
    // var form = select.parents("form").submit()
    // var form = select.parents("form").trigger('submit.rails')
    $.rails.fire(form[0], "submit")
  })
  $(".mailing_schedules").on("focusout", ".mailing_schedules-column_select", function(event) {
    var select = $(this)
    var column_edit_view = select.parents(".mailing_schedules-column_edit_view")
    var column_show_view = select.parents("td").find(".mailing_schedules-column_show_view")
    column_show_view.show()
    column_edit_view.hide()
  })
}
