;(function($) {


"use strict"

window.vue.application.app.component.ZAccountProfileLink = {

  data: function() {
    return {
      account: window.vue.application.app.data.account
    }
  },

  // Вычисляемые данные
  // Вычислять желательно из основных данных, а не из вычисляемо-управляющих.
  computed: {

    /**
     * Тип применённой персоны (для определения реквизитов).
     * @return {String}, 'user'|'company'
     */
    person_type: function() {
      if (this.account.applied_person_id == 'user') {
        return 'user'
      }
      return 'company'
    },

    /**
     * Строка с именем выбранной компании или именем пользователя, если компания не выбрана.
     * @return {String} 
     */
    name: function() {
      if (this.person_type == 'user') {
        return this.account.user.name
      }
      for (var i = 0, l = this.account.companies.length; i < l; i++) {
        var company = this.account.companies[i]
        if (company.id == this.account.applied_person_id) {
          return company.name
        }
      }
      return ''
    },

    /**
     * Урл на применённую персону.
     * @return {String|Object}, '/asdf/asdf/asdf'|null
     */
    href: function() {
      return this.$router.resolve({name: this.person_type + '_profile', params:{ company_id: this.account.applied_person_id }}).href
    },
  },

  beforeMount: function() {
  },

  mounted: function() {
    // debugger
  },

  watch: {
  },

  methods: {
  },

  // Вот сюрприз, роутеровская линка не рефрешит страницу.
  template: `
    <!--
      <router-link
        :to="{ name: person_type + '_profile', params:{ company_id: account.applied_person_id }}"
      >Профиль {{ name }}</router-link></li>
    -->
    <a :href="href">Профиль {{ name }}</a>
  `
}


})(jQuery);
