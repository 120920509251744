;(function($) {


"use strict"

// Общее место хранения данных приложения app: window.vue.application.app.data,
// расширяем его данными аккаунта (account:…), дополненными из window.uncached.data.account.
Object.assign(window.vue.application.app.data, {
  account: Object.assign({
    // Основные данные, определяющие состояния компонента
    // В данном компоненте к их числу так же необходимо относить данные this.$route
    companies: [],           // Для списка персон, [{id:…,name:…},…].
    user: null,              // Для списка персон, {id:…,name:…,…}.
    applied_person_id: null, // Применённая персона, 'user'|1,2,3,4,….

    // Вычисляемо-управляющие данные CCD (Computed Control Data)
    // Эти данные не являются источником определяющем состояние компонетов,
    // однако они влияют на данные - источники состояний.
    // Эти данные создаются при отслеживании других данных,
    // а так же при их изменении необходимо изменять основные данные (watch+watch).
    person_id: '', // Текущая отображаемая персона, ''|'user'|1,2,3,4,…, вычисляется и влияет на this.$route.
  }, window.uncached.data.account)
})

window.vue.application.app.component.ZAccount = {

  data: function() {
    return window.vue.application.app.data.account
  },

  // Вычисляемые данные
  // Вычислять желательно из основных данных, а не из вычисляемо-управляющих.
  computed: {

    person_type: function() {
      switch(this.$route.name) {
        case 'user_profile':
        case 'user_history':
          return 'user'
        break
        case 'company_profile':
        case 'company_history':
          return 'company'
        break
      }
      return null
    },

    page_type: function() {
      switch(this.$route.name) {
        case 'user_profile':
        case 'company_profile':
          return 'profile'
        break
        case 'user_history':
        case 'company_history':
          return 'history'
        break
      }
      return null
    },

    is_person: function() {
      switch(this.$route.name) {
        case 'user_profile':
        case 'user_history':
        case 'company_profile':
        case 'company_history':
          return true
        break
      }
      return false
    },
  },

  beforeMount: function() {
  },

  mounted: function() {
    this.$consider_ccd()
  },

  watch: {
    '$route.fullPath': {
      handler: function(new_value, old_value) {
        this.person_id = this.$_calc_person_id()
      },
      deep: true
    },

    'person_id': {
      handler: function(new_value, old_value) {
        if (new_value != null && new_value != '') {
          var person_type = new_value == 'user' ? 'user' : 'company'
          var page_type = this.page_type || 'profile'
          var name = person_type + '_' + page_type
          var company_id = new_value
          this.$router.push({name: name, params: {company_id: company_id}})
        }
      },
      deep: false
    },
  },

  methods: {

    // Хэндлеры (_handler)
    add_company_handler: function(event) {
      this.$router.push({name: 'company_add'})
    },

    // Публичные методы ($)
    $consider_ccd: function() {
      this.person_id = this.$_calc_person_id()
    },

    // Приватные методы ($_)
    $_calc_person_id: function() {
      switch(this.$route.name) {
        case 'user_profile':
        case 'user_history':
          return 'user'
        case 'company_profile':
        case 'company_history':
          return this.$route.params.company_id
        break
      }
      return ''
    },
  },

  template: `
    <div class="z-account">
      <div class="wrap marged">
        <h1>Личный кабинет</h1>
      </div>
      <div class="header bg white">
        <div class="blocks_row">
          <div class="select_block">
            Добавить компанию
            <span class="primary button small" @click="add_company_handler">+</span>
            <select 
              v-model="person_id"
            >
              <option value="" disabled>
                Выберите…
              </option>
              <option v-for="(person, index) in [{id: 'user', name: user.name}].concat(companies)"
                :key="index"
                :value="person.id"
              >
                {{ person.name }}
              </option>
            </select>
          </div>
          <div class="entities_block">
            <ul
              v-if="is_person"
            >
              <li><router-link
                :to="{ name: person_type + '_profile', params:{ company_id: person_id }}"
                v-bind:class="{active: page_type == 'profile'}"
              >Профиль</router-link></li>
              <li><router-link
                :to="{ name: person_type + '_history', params:{ company_id: person_id }}"
                v-bind:class="{active: page_type == 'history'}"
              >История заказов</router-link></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="wrap padded bg white">
        <router-view ref="view"></router-view>
      </div>
    </div>
  `
}


})(jQuery);
