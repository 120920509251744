;(function() {

  "use strict"

  window.main_hovers = function() {
    hovers_activate_main_menu_halloween_hover()
    hovers_globals()
    hovers_cat_click()
    hovers_cart_hover()
    hovers_map_hover()
  }

  window.hovers_activate_main_menu_halloween_hover = function() {
    var halloween_menu = $("#main_menu ul.halloween")
    if (halloween_menu.length == 0) {
      return null
    }
    $("#main_menu").on("mouseenter", "li.halloween", function(event) {
      var li = $(this)
      var ul = li.children("ul")
      setTimeout(function() {
        ul.css("background-image", "none")
      }, 300)
    })
  }

  window.hovers_globals = function() {

    //Hover hide/show
    //Обьекты выпадающего блока c активным статусом
    window.statusCat = {".map":1};
    window.timerCat = {".map":1};
  }

  //Function hover1 ***par1 - на что наводим, par2 - что открываем
  window.hover1 = function(par1, par2){

    if(statusCat[par1] == "undefined"){
      statusCat[par1] = 0;
      timerCat[par1] = 0;
    }
    if(statusCat[par1] == 1){
      clearTimeout(timerCat[par1]);
      $(par2).clearQueue();
    } else {
      timerCat[par1] = setTimeout(function() {
        statusCat[par1] = 1;
        $(par2).fadeIn();
      }, 200);
    }
  }
  //Function hover2 ***par1 - на что наводим, par2 - что открываем
  window.hover2 = function(par1, par2){

    if(statusCat[par1] == 1){
      timerCat[par1] = setTimeout(function() {
        statusCat[par1] = 0;
        $(par2).fadeOut();
      }, 200);
    } else {
      clearTimeout(timerCat[par1]);
      $(par2).clearQueue();
    }
  }

  window.hovers_cat_click = function() {
    $("#main_menu").on("click", ".cat", function(event) {
      event.stopPropagation();
      var licat = $(this);
      var nav_b = licat.children(".nav_b");
      if (!nav_b.length) {
        return null;
      }
      if (nav_b.is(":animated")) {
        return null;
      }
      nav_b.fadeToggle(200);
    });
    $("#main_menu > .cat > .nav_b").on("click", function(event) {
      event.stopPropagation();
    });
    var check_visible_cat = function(event) {
      var nav_b = $("#main_menu > .cat > .nav_b");
      if (nav_b.is(":visible")) {
        nav_b.fadeOut(200);
      }
    }
    $("body").on("click", check_visible_cat);
  }

  window.hovers_cart_hover = function() {
    //Cart hover
    $("#bar .cart").hover(function() {
      hover1($(this), ".cart .cart_b");
    }, function() {
      hover2($(this), ".cart .cart_b");
    });
  }
    
  window.hovers_map_hover = function() {
    //Map hover
    $(".map").hover(function() {
      hover2($(this), ".map_b");
    }, function() {
      hover1($(this), ".map_b");
    });
  }
})();
