;(function() {


"use strict"

window.Vue.component("FieldErrors", {
  props: {
    errors: [Object, Array]
  },
  data: function() {
    return {
    }
  },
  template: `
    <ul class="field-errors" v-if="errors != null && errors.length > 0">
      <li v-for="error in errors">{{ error }}</li>
    </ul>
  `
})


})();
