window.main_offers = function() {
  window.offers_countdown_timer()
  // Поле поиска
  window.offers_user_typeahead()
  // Переключение типов КП
  window.offers_switch_offer_types()
  window.offers_prevent_enter()
  window.offers_add_offer_to_cart()
  window.offers_add_offer_product_to_cart()
  // В редактировании установить/снять все филиалы
  window.offers_affiliates_check_all()
  // Настраиваемое КП в корзину
  window.offers_add_tuned_offer_to_cart()
  // Для Пакетных КП пересчёт полной цены.
  window.offers_calc_total_price()
  window.offers_copy_url()
  window.offers_show_when_email()
  window.offers_switch_create_slug()
}

// Активация часиков обратного отсчёта
window.offers_countdown_timer = function() {
  var timer = $(".offers .offers-countdown_timer")
  if (timer.length != 1) {
    return null
  }
  var distance_place = $(".offers-countdown_timer-distance")
  var date_to = Date.parse(timer.data("to"))
  var interval_id = setInterval(
    function() {
      var now = new Date().getTime()
      var distance = date_to - now

      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      distance_place.text(
        days + " " + days.declension("дней, день, дня") + " " +
        hours + " " + hours.declension("часов, час, часа") + " " +
        minutes + " " + minutes.declension("минут, минута, минуты") + " " +
        seconds + " " + seconds.declension("секунд, секунда, секунды")
      )

      if (distance < 0) {
        clearInterval(interval_id)
        distance_place.text("Истекло")
      }
    },
    1000
  )
}

/**
 * Активация поля поиска по имени/email с помощью "плагина отображения"
 * typeahead.
 * @return void
 */
window.offers_user_typeahead = function() {

  // Активируем поиск пользователя для добавления в фильтры списка Коммерческих
  // предложений
  $('.offers-user_typeahead').typeahead({
    minLength: 1,
    hint: true,
    highlight: true
  },
  {
    name: 'offers-user_typeahead',
    limit: 20,
    display: 'label',
    source: function(query, process, asyncProcess) {
      $.ajax({
        url: '/ajax_users/typeahead',
        type: 'get',
        data: {
          query: query
        },
        dataType: 'json',
        cache: false,
        async: true,
        dataType: 'json',
        timeout: 3000
      }).done(function(data) {
        asyncProcess(data.options)
      })
    },
  }).on('typeahead:selected', function(event, selection) {
    var selected_place = $(".offers-user_filters")
    selected_place.append(
      '\
        <span class="offers-user_filter">\
          ' + selection.label + '\
          <input type="hidden" name="filters[user_ids][]" value="' + selection.option + '">\
          <span>&#61453;</span>\
        </span>\
      '
    )
    // Очищаем после действий с выбранным значением
    $(this).typeahead('val', '')
  })

  // Удаление выбранного пользователя из фильтров по клику на крестик
  $(".offers-user_filters").on("click", ".offers-user_filter span", function(event) {
    var close = $(event.currentTarget)
    var filter = close.parent()
    filter.remove()
  })
}

// Активация радио-кнопок переключения типа КП
window.offers_switch_offer_types = function() {

  var switch_offer_type = function(offer_type) {
    var counts = $('.offers input[name^="market_model_offer[products_attributes]"][name$="[count]"]')
    var total_prices = $('.offers input[name^="market_model_offer[products_attributes]"][name$="[total_price]"]')
    var sum_total_price = $('.offers .offer-sum_total_price')
    var col_count = $(".offers .offers-table th.offers-col_count")
    var col_counts = $(".offers .offers-table .offers-col_count")
    var col_total_prices = $(".offers .offers-table .offers-col_total_price")
    var col_percent = $(".offers .offers-table .offers-col_percent")
    var new_price_input = $(".offers .offers-table .offers-col_price input")
    var new_price_hint = $(".offers .offers-table .offers-col_price .hint")
    switch(offer_type) {
      case 'grouped':
        counts.slideDown()
        total_prices.slideDown()
        sum_total_price.slideDown()
        var count_inputs = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[count]']")
        count_inputs.each(function(index, dom) {
          var count_input = $(dom)
          var code = count_input.data("code")
          if (code == null) { return null }
          var price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[price]'][data-code='" + code + "']")
          if (price_input.length != 1) { return null }
          var total_price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]'][data-code='" + code + "']")
          if (total_price_input.length != 1) { return null }
          var total_price = Math.round(parseFloat(count_input.val()) * parseFloat(price_input.val()) * 100)/100
          if (isNaN(total_price)) { return null }
          total_price_input.val(total_price)
        })
        window.offers_calc_sum_total_price()
        col_count.text("Количество")
        col_counts.show()
        col_total_prices.show()
        col_percent.show()
        new_price_input.show()
        new_price_input.prop("required", true)
        new_price_hint.show()
      break
      case 'priced':
        counts.slideUp()
        total_prices.slideUp()
        sum_total_price.slideUp()
        col_count.text("Количество")
        col_counts.hide()
        col_total_prices.hide()
        col_percent.show()
        new_price_input.show()
        new_price_input.prop("required", true)
        new_price_hint.show()
      break
      case 'tuned':
        counts.slideDown()
        total_prices.slideUp()
        sum_total_price.slideUp()
        col_count.text("Минимальное количество")
        col_counts.show()
        col_total_prices.show()
        col_percent.show()
        new_price_input.show()
        new_price_input.prop("required", true)
        new_price_hint.show()
      break
      case 'discountless':
        counts.slideUp()
        total_prices.slideUp()
        sum_total_price.slideUp()
        col_count.text("Минимальное количество")
        col_counts.hide()
        col_total_prices.hide()
        col_percent.hide()
        new_price_input.hide()
        new_price_input.prop("required", false)
        new_price_hint.hide()
      break
    }
  }

  // При загрузке страницы определить тип и переключить на него.
  var selected_input = $('.offers input[name="market_model_offer[offer_type]"]:checked')
  if (selected_input.length == 1) {
    switch_offer_type(selected_input.val())
  }

  // При выборе радиокнопки определить тип и переключить на него.
  var inputs = $('.offers input[name="market_model_offer[offer_type]"]').on("change", function(event) {
    var form_input = event.target
    var offer_type = form_input.value
    switch_offer_type(offer_type)
  })
}

// Отменить отправку формы по нажатию Enter.
window.offers_prevent_enter = function() {
  var js_offers_edit_form = $(".offers form.js_offers_edit_form")
  if (js_offers_edit_form.length != 1) {
    return null
  }
  js_offers_edit_form.find("input").keydown(function(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      return false
    }
  })
}

window.offers_add_offer_to_cart = function() {
  var buttons = $(".offers .js_add_offer_to_cart")
  if (buttons.length < 1) {
    return null
  }
  buttons.on("click", function(event) {
    var button = $(this)
    var offer_id = button.data("offer_id")
    $.ajax({
      url: "/ajax_cart/add_offer",
      type: 'get',
      dataType: 'json',
      data: {
        id: offer_id,
        count: 1
      },
      beforeSend: function(jqXHR) {
        button.removeClass("success_action")
        button.prop("disabled", true)
        button.addClass("loading")
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      console.log('jqXHR, textStatus, errorThrown: ', jqXHR, textStatus, errorThrown)
      button.attr("data-success_action", "Ошибка")
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
    })
    .done(function(data, textStatus, jqXHR) {
      console.log('data, textStatus, jqXHR: ', data, textStatus, jqXHR)
      var count
      data.offers.forEach(function(element, index, array) {
        if (element.offer.id == offer_id) {
          count = element.offer_rel.count
        }
      })
      if (isNaN(count)) {
        return null
      }
      button.attr("data-success_action", "Добавлено, в корзине " + count + " КП")
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
      window.app.cart_get()
    })
  })
}


window.offers_add_offer_product_to_cart = function() {
  var buttons = $(".offers .js_add_offer_product_to_cart")
  if (buttons.length < 1) {
    return null
  }
  buttons.on("click", function(event) {
    var button = $(this)
    var offer_product_id = button.data("offer_product_id")
    var count = $("#offer_product_count-" + offer_product_id).val()

    // В случае если КП бесскидочное, то в когрзину добавляем обычный товар, а
    // не товар-КП.
    if (button.data("is_discountless") == "true") {
      var articul = button.data("code")
      button.removeClass("success_action")
      button.prop("disabled", true)
      button.addClass("loading")
      window.app.cart_add(articul, count).then(
        function(data) {
          if (Object.prototype.toString.call(data.list) === '[object Array]') {
            for (var i = 0, l = data.list.length; i < l; i++) {
              var item = data.list[i]
              if (item.articul == articul) {
                var count = item.count
                var text = "в корзине " + count + " " + count.declension("товаров,товар,товара")
                button.attr("data-success_action", text)
              }
            }
          }
          instance.status.add_to_cart_disabled[articul] = false
          button.removeClass("loading")
          button.prop("disabled", false)
          button.addClass("success_action")
        },
        function(reason) {
          instance.status.add_to_cart_disabled[articul] = false
          button.removeClass("loading")
          button.prop("disabled", false)
        }
      )
      return null
    }

    $.ajax({
      url: "/ajax_cart/add_offer_product",
      type: 'get',
      dataType: 'json',
      data: {
        id: offer_product_id,
        count: count
      },
      beforeSend: function(jqXHR) {
        button.removeClass("success_action")
        button.prop("disabled", true)
        button.addClass("loading")
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      console.log('jqXHR, textStatus, errorThrown: ', jqXHR, textStatus, errorThrown)
      button.attr("data-success_action", "Ошибка")
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
    })
    .done(function(data, textStatus, jqXHR) {
      console.log('data, textStatus, jqXHR: ', data, textStatus, jqXHR)
      var count
      data.offer_products.forEach(function(element, index, array) {
        if (element.offer_product.id == offer_product_id) {
          count = element.offer_product_rel.count
        }
      })
      if (isNaN(count)) {
        return null
      }
      button.attr("data-success_action", "Добавлено, в корзине " + count + " " + count.declension("продуктов, продукт, продукта"))
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
      window.app.cart_get()
    })
  })
}

window.offers_add_tuned_offer_to_cart = function() {
  var buttons = $(".offers .js_add_tuned_offer_to_cart")
  if (buttons.length < 1) {
    return null
  }
  buttons.on("click", function(event) {
    var button = $(this)
    var offer_id = button.data("offer_id")
    var tune = {}
    tune.offer_products = {}
    $('.offers input[id^="offer_product_count"]').each(function(index, element) {
      var input = $(element)
      var count = parseFloat(input.val())
      var offer_product_id = parseFloat(input.data("offer_product_id"))
      tune.offer_products[offer_product_id] = {count: count}
    })
    $.ajax({
      url: "/ajax_cart/add_tuned_offer",
      type: 'get',
      dataType: 'json',
      data: {
        id: offer_id,
        tune: tune
      },
      beforeSend: function(jqXHR) {
        button.removeClass("success_action")
        button.prop("disabled", true)
        button.addClass("loading")
      }
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      console.log('jqXHR, textStatus, errorThrown: ', jqXHR, textStatus, errorThrown)
      button.attr("data-success_action", "Ошибка")
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
    })
    .done(function(data, textStatus, jqXHR) {
      button.attr("data-success_action", "Добавлено")
      button.prop("disabled", false)
      button.removeClass("loading")
      button.addClass("success_action")
    })
  })
}

window.offers_calc_total_price = function() {
  // market_model_offer[products_attributes][0][price]
  // market_model_offer[products_attributes][1][price]
  // market_model_offer[products_attributes][2][price]
  var timeout_id = null;
  var price_inputs = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[price]']")
  var count_inputs = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[count]']")
  var total_price_inputs = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]']")
  var percent_view_inputs = $(".offers input.percent_view")
  percent_view_inputs.each(function(index, dom) {
    var percent_view_input = $(dom)
    var percent = percent_view_input.val()
    window.offers_check_percent_with_discount(percent, percent_view_input)
  })
  price_inputs.on("keyup", function(event) {
    var price_input = $(this)
    if (timeout_id != null) {
      clearTimeout(timeout_id)
    }
    timeout_id = setTimeout(function() {
      var code = price_input.data("code")
      if (code == null) { return null }
      // Процент перерасчитываем при любом типе КП при изменении цены
      var percent_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[percent]'][data-code='" + code + "']")
      if (percent_input.length != 1) { return null }
      var percent_view = $(".offers input.percent_view[data-code='" + code + "']")
      if (percent_view.length != 1) { return null }
      var percent = 100 - 100 * price_input.val() / price_input.data("old_price")
      if (isNaN(percent)) { return null }
      percent_input.val(percent)
      percent_view.attr("title", percent)
      percent_view.val(Math.round(percent * 100) / 100)
      window.offers_check_percent_with_discount(percent, percent_view)
      // Определим тип КП.
      var radio = $(".offers input[name='market_model_offer[offer_type]']:checked")
      // Если Пакетное.
      if (radio.val() == "grouped") {
        var count_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[count]'][data-code='" + code + "']")
        if (count_input.length != 1) { return null }
        var total_price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]'][data-code='" + code + "']")
        if (total_price_input.length != 1) { return null }
        var total_price = Math.round(parseFloat(count_input.val()) * parseFloat(price_input.val()) * 100)/100
        if (isNaN(total_price)) { return null }
        total_price_input.val(total_price)
        window.offers_calc_sum_total_price()
      }
    }, 200);
  })
  count_inputs.on("keyup", function(event) {
    var count_input = $(this)
    if (timeout_id != null) {
      clearTimeout(timeout_id)
    }
    timeout_id = setTimeout(function() {
      // Определим тип КП.
      var radio = $(".offers input[name='market_model_offer[offer_type]']:checked")
      // Если Пакетное.
      if (radio.val() == "grouped") {
        var code = count_input.data("code")
        if (code == null) { return null }
        var price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[price]'][data-code='" + code + "']")
        if (price_input.length != 1) { return null }
        var total_price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]'][data-code='" + code + "']")
        if (total_price_input.length != 1) { return null }
        var percent_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[percent]'][data-code='" + code + "']")
        if (percent_input.length != 1) { return null }
        var percent_view = $(".offers input.percent_view[data-code='" + code + "']")
        if (percent_view.length != 1) { return null }
        var total_price = Math.round(parseFloat(count_input.val()) * parseFloat(price_input.val()) * 100)/100
        if (isNaN(total_price)) { return null }
        total_price_input.val(total_price)
        var percent = 100 - 100 * price_input.val() / price_input.data("old_price")
        if (isNaN(percent)) { return null }
        percent_input.val(percent)
        percent_view.attr("title", percent)
        percent_view.val(Math.round(percent * 100) / 100)
        window.offers_check_percent_with_discount(percent, percent_view)
        window.offers_calc_sum_total_price()
      }
    }, 200);
  })
  total_price_inputs.on("keyup", function(event) {
    var total_price_input = $(this)
    if (timeout_id != null) {
      clearTimeout(timeout_id)
    }
    timeout_id = setTimeout(function() {
      // Определим тип КП.
      var radio = $(".offers input[name='market_model_offer[offer_type]']:checked")
      // Если Пакетное.
      if (radio.val() == "grouped") {
        var code = total_price_input.data("code")
        if (code == null) { return null }
        var price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[price]'][data-code='" + code + "']")
        if (price_input.length != 1) { return null }
        var count_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[count]'][data-code='" + code + "']")
        if (count_input.length != 1) { return null }
        var percent_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[percent]'][data-code='" + code + "']")
        if (percent_input.length != 1) { return null }
        var percent_view = $(".offers input.percent_view[data-code='" + code + "']")
        if (percent_view.length != 1) { return null }
        var price = Math.round(parseFloat(total_price_input.val()) / parseFloat(count_input.val()) * 100)/100
        if (isNaN(price)) { return null }
        price_input.val(price)
        var percent = 100 - 100 * price_input.val() / price_input.data("old_price")
        if (isNaN(percent)) { return null }
        percent_input.val(percent)
        percent_view.attr("title", percent)
        percent_view.val(Math.round(percent * 100) / 100)
        window.offers_check_percent_with_discount(percent, percent_view)
        window.offers_calc_sum_total_price()
      }
    }, 200);
  })
  percent_view_inputs.on("keyup", function(event) {
    var percent_view_input = $(this)
    if (timeout_id != null) {
      clearTimeout(timeout_id)
    }
    timeout_id = setTimeout(function() {
      var code = percent_view_input.data("code")
      if (code == null) { return null }
      // Процент
      var percent_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[percent]'][data-code='" + code + "']")
      if (percent_input.length != 1) { return null }
      var percent = percent_view_input.val()
      if (isNaN(percent)) { return null }
      percent_input.val(percent)
      percent_view_input.attr("title", percent)
      window.offers_check_percent_with_discount(percent, percent_view_input)
      // Цена
      var price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[price]'][data-code='" + code + "']")
      if (price_input.length != 1) { return null }
      // price = (100 - percent) * old_price / 100
      var price = Math.round(((100 - parseFloat(percent)) * parseFloat(price_input.data("old_price")) / 100) * 100)/100
      if (isNaN(price)) { return null }
      price_input.val(price)
      // Определим тип КП.
      var radio = $(".offers input[name='market_model_offer[offer_type]']:checked")
      // Если Пакетное.
      if (radio.val() == "grouped") {
        var count_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[count]'][data-code='" + code + "']")
        if (count_input.length != 1) { return null }
        var total_price_input = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]'][data-code='" + code + "']")
        if (total_price_input.length != 1) { return null }
        var total_price = Math.round(parseFloat(count_input.val()) * parseFloat(price_input.val()) * 100)/100
        if (isNaN(total_price)) { return null }
        total_price_input.val(total_price)
        window.offers_calc_sum_total_price()
      }
    }, 200);
  })
}

window.offers_format_price = function(price, options = {discount: false, red: false}) {
  if (typeof price != "number") { price = parseFloat(price) }
  if (isNaN(price)) { price = 0 }
  price = Math.round(price * 100) / 100
  var parts = price.toString().split(".")
  var whole = parts[0]
  var fractional = parts[1] == null ? "00" : (parts[1] + "0").slice(0, 2)
  var discount = options.discount ? ' discount' : ''
  var red = options.red ? ' red' : ''
  return '<span class="price-numeric' + discount + red + '">' + whole + ' <span>' + fractional + '</span></span>'
}

window.offers_calc_sum_total_price = function() {
  var total_price_inputs = $(".offers input[name^='market_model_offer[products_attributes]'][name$='[total_price]']")
  var sum_total_price = 0
  total_price_inputs.each(function(index, dom) {
    sum_total_price+= parseFloat($(dom).val())
  })
  if (isNaN(sum_total_price)) { return null }
  var sum_total_price_block = $(".offers .js_sum_total_price")
  sum_total_price_block.html(window.offers_format_price(sum_total_price))
}

window.offers_copy_url = function() {
  // В просмотре КП
  var copy_offer_url = $(".offers .js_copy_offer_url").on("click", function(event) {
    var button = $(this)
    button.removeClass("success_action")
    var offer_url = $(".offers .js_offer_url")
    if (offer_url.length != 1) { return null }
    var temp_id = 'hiddenCopyToClipboard'
    var temp = document.createElement("textarea")
    temp.style.position = "absolute"
    temp.style.left = "-9999px"
    temp.style.top = "0"
    temp.id = temp_id
    document.body.appendChild(temp)
    temp.textContent = offer_url[0].textContent
    var x = window.scrollX
    var y = window.scrollY
    temp.focus()
    window.scrollTo(x, y)
    temp.setSelectionRange(0, temp.value.length)
    var done
    try {
      done = document.execCommand("copy")
      button.addClass("success_action")
    } catch(e) {
      done = false
    }
  })
  // В списке КП
  $(".offers").on("click", ".copy_offers_url", function(event) {
    var button = $(this)
    var temp_id = 'hiddenCopyToClipboard'
    var temp = document.createElement("textarea")
    temp.style.position = "absolute"
    temp.style.left = "-9999px"
    temp.style.top = "0"
    temp.id = temp_id
    document.body.appendChild(temp)
    temp.textContent = button.data("url")
    var x = window.scrollX
    var y = window.scrollY
    temp.focus()
    window.scrollTo(x, y)
    temp.setSelectionRange(0, temp.value.length)
    var done
    try {
      done = document.execCommand("copy")
      button.removeClass("light_secondary")
      button.addClass("light_primary")
    } catch(e) {
      done = false
    }
  })
}


// В редактировании установить/снять все филиалы
window.offers_affiliates_check_all = function() {
  $(".offers").on("click", ".js_offer_affiliates_check_all", function(event) {
    var inputs = $(".offers input[name^='market_model_offer[affiliate_ids]']")
    inputs.prop('checked', true)
    var inputs = $(".offers input[name^='filters[affiliate_ids]']")
    inputs.prop('checked', true)
  })
  $(".offers").on("click", ".js_offer_affiliates_uncheck_all", function(event) {
    var inputs = $(".offers input[name^='market_model_offer[affiliate_ids]']")
    inputs.prop('checked', false)
    var inputs = $(".offers input[name^='filters[affiliate_ids]']")
    inputs.prop('checked', false)
  })
}


// При согласовании КП пользователь может ограничить число Клиентов, в этом
// случае раскрывается интерфейс добавления/удаления email-ов Клиентов.
window.offers_show_when_email = function() {
  // Инпуты переключения
  var input = $(".offers input[name=market_model_offer\\[show_when\\]]")
  if (input.length == 0) {
    return null
  }
  // Слайд со списком мыл, будем его показывать или прятать.
  var emails = $(".offers .offers_emails")
  // Функция, которая прячет/показывает в зависимости от выбранного значения
  var switch_limiting_visibility = function(val) {
    if (val == 'email') {
      emails.slideDown()
    } else {
      emails.slideUp()
    }
  }
  // Определяенм выбранное значение при загрузке страницы.
  var current_input = $(".offers input[name=market_model_offer\\[show_when\\]]:checked")
  if (current_input.length == 1) {
    switch_limiting_visibility(current_input.val())
  }
  // Навешиваем обработчик на изменение значения.
  input.on("change", function(event) {
    switch_limiting_visibility($(this).val())
  })
}

// В соответствии с выбором способа создания slug блокируется поле ручного
// указания slug.
window.offers_switch_create_slug = function() {
  var inputs = $(".offers input[name=market_model_offer\\[short_slug\\]]")
  if (inputs.length == 0) {
    return null
  }
  var input = $(".offers input[name=market_model_offer\\[slug\\]]")
  var switch_short_slug = function(value) {
    if (value == "вручную") {
      input.attr("disabled", false)
    } else {
      input.attr("disabled", true)
    }
  }
  inputs.on("change", function(event) {
    switch_short_slug($(this).val())
  })
  var current_input = $(".offers input[name=market_model_offer\\[short_slug\\]]:checked")
  if (current_input.length == 1) {
    switch_short_slug(current_input.val())
  }
}

window.offers_check_percent_with_discount = function(percent, percent_view) {
  percent = parseFloat(percent)
  var max_discount = parseFloat(percent_view.data("max_discount"))
  var above_discount = percent_view.siblings(".above_discount").first()
  if (percent > max_discount) {
    above_discount.show()
  } else {
    above_discount.hide()
  }
}
