window.main_page = function() {}

// lib
window.page_type = function() {
  return {
    'Главная': /\/$/,
    'Каталог':  /\/cat\/\D.+/,
    'Товар':    /^\/cat\/[\d-]{11,12}$/,
    'Контакты': /\/company\/conacts/,
    'Доставка': /\/company\/shipping/,
    'Оплата':    /\/company\/payment/,
    'События':  /\/events/
  }
}

window.page_is = function(name) {
  return window.page_type()[name].test(window.location.pathname)
}

// helpers
window.page_tovar = function()    { return window.page_is('Товар') }
window.page_events = function()   { return window.page_is('События') }
window.page_catalog = function()  { return window.page_is('Каталог') }
window.page_contacts = function() { return window.page_is('Контакты') }
window.page_shipping = function() { return window.page_is('Доставка') }
window.page_payment = function()  { return window.page_is('Оплата') }
window.page_home = function()     { return window.page_is('Главная') }

// Страница оформления заказа
window.page_cart_order = function() { return /^\/cart\/to_order/i.test(window.location.pathname) }
