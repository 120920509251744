;(function() {


"use strict"

window.vue.application.app.mixin.PersonInfoMixin = {

  computed: {
  },

  created: function() {
  },

  beforeUpdate: function() {
  },

  methods: {

    $_is_show_span_as_input: function(field_name) {
      return !this.fields[field_name].is_editable && this.fields[field_name].value != null && this.fields[field_name].value != ''
    },

    $_is_show_input: function(field_name) {
      return this.fields[field_name].is_editable || this.fields[field_name].value == '' || this.fields[field_name].value == null
    },

    $_is_show_pencil: function(field_name) {
      return !this.fields[field_name].is_editable && this.fields[field_name].value != '' && this.fields[field_name].value != null
    },
  },
}


})();
