window.main_cookies = function() {
  cookies_init()
  cookies_trigger()
}

window.cookies_init = function() {
  console.log('> cookies_init')
  window['clear_cookie_key'] = '1'
  window['clear_cookie_cookies'] = ['phil']
}

window.cookies_trigger = function() {
  console.log('> cookies_trigger')
  if (clear_cookie_key != $.cookies('clear_cookie_key')) {
    $.cookie('clear_cookie_key', clear_cookie_key, {path: '/', expires: 180})
    for (i = 0; i < clear_cookie_cookies.length; i ++) {
      $.cookie(clear_cookie_cookies[i], '')
    }
  }
}
